import { FC } from "react";
import i18n from "@/i18n";
import { FloorPlanListItemProps } from "./types";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { FloorPlanNoImageIcon } from "./components";
import ModalManager from "@/components/ModalComponent/ModalManager";
import ListEntryComponent from "@/configurable/components/ListComponent/ListEntryComponent";
import styles from "./FloorPlanListItem.module.scss";

const FloorPlanListItem: FC<FloorPlanListItemProps> = ({
  floorPlanBase,
  onEditClick,
  onDeleteClick,
  onFloorPlanClick,
}) => {
  // ! variables
  const floorPlanData = floorPlanBase.data;
  const showNoImageIcon = !floorPlanData.images?.length;

  // ! handlers
  const handleDeleteClick = () => {
    ModalManager.confirm({
      message: i18n.t(
        "cb:FloorPlan.messages.confirm.delete",
        "Sind Sie sicher, dass Sie den Grundriss löschen möchten?"
      ),
      confirmButtonText: i18n.t("Global.Buttons.delete"),
      onConfirm: () => onDeleteClick(floorPlanBase._id),
    });
  };

  // ! render
  return (
    <ListEntryComponent
      disableEntryClick
      className={styles.list_item}
      entryData={floorPlanData}
      onClickEntry={() => onFloorPlanClick(floorPlanBase)}
      renderEntry={() => (
        <div className={styles.entry}>
          {showNoImageIcon && <FloorPlanNoImageIcon />}

          <span className={styles.text}>{floorPlanData.name}</span>
        </div>
      )}
      entryActions={[
        // todo @Anton - uncomment when delete problem resolved
        // {
        //   type: "button",
        //   text: i18n.t("Global.Buttons.delete"),
        //   icon: { ...DefaultIcons.TRASH, size: "xs" },
        //   onSelect: handleDeleteClick,
        // },
        {
          type: "button",
          text: i18n.t("Global.Buttons.edit"),
          icon: { ...DefaultIcons.EDIT, size: "xs" },
          onSelect: () => onEditClick(floorPlanBase),
        },
      ]}
    />
  );
};

export default FloorPlanListItem;
