import { FC } from "react";
import { LoadOverlayProps } from "./types";
import { Loader } from "rsuite";
import styles from "./LoadOverlay.module.scss";

const LoadOverlay: FC<LoadOverlayProps> = ({
  open = false,
  size = "md",
  position = "absolute",
}) => {
  if (!open) return null;

  return (
    <div className={styles.load_overlay} style={{ position }}>
      <Loader size={size} />
    </div>
  );
};

export default LoadOverlay;
