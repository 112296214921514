import { FC } from "react";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { FloorPlanSelectFieldProps } from "./types";
import { OAFloorPlanBase } from "@/apps/tatar/objectsApp/types/object.interface";
import MQ from "@/utils/MatchQueryUtils";
import FormValidators from "@/components/Form/Validation/FormValidators";
import { Field } from "react-final-form";
import BFAssetChooserSelect from "@/modules/abstract-ui/forms/chooser/BFAssetChooserSelect";

const FloorPlanSelectField: FC<FloorPlanSelectFieldProps> = ({
  buildingId,
  onFloorPlanSelect,
}) => {
  return (
    <Field name="floorPlanId" validate={FormValidators.required()}>
      {({ input, meta }) => (
        <BFAssetChooserSelect
          {...input}
          {...FormValidators.getValidation(meta)}
          label={i18n.t("cb:FloorPlan.form.labels.floorPlan", "Grundriss")}
          placeholder={i18n.t(
            "cb:FloorPlan.form.placeholders.selectFloorPlan",
            "Grundriss auswählen"
          )}
          assetType={AssetTypes.Portfolio.FloorPlan}
          onChange={onFloorPlanSelect}
          matchQuery={MQ.and(
            MQ.eq("data.objectId", buildingId),
            MQ.ne("data.status", "archived"),
            MQ.nin("cdn", [null]),
            MQ.nin("data.images", [null])
          )}
          getOption={(floorPlanBase: OAFloorPlanBase) => ({
            value: floorPlanBase._id,
            label: floorPlanBase.data.name,
          })}
        />
      )}
    </Field>
  );
};

export default FloorPlanSelectField;
