import { memo } from "react";
import { Text } from "rsuite";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import styles from "./ImageFieldDropzoneUpload.module.scss";

const ImageFieldDropzoneUpload = () => {
  return (
    <>
      <Text size={16}>Upload Image</Text>
      <BfIcon type="light" data="add" className={styles.add_icon} />
    </>
  );
};

export default memo(ImageFieldDropzoneUpload);
