import { useDispatch } from "react-redux";
import AssetLoader from "../../components/AssetLoader/AssetLoader";
import { AssetTypes } from "../../model/AssetTypes";
import { DashboardConfigAsset } from "../../model/general-assets/DashboardConfigAsset";
import { useTypedSelector } from "../../redux/hooks";
import SubmitService from "../../services/SubmitService";
import ConfigurableDashboard, {
  BreakpointDefinition,
  DashboardData,
  DashboardItemConfig,
} from "./ConfigurableDashboard";
import { DASHBOARD_DEFAULT_BREAKPOINTS } from "./ConfigurableDashboardConstants";

interface ConfigurableDashboardByAssetProps {
  filterComponent?: React.ReactNode;
  definition?: BreakpointDefinition;
  dashboardId: string;
  rowHeight?: number;

  bright?: boolean;

  configs: DashboardItemConfig[];
  defaultData?: DashboardData;
  globalAsset?: boolean;
}
const ConfigurableDashboardByAsset = (
  props: ConfigurableDashboardByAssetProps
) => {
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.global.user._id);
  return (
    <AssetLoader
      global={props.globalAsset}
      assetType={AssetTypes.DashboardConfig}
      query={{
        type: "and",
        query: [
          {
            type: "op",
            name: "data.user",
            op: "eq",
            value: user,
          },
          {
            type: "op",
            name: "data.id",
            op: "eq",
            value: props.dashboardId,
          },
        ],
      }}
      render={(asset: DashboardConfigAsset, selector, reload) => (
        <ConfigurableDashboard
          filterComponent={props.filterComponent}
          bright={props.bright}
          rowHeight={props.rowHeight}
          breakpoints={props.definition || DASHBOARD_DEFAULT_BREAKPOINTS}
          data={asset ? asset.data.config : props.defaultData || undefined}
          configs={props.configs}
          onLayoutSubmit={async (layout, elements) => {
            await SubmitService.submitDataAsync({
              type: "asset",
              assetType: AssetTypes.DashboardConfig,
              data: {
                _id: asset?._id,
                data: {
                  user: user,
                  id: props.dashboardId,
                  config: {
                    layouts: layout,
                    data: elements,
                  },
                },
              },
              ignorePropChecks: true,
              ignoreSubmitValidation: true,
            });
            reload(true);
          }}
          editable
        />
      )}
    />
  );
};

export default ConfigurableDashboardByAsset;
