import Tools from "./Tools";

export const DataBusSubKeys = {
  SIDEBAR_CLOSE: "SIDEBAR_CLOSE",
  SIDEBAR_OPEN: "SIDEBAR_OPEN",
  PDF_VIEWER_START_EDIT_MODE: "PDF_VIEWER_START_EDIT_MODE",
  PDF_VIEWER_STOP_EDIT_MODE: "PDF_VIEWER_STOP_EDIT_MODE",
  PDF_VIEWER_START_VIEW_MODE: "PDF_VIEWER_START_VIEW_MODE",
  PDF_VIEWER_STOP_VIEW_MODE: "PDF_VIEWER_STOP_VIEW_MODE",
  PDF_VIEWER_EDIT_SAVE: "PDF_VIEWER_EDIT_SAVE",
  PDF_VIEWER_EDIT_ABORT: "PDF_VIEWER_EDIT_ABORT",

  CHART_RESIZE: "CHAR_RESIZE",
  CHART_GEO_SELECT: "CHART_GEO_SELECT",
  STACKING_PLAN_RELOAD: "STACKING_PLAN_RELOAD",
  FILTER_CHANGED: "FILTER_CHANGED",
  COMMENT_INPUT_INIT: "COMMENT_INPUT_INIT",
  UPDATE_AUTOSIZE_TEXTAREA: "UPDATE_AUTOSIZE_TEXTAREA",
  SELECT_NEXT: "SELECT_NEXT",
  OPEN_PDF_VIEWER: "OPEN_PDF_VIEWER",
  OPEN_CONTENT_VIEWER: "OPEN_CONTENT_VIEWER",
  SCROLL_GROUP: "SCROLL_GROUP",
  DROPDOWN_TOGGLE: "DROPDOWN_TOGGLE",
  CLOSE_MODAL: "CLOSE_MODAL",

  DRAWER_MANAGER_ADD: "DRAWER_MANAGER_ADD",
  DRAWER_MANAGER_CLOSE: "DRAWER_MANAGER_CLOSE",
  DRAWER_MANAGER_CLOSE_ALL: "DRAWER_MANAGER_CLOSE_ALL",

  MODAL_MANAGER_ADD: "MODAL_MANAGER_ADD",
  MODAL_MANAGER_CLOSE_ALL: "MODAL_MANAGER_CLOSE_ALL",
  BF_LONG_LAT_QUERY: "BF_LONG_LAT_QUERY",

  ASSET_CACHED: "ASSET_CACHED",
  ASSET_UPDATED: "ASSET_UPDATED",
  TOAST: "TOAST",
  TOAST_CREATE: "CREATE_TOAST_OBJ",
  TOAST_REMOVE: "REMOVE_TOAST_OBJ",
  ROUTE: "ROUTE",
  COLUMNS_UPDATED: "COLUMNS_UPDATED",
  HTTP: "HTTP",
  CACHE_CHANGED: "CACHE_CHANGED",

  CLEAR_TABLE: "CLEAR_TABLE",
  RELOAD: "RELOAD",
  SUBMIT: "SUBMIT",
  SUBMIT_START: "SUBMIT_START",
  SUBMIT_RESPONSE: "SUBMIT_RESPONSE",

  FOCUS: "FOCUS",

  DELETE_ASSET: "DELETE_ASSET",
  ACTION_DELETE_ASSET: "ACTION_DELETE_ASSET",
  ACTION_DELETE_ASSET_RESPONSE: "ACTION_DELETE_ASSET_RESPONSE",

  DETAIL_DATA_FETCH: "DETAIL_DATA_FETCH",

  TABLE_ROW_SELECTION: "TABLE_ROW_SELECTION",
  TABLE_ROW_DBL_CLICK: "TABLE_ROW_DBL_CLICK",
  TABLE_ROW_RELOAD: "TABLE_ROW_RELOAD",

  ACTION_REMOVE_GROUP_USER_ASSIGNMENT: "ACTION_REMOVE_GROUP_USER_ASSIGNMENT",
  ACTION_ADD_GROUP_USER_ASSIGNMENT: "ACTION_ADD_GROUP_USER_ASSIGNMENT",
  ACTION_CREATE_GROUP: "ACTION_CREATE_GROUP",
  ACTION_CREATE_GROUP_RESPONSE: "ACTION_CREATE_GROUP_RESPONSE",
  ACTION_DELETE_GROUP: "ACTION_DELETE_GROUP",
  ACTION_DELETE_GROUP_RESPONSE: "ACTION_DELETE_GROUP_RESPONSE",

  ACTION_CREATE_USER: "ACTION_CREATE_USER",
  ACTION_CREATE_USER_RESPONSE: "ACTION_CREATE_USER_RESPONSE",
  ACTION_DELETE_USER: "ACTION_DELETE_USER",
  ACTION_DELETE_USER_RESPONSE: "ACTION_DELETE_USER_RESPONSE",

  ACTION_LOCK_USER: "ACTION_LOCK_USER",
  ACTION_UNLOCK_USER: "ACTION_UNLOCK_USER",

  ACTION_SEND_PASSWORD_TOKEN_MAIL: "ACTION_SEND_PASSWORD_TOKEN_MAIL",
  ACTION_SEND_ACCOUNT_ACTIVATION_MAIL: "ACTION_SEND_ACCOUNT_ACTIVATION_MAIL",

  CDN_UPLOAD_FILE_STARTED: "CDN_UPLOAD_FILE_STARTED",
  CDN_UPLOAD_FILE_PROGRESS: "CDN_UPLOAD_FILE_PROGRESS",
  CDN_UPLOAD_FILE_SUCCESS: "CDN_UPLOAD_FILE_SUCCESS",
  CDN_UPLOAD_FILE_FAILED: "CDN_UPLOAD_FILE_FAILED",

  PORTAL_MODAL: "PORTAL_MODAL",

  RESUBMISSION_COUNT_UPDATE: "RESUBMISSION_COUNT_UPDATE",
};
Tools.registerWindowObject("DataBusSubkeys", DataBusSubKeys);
export const UserStatus = {
  ACTIVE: 0,
  INACTIVE: 1, //set by mandator admin
  LOCKED: 2, //locked by condition - too many login tries
  WAITING_FOR_ACTIVATION: 3, //
  DEACTIVATED: 4, //deactivated by vendor
};

export const SESSION_STORAGE_KEYS = {
  SESSION_STORAGE_DEBUG: "debugActive",
};

export const ConstantMisc = {
  LS_KEY_TRUSTED_DEVICES: "TR_DEV_LIST",
};

export const RouteConstants = {
  MODAL_PREFIX: "__",
};

export interface LSTrustedDeviceModel {
  displayName: string;
  avatar: string;
  userID: string;
  mandator: string;
  token: string;
  deviceID: string;
}

export const CACHE_TTL = 1000 * 60 * 5;

/**
 * These colors are also defined in the css variables file
 *
 * css-variables.scss
 *
 * --color-prio-low: #198c62;
 * --color-prio-medium: #879300;
 * --color-prio-high: #d26405;
 * --color-prio-critical: #c40d0d;
 */
export const PriorityColorCodes = {
  Low: "#198c62",
  Medium: "#879300",
  High: "#d26405",
  Critical: "#c40d0d",
};

export const PriorityIcons = {
  Light: {
    Low: {
      type: "light",
      data: "signal-very-low",
    },
    Medium: {
      type: "light",
      data: "signal-low",
    },
    High: {
      type: "light",
      data: "signal-strong",
    },
    Critical: {
      type: "light",
      data: "signal-full",
    },
  },
};
