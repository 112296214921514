import { CashBudgetTags } from "@/apps/tatar/cashBudget/CashBudgetConst";
import i18n from "@/i18n";
import LanguageService from "@/services/LanguageService";
import { ACCategory } from "../../../../apps/AppConfigInterfaces";
import { includesAny } from "../../../../utils/Helpers";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

class CategoryStructClass extends AbstractStructSelectors<ACCategory[]> {
  getStructType(): StructType {
    return "category";
  }
  loadStructData(types: string[]): Promise<DataByUnitType<ACCategory[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: "/liquiplanservice/getCategories",
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as ACCategory[];
        const result: DataByUnitType<ACCategory[]> = {};
        types.forEach((type) => {
          result[type] = data.filter((config) => config.data.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  findByTags(type: string, tags: string[]) {
    return this.useCache<ACCategory[]>("findByTags", arguments, () => {
      const caegories = this.getData(type);
      return caegories.filter((e) => includesAny(e.data.tags, tags));
    });
  }
  findByTagsGlobal(tags: string[]) {
    return this.useCache<ACCategory[]>("findByTagsGlobal", arguments, () => {
      const caegories = this.getAllData().flat();
      return caegories.filter((e) => includesAny(e.data.tags, tags));
    });
  }

  getTagsOf(categoryId: string) {
    return this.useCache<string[]>("getTagsOf", arguments, () => {
      const allData = this.getAllData();

      for (const data of allData) {
        for (const category of data) {
          if (category._id === categoryId) {
            return category.data.tags;
          }
        }
      }
    });
  }

  getAllDependingCategoriesFor(categoryId: string) {
    const findChilds = (cat: ACCategory) => {
      let result: ACCategory[] = [];
      if (cat) {
        result.push(cat);
        this.getAllCategorys([cat.data.type]).forEach((oCat) => {
          if (oCat.data.group === cat._id) {
            result = [...result, ...findChilds(oCat)];
          }
        });
      }

      return result;
    };

    let cat = this.getCategory(categoryId);

    return findChilds(cat);
  }
  getAllCategorys(type?: string[]) {
    return this.useCache<ACCategory[]>("getAllCategorys", arguments, () =>
      this.getAllData()
        .flat()
        .filter((e) => (type ? type.includes(e.data.type) : true))
    );
  }

  getCategorysForType(type: string) {
    return this.useCache<ACCategory[]>("getCategorysForType", arguments, () =>
      this.getAllData()
        ?.flat()
        ?.filter((e) => e.data.type === type)
    );
  }
  getCategory(CategoryId: string) {
    return this.useCache<ACCategory>("getCategory", arguments, () =>
      this.getAllData()
        ?.flat()
        .find((Category) => Category._id === CategoryId)
    );
  }

  getAllCategoriesPlannableOfType(type: string, tagFilter?: string) {
    return this.useCache<ACCategory[]>(
      "getAllCategoriesPlannableOfType",
      arguments,
      () =>
        this.getCategorysForType(type).filter((e) =>
          tagFilter
            ? e.data.tags.includes(tagFilter)
            : (e.data.tags || []).indexOf(CashBudgetTags.PREVENT_PLANNABLE) ===
              -1
        )
    );
  }

  getAssignableCategories(type: string, kind: "expense" | "income") {
    return this.useCache<ACCategory[]>(
      "getAssignableCategories",
      arguments,
      () =>
        this.getCategorysForType(type)
          .filter((e) => e.data.kind === kind)
          .filter((e) => (e.data.tags || []).indexOf("group") === -1)
    );
  }

  getPlannableCategoriesSelectOptionsForTyp(
    type: string,
    filterRelevantType?: string
  ) {
    return this.useCache(
      "getPlannableCategoriesSelectOptionsForTyp",
      arguments,
      () => [
        {
          value: "expenses",
          label: i18n.t("cb:Label.expenses"),
          children: this.getAllCategoriesPlannableOfType(
            type,
            filterRelevantType
          )
            .filter((e) => e.data.kind === "expense")
            .map((e) => ({
              value: e._id,
              label: LanguageService.translateLabel(e.data.displayName) || "-",
            })),
        },
        {
          value: "income",
          label: i18n.t("cb:Label.income"),
          children: this.getAllCategoriesPlannableOfType(
            type,
            filterRelevantType
          )
            .filter((e) => e.data.kind === "income")
            .map((e) => ({
              value: e._id,
              label: LanguageService.translateLabel(e.data.displayName) || "-",
            })),
        },
      ]
    );
  }
}
const CategoryStruct = new CategoryStructClass();
export default CategoryStruct;
