import moment from "moment";

import { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
// import "rsuite/lib/styles/themes/default/index.less";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/react";
import { createBrowserHistory } from "history";
import _ from "lodash";
import "rsuite/dist/rsuite.min.css";
import App from "./App";
import ErrorBoundaryErrorPage from "./components/AppErrorBoundary/ErrorBoundayErrorPage";
import LoadMask from "./components/LoadMask/LoadMask";
import Log from "./debug/Log";
import i18n, { i18nOptions } from "./i18n";
import "./index.scss";
import init from "./polyfill";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import AssetService from "./services/AssetService";
import CDNService from "./services/CDNService";
import CacheService from "./services/CacheService";
import GroupService from "./services/GroupService";
import IndexDBService from "./services/IndexDBService";
import MandatorService from "./services/MandatorService";
import SubmitService from "./services/SubmitService";
import UserService from "./services/UserService";
import "./styles/custom-rsuite.scss";
import { ComponentsMapper } from "./utils/ComponentsMapper";
import "ol/ol.css";
require("polyfill-object.fromentries");

(window as any)._ = _;

const history = createBrowserHistory();
if ((process.env.REACT_APP_STAGE || "local") !== "local") {
  Sentry.init({
    dsn: "https://8f07ea634e654e9c86e9e1d05f007e87@o4504820443119616.ingest.sentry.io/4504820444299269",
    integrations: [
      new Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    ignoreErrors: [
      "Loading chunk",
      "startCleanup: Page",
      "CKEditor",
      "Network error",
      /ResizeObserver loop completed with undelivered notifications/g,
      /Cannot read properties of null \(reading 'model'\)/g,
      /'insertBefore' on 'Node'/g,
      /'removeChild' on 'Node'/g,
    ],
    environment: process.env.REACT_APP_STAGE || "local",
    tracesSampleRate: Number(
      process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || 1.0
    ),
    replaysSessionSampleRate: Number(
      process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE || 1.0
    ),
    replaysOnErrorSampleRate: Number(
      process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0
    ),
  });
}

init();

ComponentsMapper.init();
CDNService.init();
SubmitService.init();
GroupService.init();
UserService.init();
CacheService.init();
MandatorService.init();
AssetService.init();
// Properties.mocked = process.env.NODE_ENV !== "production" ;

(window as any).moment = moment;
i18n
  .init(i18nOptions)
  .then(() => {
    IndexDBService.initShared()
      .then(() => {
        const container = document.getElementById("root");
        const root = createRoot(container); // createRoot(container!) if you use TypeScript

        root.render(
          <CookiesProvider>
            <DndProvider
              backend={HTML5Backend}
              options={{ enableMouseEvents: true }}
            >
              <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                  <Sentry.ErrorBoundary
                    onError={(err) => {
                      Log.info("error boundary", err);
                    }}
                    // beforeCapture={(scope, error, stack) => {
                    //   scope.
                    // }}
                    // dialogOptions={{
                    // }}
                    fallback={(errorData: {
                      error: Error;
                      componentStack: string;
                      eventId: string;
                      resetError(): void;
                    }) => {
                      Log.info("errordata", errorData);
                      return <ErrorBoundaryErrorPage errorData={errorData} />;
                    }}
                    showDialog={false}
                  >
                    <Router history={history}>
                      <Suspense fallback={<LoadMask />}>
                        <App />
                      </Suspense>
                    </Router>
                  </Sentry.ErrorBoundary>
                </I18nextProvider>
              </Provider>
            </DndProvider>
          </CookiesProvider>
        );
      })
      .catch((err) => {});
  })
  .catch((err) => {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
