import { CKEditor } from "@ckeditor/ckeditor5-react";
import EditorCK from "ckeditor5-custom-build/build/ckeditor";
import { useEffect, useRef, useState } from "react";
import Log from "../../debug/Log";
import i18n from "../../i18n";
import { Textblock } from "../../model/db/UserConfigAsset";
import DataBusDefaults from "../../services/DataBusDefaults";
import StorageUtils from "../../utils/StorageUtils";
import "./TextEditor.scss";

export const PREFIX_MENTION = "@";
export const PREFIX_VARIABLE = "$";
export interface MentionItem {
  id: string;
  type: "team" | "user";
  name: string;
}

export type VariableItem = {
  id: string;
  name: string;
  defaultText?: string;
  preventEdit?: boolean;
};

interface TextEditorProps {
  balloon?: boolean;
  initialValue?: string;
  disabled?: boolean;
  onEmptyChange?: (isEmpty: boolean) => void;
  editorRef?: React.MutableRefObject<any>;
  placeholder?: string;

  value?: string;
  onInit?: (editor) => void;

  onChange?: (value: string) => void;
  onChangeTrigger?: () => void;

  textblocks?: Textblock[];
  mentionSource?: (search: string) => Promise<MentionItem[]>;
  variableSource?: (search: string) => Promise<VariableItem[]>;
}
const TextEditor = (props: TextEditorProps) => {
  const [isEmpty, setIsEmpty] = useState<boolean>(
    !props.initialValue && !props.value
  );

  const [allVariables, setAllVariables] = useState<{
    status: "loading" | "loaded";
    result?: VariableItem[];
  }>(props.variableSource ? { status: "loading" } : null);

  useEffect(() => {
    if (props.variableSource) {
      props.variableSource("").then((variables) => {
        setAllVariables({
          status: "loaded",
          result: variables.map((e) => ({
            id: e.id,
            name: e.name,
          })),
        });
      });
    }
  }, [props.variableSource]);

  useEffect(() => {
    if (StorageUtils.SessionStorage.get("CKEDITOR_RESTART")) {
      StorageUtils.SessionStorage.delete("CKEDITOR_RESTART");

      DataBusDefaults.toast({
        type: "warning",
        duration: 8000,
        text: i18n.t(
          "TextEditor.ErrorRestarted",
          "Leider ist der Texteditor abgestürzt. Bitte versuche es erneut."
        ),
      });
    }
  }, []);
  useEffect(() => {
    props.onEmptyChange?.(isEmpty);
  }, [isEmpty]);
  const editorRef = useRef<any>(null);

  if (allVariables?.status === "loading") {
    return null;
  }
  return (
    <div className="text-editor">
      <CKEditor
        disabled={props.disabled}
        editor={props.balloon ? EditorCK.Balloon : EditorCK.Editor}
        onReady={(editor) => {
          editorRef.current = editor;
          if (props.editorRef) {
            props.editorRef.current = editor;
          }

          props.onInit?.(editor);
        }}
        onError={(error, props) => {
          if (props.willEditorRestart) {
            StorageUtils.SessionStorage.save("CKEDITOR_RESTART", true);
          }
          Log.error("error texteditor", error, props);
        }}
        onChange={(event, editor: any) => {
          // for builded online editor there are no types
          if (props.onChange) {
            const editorData = editor.getData();
            if (editorData !== props.value) {
              props.onChange(editorData);
            }
          }

          props.onChangeTrigger?.();
          setTimeout(() => {
            const firstParagraph =
              editor.ui.view.editable.element.querySelector("p");
            if (firstParagraph) {
              if (firstParagraph.classList.contains("ck-placeholder")) {
                setIsEmpty(true);
              } else {
                setIsEmpty(false);
              }
            }
          });
        }}
        data={props.value}
        config={
          {
            initialData: props.initialValue,
            textblocks: props.textblocks,
            variables: allVariables?.result,
            placeholder: props.placeholder,
            htmlSupport: {
              allow: [
                {
                  name: "div",
                  classes: ["iberio-signature"],
                },
              ],
            },
            extraPlugins: [MentionCustomization],
            //   plugins: [],
            ...((props.mentionSource || props.variableSource) && {
              mention: {
                feeds: [
                  ...(props.mentionSource
                    ? [
                        {
                          marker: PREFIX_MENTION,
                          feed: async (search) => {
                            const users = await props.mentionSource(search);

                            return users.map((user) => ({
                              id: `${PREFIX_MENTION}${user.name}`,
                              name: user.name,
                              type: user.type,
                              objectId: user.id,
                              mentionType: "mention",
                            }));
                          },
                          itemRenderer: renderMentionItem,
                        },
                      ]
                    : []),
                  ...(props.variableSource
                    ? [
                        {
                          marker: PREFIX_VARIABLE,
                          dropdownLimit: 50,
                          feed: async (search) => {
                            const variables = await props.variableSource(
                              search
                            );

                            return variables.map((variable) => ({
                              id: `${PREFIX_VARIABLE}${variable.name}`,
                              name: variable.name,
                              variableId: variable.id,
                              mentionType: "variable",
                            }));
                          },
                          itemRenderer: renderVariableItem,
                        },
                      ]
                    : []),
                ],
              },
            }),
          } as any
        }
        // config={ /* the editor configuration */ }
      />
    </div>
  );
};

export default TextEditor;

function renderMentionItem(item: MentionItem) {
  const itemElement = document.createElement("span");

  itemElement.classList.add("mention");
  itemElement.textContent = `${item.name}`;

  return itemElement;
}
function renderVariableItem(item: VariableItem) {
  const itemElement = document.createElement("span");

  itemElement.classList.add("variable");
  itemElement.textContent = `${item.name}`;

  return itemElement;
}

function MentionCustomization(editor) {
  editor.conversion.for("upcast").elementToAttribute({
    view: {
      name: "span",
      // key: "data-variable-id",
      classes: "mention-variable",
      attributes: {
        "data-variable-id": true,
      },
    },
    model: {
      key: "mention",
      value: (viewItem) => {
        const mentionAttribute = editor.plugins
          .get("Mention")
          .toMentionAttribute(viewItem, {
            mentionType: "variable",
            variableId: viewItem.getAttribute("data-variable-id"),
          });

        return mentionAttribute;
      },
    },
    converterPriority: "high",
  });

  editor.conversion.for("upcast").elementToAttribute({
    view: {
      name: "span",
      // key: "data-mention",
      classes: "mention",
      attributes: {
        "data-mention-type": true,
        "data-object-id": true,
      },
    },
    model: {
      key: "mention",
      value: (viewItem) => {
        const mentionAttribute = editor.plugins
          .get("Mention")
          .toMentionAttribute(viewItem, {
            mentionType: "mention",
            type: viewItem.getAttribute("data-mention-type"),
            objectId: viewItem.getAttribute("data-object-id"),
          });

        return mentionAttribute;
      },
    },
    converterPriority: "high",
  });

  editor.conversion.for("downcast").attributeToElement({
    model: "mention",
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue) {
        return;
      }
      if (modelAttributeValue.mentionType === "mention") {
        return writer.createAttributeElement(
          "span",
          {
            class: "mention",
            "data-mention": modelAttributeValue.id,
            "data-object-id": modelAttributeValue.objectId,
            "data-mention-type": modelAttributeValue.type,
          },
          {
            priority: 20,
            id: modelAttributeValue.uid,
          }
        );
      }
      if (modelAttributeValue.mentionType === "variable") {
        return writer.createAttributeElement(
          "span",
          {
            class: "mention-variable",
            "data-variable-id": modelAttributeValue.variableId,
          },
          {
            priority: 20,
            id: modelAttributeValue.uid,
          }
        );
      }
    },
    converterPriority: "high",
  });
}
