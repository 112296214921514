import { TypedBaseAsset } from "@/model/general-assets/BaseAsset";
import { CurrencyValueWithConversion } from "@/modules/abstract-ui/forms/input/BFCurrency";
import { DocumentStoreDocument } from "@/modules/document-store/DSInterfaces";
import { CDNBucketObject } from "@/services/CDNService";
import { TranslatedLabel } from "@/services/LanguageService";
import { LinkedAsset } from "../../cashBudget/model/CashBudgetEntry";
import { OpenDebitPositionBookingValue } from "../../cashBudget/views/tenants/components/rental-agreements/accounting/CBRentalAgreementOpenDebitPositions";

export enum AccountingBookingType {
  SOLL = "S",
  HABEN = "H",
}

export type AccountingBookingData = {
  linkedObject: string | null; // linked portfolio object
  costId: string | null; // linked gegenbuchung w/e

  notes: {
    text: string;
    userId: string;
    date: Date;
  }[];

  creationType: "manual" | "system";
  value: CurrencyValueWithConversion;
  date: Date; // this is bookingdate if performanceDate is not set
  // bookingDate: Date; // bookingDate
  objectId: string;
  performanceDate: Date; // Leistungsdatum
  // description: string;
  account: string;
  contraAccount: string;
  bookingType: AccountingBookingType;
  usedForId?: string;
  referenceField?: string;
  // dont we need that?
  // exportObjectId: string;

  // BU: number; // Buchungsschlüssel
  // GU: boolean; // Generalumkehr
  // invoice: {
  //   date: Date;
  //   field1: string;
  //   field2: string;
  // };

  // costCenter1: string;
  // costCenter2: string;

  groupId: string | null; // wenn eine Buchung weitere Buchungungen auslöst, wie Ust, hat man dieselbe groupId

  entity: string;
  type: string;
  attachments: DocumentStoreDocument[];

  //iberio links
};

export type AccountingAccountData = {
  internalId: string;
  displayName: TranslatedLabel;
  accountType: AccountType;
  priority?: number;
  taxAccount?: {
    taxRate: number;
  };
  objectId?: string;
  status: "active" | "archived";
  linkedAsset?: LinkedAsset;
  BUKey: number | null;
  exportId: number | null;
  sumHaben: number;
  sumSoll: number;
  sumBalance: number;
  detailBalance: {
    [accountNumber: string]: {
      sumSoll: number;
      sumHaben: number;
      sumBalance: number;
    };
  };
};

export type AccountingExportConfigDate = {
  exportType: "datev";
  typeData: {
    FiscalYear: Date;
    AdvisorNumber: string;
    ClientNumber: string;
    AccountLength: number;
    CurrencyCode: string;
    DatevFormatVersion: string;
  };
};
export type AccountingExportConfig = AccountingExportConfigDate;
export type AccountingConfigData = {
  startOfAccounting: Date;
  entity: string;
  type: string;
  currency: string;
  status: "active" | "archived";
  // accounts: AccountingAccountDataAccount[];
  exportConfig: AccountingExportConfig;
};

export type AccountingBookingGroupData = {
  account: string;
  balance: number;
  booked: {
    soll: number;
    haben: number;
    balance: number;
  };
  sum: {
    soll: number;
    haben: number;
    balance: number;
  };

  date: Date;
  displayName: string;
  entity: string;
  objectId: string;
  note: {
    date: Date;
    text: string;
    userId: string;
  };

  linkedLqBooking?: string;
  linkedAsset: LinkedAsset[];
};
export type AccountingConfig = TypedBaseAsset<AccountingConfigData>;

export type AccountingAccount = TypedBaseAsset<AccountingAccountData>;
export type AccountingBooking = TypedBaseAsset<AccountingBookingData>;
export type AccountingBookingWithGroupAndBooking = AccountingBooking & {
  expand: {
    groupId: AccountingBookingGroup;
    contraAccount: AccountingBooking;
  };
};
export type AccountingBookingGroup = TypedBaseAsset<AccountingBookingGroupData>;

export enum AccountType {
  debitor_rentalagreement = "debitor_rental",
  debitor_deposit = "debitor_deposit",
  debitor_loss = "debitor_loss",
  debitor_rentalposition = "debitor_rentalposition",
  debitor_rent_reduction = "debitor_rent_reduction",
  debitor_rent_loss = "debitor_rent_loss",
  bank = "bank",
  debitor_other = "debitor_other",
  debitor_dunning = "debitor_dunning",
}

export const isAccountTypeGenerated = (accountType: AccountType): boolean => {
  return [
    AccountType.debitor_rentalagreement,
    AccountType.debitor_rentalposition,
    AccountType.bank,
    AccountType.debitor_dunning,
  ].includes(accountType);
};

export type AccountingBookingFormValue = {
  groupDisplayName: string;
  date: Date;
  entity: string;
  account: string;
  fullAmount: number;
  note: string;
  linkedAsset: {
    assetType: string;
    assetId: string;
    extra?: any;
  }[];
  frames: {
    objectId?: string;
    contraAccount: string;
    bookings: OpenDebitPositionBookingValue[];
  }[];
};

export type AccountingIDMapping = {
  accountId: string;
  internalId: string;
  exportId: number | null;
  BUKey: number | null;
};

export type AccountingExportDialogValueOld = {
  entityIds: string[];
  fromBookingDate: Date;
  toBookingDate: Date;
  useAllAccounts: boolean;
  includeBank: boolean;
  accounts?: string[];
};

export enum AccountExportType {
  ONLY_SOLL = "ONLY_SOLL",
  ONLY_CORRECTION = "ONLY_CORRECTION",
  SOLL_AND_CORRECTION = "SOLL_AND_CORRECTION",
}
export type AccountingExportDialogValue = {
  template: {
    type: "new" | "template";
    id: string;
  };
  type: string;
  entityIds: string[];
  useAllAccounts: boolean;
  accounts?: string[];
  fromBookingDate: Date;
  toBookingDate: Date;

  exportType: AccountExportType;
};

export type AccountingSettingsDatevFormValue = {
  exportType: "datev";
  FiscalYear: Date;
  AdvisorNumber: string;
  ClientNumber: string;
  AccountLength: number;
  CurrencyCode: string;
  DatevFormatVersion: string;
};
export type AccountingSettingsFormValue = AccountingSettingsDatevFormValue;

export type AccountingBookingHistoryData = {
  exports?: {
    bookingCount: number;
    entityId: string;
    exportType: "datev";
    exportTypeData: any;
  }[];
  type: string;
  dateFrom: Date;
  dateTo: Date;
  exportType: AccountExportType;
  file: CDNBucketObject;
};
export type AccountingBookingHistory =
  TypedBaseAsset<AccountingBookingHistoryData>;
