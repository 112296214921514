import { Plugin } from "@ckeditor/ckeditor5-core";
import {
  addListToDropdown,
  createDropdown,
  Model,
} from "@ckeditor/ckeditor5-ui";
import { add } from "@ckeditor/ckeditor5-utils/src/translation-service";

import { Collection } from "@ckeditor/ckeditor5-utils";

add("en", {
  Variables: "Variables",
});
add("de", {
  Variables: "Variablen",
});

export default class VariablesPlugin extends Plugin {
  init() {
    const editor = this.editor;
    if (!editor.config._config.variables) {
      return;
    }
    const variables = editor.config._config.variables;
    const { t } = editor.locale;

    editor.ui.componentFactory.add("variablesDropdown", (locale) => {
      const collection = new Collection();
      variables.forEach((variable) => {
        collection.add({
          type: "button",
          model: new Model({
            id: variable.id,
            name: variable.name,
            label: variable.name,
            withText: true,
          }),
        });
      });

      const listDropdown = createDropdown(locale);
      listDropdown.class = "variables-dropdown ck-dropdown-list";
      listDropdown.buttonView.set({
        label: t("Variables"),
        withText: true,
      });
      addListToDropdown(listDropdown, collection);

      listDropdown.on("execute", (eventInfo) => {
        const { id, name } = eventInfo.source;
        const currentPosition =
          editor.model.document.selection.getFirstPosition();

        editor.model.change(() => {
          var viewFragment = editor.data.processor.toView(
            `<span class="mention-variable" data-variable-id="${id}">$${name}</span>`
          );
          var modelFragment = editor.data.toModel(viewFragment);

          editor.model.insertContent(modelFragment, currentPosition);
        });
      });
      return listDropdown;
    });
  }
}
