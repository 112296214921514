import { FC, useState } from "react";
import { ObjectFloorPlanProps } from "./types";
import { AssetTypes } from "@/model/AssetTypes";
import { OAFloorPlanBase } from "@/apps/tatar/objectsApp/types/object.interface";
import { FETCH_FLOOR_PLAN_LIST_IDENTIFIER } from "./config";
import Log from "@/debug/Log";
import FloorPlanService, {
  CreateFloorPlanRequestData,
  UpdateFloorPlanRequestData,
} from "@/services/FloorPlanService";
import MQ from "@/utils/MatchQueryUtils";
import { FloorPlanToastService } from "./helpers";
import DataBusDefaults from "@/services/DataBusDefaults";
import {
  FloorPlanForm,
  FloorPlanListItem,
  FloorPlanFormValues,
  AllPositionsModalContent,
} from "./components";
import { Loader } from "rsuite";
import ModalManager from "@/components/ModalComponent/ModalManager";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import styles from "./ObjectFloorPlan.module.scss";
import I18n from "@/i18n";

/**
 * TODO:
 *
 * - [X] use list component to fetch and display floor plans
 * - [-] use a swiper (or just normal nav buttons first) to navigate through the floor plans
 * - [X] add a modal create new floor plans (form will be in extra component)
 */
const ObjectFloorPlan: FC<ObjectFloorPlanProps> = ({
  object: buildingBase,
}) => {
  // ! state
  const [loading, setLoading] = useState(false);

  // ! handlers
  const onCreateFloorPlan = async (
    formValues: FloorPlanFormValues,
    closeModal: () => void
  ) => {
    const { image, ...floorPlanValues } = formValues;

    try {
      const requestConfig: CreateFloorPlanRequestData = {
        objectId: buildingBase._id,
        entityId: buildingBase.data.entity,
        type: buildingBase.data.type,
        status: "active",
        ...floorPlanValues,
      };

      const { _id: floorPlanBaseId } = await FloorPlanService.createFloorPlan({
        requestConfig,
      });

      // upload image if exists
      if (image) {
        try {
          await FloorPlanService.uploadImage({
            requestConfig: { floorPlanBaseId, image },
          });

          FloorPlanToastService.createSuccess();
        } catch (error) {
          Log.error(`Error creating Floor Plan Image`, error);
          FloorPlanToastService.createdWithoutImage();
        }
      }

      if (!image) FloorPlanToastService.createSuccess();

      DataBusDefaults.reload({
        identifiers: [FETCH_FLOOR_PLAN_LIST_IDENTIFIER],
      });

      closeModal();
    } catch (error) {
      Log.error("Error creating Floor Plan", error);
      FloorPlanToastService.createError();
    }
  };

  const onUpdateFloorPlan = async (
    formValues: FloorPlanFormValues,
    closeModal: () => void,
    floorPlanBaseId: string
  ) => {
    const { image, ...floorPlanValues } = formValues;

    try {
      const requestConfig: UpdateFloorPlanRequestData = {
        _id: floorPlanBaseId,
        data: {
          ...floorPlanValues,
          description: formValues.description || "",
        },
      };

      await FloorPlanService.updateFloorPlan({ requestConfig });

      // upload image if exists
      if (image) {
        try {
          await FloorPlanService.uploadImage({
            requestConfig: { floorPlanBaseId, image },
          });

          FloorPlanToastService.updateSuccess();
        } catch (error) {
          Log.error(`Error updating Floor Plan Image`, error);
          FloorPlanToastService.updatedWithoutImage();
        }
      }

      if (!image) FloorPlanToastService.updateSuccess();

      DataBusDefaults.reload({
        identifiers: [FETCH_FLOOR_PLAN_LIST_IDENTIFIER],
      });

      closeModal();
    } catch (error) {
      Log.error("Error updating Floor Plan", error);
      FloorPlanToastService.updateError();
    }
  };

  const onDeleteFloorPlan = async (floorPlanBaseId: string) => {
    setLoading(true);

    try {
      await FloorPlanService.deleteFloorPlan({
        requestConfig: { _id: floorPlanBaseId },
        identifiersToUpdate: [FETCH_FLOOR_PLAN_LIST_IDENTIFIER],
      });

      FloorPlanToastService.deleteSuccess();
    } catch (error) {
      Log.error("Error deleting Floor Plan", error);
      FloorPlanToastService.deleteError();
    } finally {
      setLoading(false);
    }
  };

  const onFloorPlanFormSubmit = (
    formValues: FloorPlanFormValues,
    closeModal: () => void,
    floorPlanId?: string
  ) => {
    if (!floorPlanId) return onCreateFloorPlan(formValues, closeModal);

    return onUpdateFloorPlan(formValues, closeModal, floorPlanId);
  };

  const onCreateEditPlanClick = (floorPlanBase?: OAFloorPlanBase) => {
    ModalManager.show({
      noPadding: true,
      backdrop: "static",
      size: "md",
      content: (_states, _setStates, closeModal) => (
        <FloorPlanForm
          floorPlanBase={floorPlanBase}
          onAbort={closeModal!}
          onFormSubmit={(values, floorPlanId) =>
            onFloorPlanFormSubmit(values, closeModal!, floorPlanId)
          }
        />
      ),
    });
  };

  const onFloorPlanClick = (floorPlanBase: OAFloorPlanBase) => {
    ModalManager.show({
      title: floorPlanBase.data.name,
      size: "md",
      closable: true,
      backdrop: "static",
      buttons: [
        {
          text: I18n.t("Global.Buttons.close"),
          closeOnClick: true,
          appearance: "default",
        },
      ],
      content: <AllPositionsModalContent floorPlanBase={floorPlanBase} />,
    });
  };

  // ! render
  return (
    <div className={styles.object_floor_plan}>
      {/* HEADER */}
      <div className={styles.header}>
        <BFButton onClick={() => onCreateEditPlanClick()}>
          Create Floor Plan
        </BFButton>
      </div>

      {/* CONTENT */}
      <div className={styles.content}>
        {/* LOADER */}
        {loading && (
          <div className={styles.loader}>
            <Loader size="lg" />
          </div>
        )}

        {/* LIST */}
        <ListComponent
          identifier={FETCH_FLOOR_PLAN_LIST_IDENTIFIER}
          assetType={AssetTypes.Portfolio.FloorPlan}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.objectId", buildingBase._id),
            MQ.ne("data.status", "archived")
          )}
          render={(floorPlanBaseItem: OAFloorPlanBase) => (
            <FloorPlanListItem
              floorPlanBase={floorPlanBaseItem}
              onEditClick={onCreateEditPlanClick}
              onDeleteClick={onDeleteFloorPlan}
              onFloorPlanClick={onFloorPlanClick}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ObjectFloorPlan;
