import { convertOpenLayerCoordinates } from "@/components/OpenLayerWrapper/utils";
import i18n from "@/i18n";
import DataBusDefaults from "@/services/DataBusDefaults";
import classNames from "classnames";
import { Map, View } from "ol";
import { getCenter } from "ol/extent";
import { Point } from "ol/geom";
import { DrawEvent } from "ol/interaction/Draw";
import { Layer } from "ol/layer";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import Projection from "ol/proj/Projection";
import { ImageStatic } from "ol/source";
import { ImageSourceEvent } from "ol/source/Image";
import VectorSource from "ol/source/Vector";
import React, { useEffect, useRef, useState } from "react";
import { OpenLayerControlsPanel, OpenLayerLoader } from "./components";
import { OPEN_LAYER_EXTENT } from "./config";
import { createFeatureInteractions, createFeatures } from "./helpers";
import { useDrawIconWithSvg, useFeaturesWithSvg } from "./hooks";
import styles from "./OpenLayerWrapper.module.scss";
import {
  CustomFeatureInfo,
  InteractionsState,
  OpenLayerLocationItem,
  OpenLayerWrapperProps,
} from "./types";

export const OpenLayerWrapper: React.FC<OpenLayerWrapperProps> = ({
  drawIcon,
  drawColor,
  imageUrl,
  maxLocations,
  predefinedLocations,
  withControlsPanel,
  onSubmitProgress,
  onEditModeChange,
}) => {
  // ! refs
  const mapElement = useRef<HTMLDivElement | null>(null);

  // ! state
  const [map, setMap] = useState<Map | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  // map items
  const [projection, setProjection] = useState<Projection | null>(null);
  const [featureLayer, setFeatureLayer] = useState<Layer | null>(null);
  const [featureSource, setFeatureSource] = useState<VectorSource | null>(null);
  const [interactions, setInteractions] = useState<InteractionsState | null>(
    null
  );

  // load svg to draw on the map
  const { drawIconWithSvg, drawIconWithSvgLoading } = useDrawIconWithSvg(
    drawIcon,
    drawColor
  );

  // load predefined features svg
  const { featuresWithSvg, featuresWithSvgLoading } =
    useFeaturesWithSvg(predefinedLocations);

  // ! handlers
  const onEditClick = () => {
    setIsEditable(true);
  };

  const onCancelClick = () => {
    if (!featureSource) return;

    const features = createFeatures(featuresWithSvg);

    featureSource.clear();
    featureSource.addFeatures(features);

    setIsEditable(false);
  };

  const onSaveClick = () => {
    if (!featureSource) return;

    const featuresFromSource = featureSource.getFeatures();

    const featuresToSave: OpenLayerLocationItem[] = [];

    featuresFromSource.forEach((feature) => {
      const customFeatureInfo: CustomFeatureInfo | undefined =
        feature.get("customFeatureInfo");
      if (!customFeatureInfo) return;

      const featureGeometry = feature.getGeometry();

      // all features are points, but in case of future changes
      if (featureGeometry instanceof Point) {
        const featureCoordinates = featureGeometry.getCoordinates();

        featuresToSave.push({
          ...customFeatureInfo,
          coordinates: convertOpenLayerCoordinates(featureCoordinates),
        });
      }
    });

    onSubmitProgress?.(featuresToSave);
    setIsEditable(false);
  };

  // ! effects
  // 1. create map and independent map items
  useEffect(() => {
    if (!mapElement.current) return;

    const projectionInstance = new Projection({
      code: "image-projection",
      units: "pixels",
      extent: OPEN_LAYER_EXTENT,
    });

    const featureSourceInstance = new VectorSource();

    const featureLayerInstance = new VectorLayer({
      source: featureSourceInstance,
    });

    const mapInstance = new Map({
      target: mapElement.current, // DOM element for the map
      layers: [featureLayerInstance], // Add image layer and feature layer (empty)
      view: new View({
        projection: projectionInstance, // Use the created projection for the map
        center: getCenter(OPEN_LAYER_EXTENT), // Center the map using the image extent
        zoom: 1,
      }),
    });

    setMap(mapInstance);
    setProjection(projectionInstance);
    setFeatureSource(featureSourceInstance);
    setFeatureLayer(featureLayerInstance);

    // cleanup
    return () => {
      mapInstance.setTarget(undefined);
    };
  }, []);

  // 2. create image layer and react on imageUrl change
  useEffect(() => {
    if (!map || !imageUrl || !projection) return;

    // create layer
    const imageLayer = new ImageLayer({
      source: new ImageStatic({
        url: imageUrl,
        projection: projection,
        imageExtent: OPEN_LAYER_EXTENT,
      }),
    });

    // handle image load error
    const onImageLoadError = (error: ImageSourceEvent) => {
      setImageLoading(() => false);
      console.error("Failed to load map image: ", error);

      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "OpenLayer.messages.errors.failedToLoadMapImage",
          "Der Raumplan konnte nicht geladen werden."
        ),
      });
    };
    const onImageLoadStart = () => {
      setImageLoading(() => true);
    };

    const onImageLoadEnd = () => {
      setImageLoading(() => false);
    };

    const source = imageLayer.getSource();
    source?.on("imageloadstart", onImageLoadStart);
    source?.on("imageloadend", onImageLoadEnd);
    source?.on("imageloaderror", onImageLoadError);

    // add image layer to the map
    const mapLayers = map.getLayers().getArray();
    map.setLayers([imageLayer, ...mapLayers]);

    map.getView().fit(OPEN_LAYER_EXTENT, {
      size: map.getSize(), // set the size of the map
    });

    // cleanup
    return () => {
      source?.un("imageloadstart", onImageLoadStart);
      source?.un("imageloadend", onImageLoadEnd);
      source?.un("imageloaderror", onImageLoadError);
      map.removeLayer(imageLayer);
    };
  }, [map, imageUrl, projection]);

  // 3. create interactions and react on isEditable change
  useEffect(() => {
    if (!map || !featureSource || !featureLayer) return;

    if (!isEditable) {
      if (!interactions) return;

      Object.values(interactions).forEach((interactionItem) => {
        map.removeInteraction(interactionItem);
      });
      return;
    }

    // create interactions if they are not created yet
    if (!interactions) {
      const createdInteractions = createFeatureInteractions({
        featureSource,
        featureLayer,
      });

      setInteractions(createdInteractions);
      return;
    }

    // Set interactions for features. Order is important!!!
    map.addInteraction(interactions.selectClickInteraction);
    map.addInteraction(interactions.drawInteraction);
    map.addInteraction(interactions.modifyInteraction);
  }, [map, isEditable, interactions, featureSource, featureLayer]);

  // 4. create draw interaction listener and react on drawIconWithSvg change
  useEffect(() => {
    if (!interactions || !featureSource || !drawIconWithSvg) return;

    const drawEndHandler = (event: DrawEvent) => {
      const existingFeatures = featureSource.getFeatures();

      if (maxLocations && existingFeatures.length >= maxLocations) {
        DataBusDefaults.toast({
          type: "error",
          text: i18n.t(
            "OpenLayer.messages.errors.maxFeaturesExceeded",
            "Die maximal zulässige Anzahl an Positionen ist {{max}}",
            { max: maxLocations }
          ),
        });
        return;
      }

      const eventFeature = event.feature;
      const eventFeatureGeometry = eventFeature.getGeometry();

      if (eventFeatureGeometry instanceof Point) {
        const createdFeatures = createFeatures([
          {
            ...drawIconWithSvg,
            coordinates: eventFeatureGeometry.getCoordinates(),
          },
        ]);

        featureSource.addFeatures(createdFeatures);
      }
    };

    interactions.drawInteraction.on("drawend", drawEndHandler);

    return () => {
      interactions.drawInteraction.un("drawend", drawEndHandler);
    };
  }, [interactions, drawIconWithSvg, featureSource, maxLocations]);

  // 5. create predefined features and react on featuresWithSvg change
  useEffect(() => {
    if (!featureSource) return;

    if (!featuresWithSvg) {
      featureSource.clear();
      return;
    }

    const features = createFeatures(featuresWithSvg);

    featureSource.clear();
    featureSource.addFeatures(features);
  }, [featuresWithSvg, featureSource]);

  // 6. react on isEditable and notify parent component
  useEffect(() => {
    onEditModeChange?.(isEditable);
  }, [isEditable]); // eslint-disable-line react-hooks/exhaustive-deps

  // ! render
  return (
    <div className={classNames(styles.wrapper, "open-layer-wrapper-container")}>
      <OpenLayerLoader
        loading={
          !map ||
          imageLoading ||
          featuresWithSvgLoading ||
          drawIconWithSvgLoading
        }
      />

      {withControlsPanel && (
        <OpenLayerControlsPanel
          isEditable={isEditable}
          onEditClick={onEditClick}
          onCancelClick={onCancelClick}
          onSaveClick={onSaveClick}
        />
      )}

      <div ref={mapElement} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default OpenLayerWrapper;
