import { FC, useEffect, useState } from "react";
import i18n from "@/i18n";
import axios from "axios";
import {
  AllPositionsInitialState,
  AllPositionsModalContentProps,
} from "./types";
import Log from "@/debug/Log";
import DataBusDefaults from "@/services/DataBusDefaults";
import FloorPlanService from "@/services/FloorPlanService";
import LoadPage from "@/components/LoadPage/LoadPage";
import { OpenLayerWrapper } from "@/components/OpenLayerWrapper";
import { ErrorContentComponent } from "@/components/ErrorContentComponent";
import styles from "./AllPositionsModalContent.tsx.module.scss";

const AllPositionsModalContent: FC<AllPositionsModalContentProps> = ({
  floorPlanBase,
}) => {
  // ! state
  const [initialState, setInitialState] =
    useState<AllPositionsInitialState | null>(null);
  const [isError, setIsError] = useState(false);

  // ! effects
  useEffect(() => {
    const imageUrlTokenSource = axios.CancelToken.source();
    const locationsInfoTokenSource = axios.CancelToken.source();

    const fetchRequiredData = async () => {
      try {
        const [imageUrl, predefinedLocations] = await Promise.all([
          FloorPlanService.fetchImageURL(floorPlanBase, imageUrlTokenSource),
          FloorPlanService.fetchLocationsInfo(
            floorPlanBase._id,
            locationsInfoTokenSource.token
          ),
        ]);

        setInitialState({
          imageUrl,
          predefinedLocations,
        });
      } catch (error) {
        if (axios.isCancel(error)) return;

        setIsError(true);

        Log.error("Error loading All Positions modal content", error);

        DataBusDefaults.toast({
          type: "error",
          text: i18n.t(
            "cb:FloorPlanPosition.messages.errors.failedToFetchFloorPlanPositions",
            "Fehler beim Abrufen der Grundrisspositionen"
          ),
        });
      }
    };

    void fetchRequiredData();

    return () => {
      imageUrlTokenSource.cancel();
      locationsInfoTokenSource.cancel();
    };
  }, [floorPlanBase]);

  if (isError) {
    return (
      <ErrorContentComponent
        description={i18n.t(
          "cb:FloorPlanPosition.messages.errors.failedToFetchFloorPlanPositions",
          "Fehler beim Abrufen der Grundrisspositionen"
        )}
      />
    );
  }
  if (!initialState) return <LoadPage size="lg" />;

  // ! render
  return (
    <div className={styles.open_layer_container}>
      {!initialState.predefinedLocations.length && (
        <div className={styles.hint}>
          {i18n.t(
            "cb:FloorPlan.messages.info.floorPlanHasNoPositions",
            "Der Grundriss hat noch keine Positionen."
          )}
        </div>
      )}

      <OpenLayerWrapper
        imageUrl={initialState.imageUrl}
        predefinedLocations={initialState.predefinedLocations}
      />
    </div>
  );
};

export default AllPositionsModalContent;
