import i18n from "@/i18n";
import { FloorPlanPositionsBase } from "@/apps/tatar/objectsApp/types/object.interface";
import Log from "@/debug/Log";
import DataBusDefaults from "@/services/DataBusDefaults";
import ModalManager from "@/components/ModalComponent/ModalManager";
import FloorPlanPositionsService from "@/services/FloorPlanPositionsService";

// ! TOAST
export const AddPositionsFormToastService = {
  failedToLoadFloorPlanImage() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.errors.failedToLoadFloorPlanImage",
        "Das Laden des Grundrissbildes ist fehlgeschlagen"
      ),
    });
  },

  failedToSaveFloorPlanPosition() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlanPosition.messages.errors.failedToSaveFloorPlanPosition",
        "Erstellung der Grundrissposition fehlgeschlagen"
      ),
    });
  },

  failedToUpdateFloorPlanPosition() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlanPosition.messages.errors.failedToUpdateFloorPlanPosition",
        "Aktualisierung der Grundrissposition fehlgeschlagen"
      ),
    });
  },

  failedToFetchFloorPlanPosition() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlanPosition.messages.errors.failedToFetchFloorPlanPosition",
        "Fehler beim Abrufen der Grundrissposition"
      ),
    });
  },
};

// ! SELECT ERROR HANDLER
export const handleSelectRequestRejection = (
  imageResult: PromiseSettledResult<string>,
  positionResult: PromiseSettledResult<FloorPlanPositionsBase | null>
) => {
  if (imageResult.status === "rejected") {
    AddPositionsFormToastService.failedToLoadFloorPlanImage();
    Log.error("Error fetching image for floor plan", imageResult.reason);
  }

  if (positionResult.status === "rejected") {
    AddPositionsFormToastService.failedToFetchFloorPlanPosition();
    Log.error("Error fetching floorPlanPositionBase", positionResult.reason);
  }
};

// ! FIND, CONFIRM AND DELETE POSITIONS
// todo @Anton backend limit problem
export const processExistingPositions = async (
  assetId: string
): Promise<boolean> => {
  // check if there are existing positions
  const existingPositions =
    await FloorPlanPositionsService.fetchMultiplePositions({ assetId });

  // allow creating, if not
  if (!existingPositions) return true;

  // get user confirmation to delete existing positions
  const userConfirmed = await new Promise<boolean>((resolve) => {
    ModalManager.confirm({
      title: i18n.t(
        "cb:FloorPlanPosition.messages.confirm.deleteOtherPositions.title",
        "Andere Positionen entfernen"
      ),
      message: i18n.t(
        "cb:FloorPlanPosition.messages.confirm.deleteOtherPositions.message",
        "Alle anderen Positionen in allen Grundrissen werden entfernt"
      ),
      onConfirm: () => resolve(true),
      onAbort: () => resolve(false),
    });
  });

  // forbid creating, if not
  if (!userConfirmed) return false;

  // remove existing positions
  const idsToDelete = existingPositions.map(({ _id }) => _id);
  await FloorPlanPositionsService.deleteMultiplePositions(idsToDelete);

  // allow creating
  return true;
};
