import i18n from "@/i18n";
import { reloadStructData } from "@/redux/actions/struct/struct-actions";
import { store } from "@/redux/store";
import CacheService from "@/services/CacheService";
import DataBusDefaults from "@/services/DataBusDefaults";
import ServiceUtils from "@/services/ServiceUtils";
import { HTTP } from "@/utils/Http";
import { OAObject, ObjectFormValue } from "./types/object.interface";

class ObjectServiceClass {
  public async submitObject(
    objectFormValue: ObjectFormValue,
    assetId?: string
  ) {
    if (!objectFormValue) {
      throw new Error("objectFormValue is null or undefined");
    }
    const submitData = { ...objectFormValue };
    if (assetId) {
      submitData.entity = undefined;
      submitData.objectKindId = undefined;
      submitData.type = undefined;
    } else {
      submitData.status = undefined;
    }

    const asset = (await HTTP.post({
      url: assetId
        ? `/api/portfolioObject/${assetId}/updatePortfolioObject`
        : `/api/portfolioObject/createPortfolioObject`,
      target: "EMPTY",
      bodyParams: {
        ...submitData,
      },
    })) as OAObject;

    CacheService.update(asset);

    await store.dispatch(reloadStructData("orga"));
    return asset;
  }

  public async setDebitRunStopStatus(objId: string, stopDebitRun: boolean) {
    return ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/portfolioObject/${objId}/setDebitRunStatus`,
        target: "EMPTY",
        bodyParams: {
          stopDebitRun,
        },
      });
      CacheService.update(result);
    });
  }

  public async planDebitPositionForObject(objId: string, date: Date) {
    return ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/planDebitPositionForObject/${objId}`,
        target: "EMPTY",
        bodyParams: {
          date,
        },
      });
      CacheService.update(result);
      DataBusDefaults.toast({
        type: "success",
        text: i18n.t(
          "Object.Immo.Action.PlanDebitPositionForObject.successMessage",
          "Sollstellungen wurden geplant"
        ),
      });
    });
  }
}
const ObjectService = new ObjectServiceClass();
export default ObjectService;
