import { FC, useState } from "react";
import { Field } from "react-final-form";
import { OpenLayerFieldProps } from "./types";
import FormValidators from "@/components/Form/Validation/FormValidators";
import { OpenLayerWrapper } from "@/components/OpenLayerWrapper";
import ValidationPopover from "@/modules/abstract-ui/general/ValidationPopover/ValidationPopover";
import FocusedEditComponent from "@/components/FocusedEditComponent/FocusedEditComponent";
import styles from "./OpenLayerField.module.scss";

const OpenLayerField: FC<OpenLayerFieldProps> = ({
  imageURL,
  drawIcon,
  drawColor,
  maxUnits,
  onOpenLayerSubmit,
}) => {
  // ! state
  const [isOpenLayerInEditMode, setIsOpenLayerInEditMode] = useState(false);

  // ! render
  return (
    <Field name="locations" validate={FormValidators.required()}>
      {({ meta, input }) => {
        if (!imageURL) return null;

        const errorMsg = meta.error && meta.touched ? meta.error : "";

        return (
          <ValidationPopover level="error" message={errorMsg}>
            <div className={styles.open_layer_container}>
              <FocusedEditComponent editActive={isOpenLayerInEditMode}>
                <OpenLayerWrapper
                  withControlsPanel
                  key={imageURL}
                  drawIcon={drawIcon}
                  drawColor={drawColor}
                  imageUrl={imageURL}
                  predefinedLocations={input.value}
                  maxLocations={maxUnits}
                  onSubmitProgress={onOpenLayerSubmit}
                  onEditModeChange={setIsOpenLayerInEditMode}
                />
              </FocusedEditComponent>
            </div>
          </ValidationPopover>
        );
      }}
    </Field>
  );
};

export default OpenLayerField;
