import { useGlobalKeyEvent } from "@/utils/Hooks";
import { useEffect, useState } from "react";
import { Drawer } from "rsuite";
import { useDatabus } from "../../redux/hooks";
import { DataBusSubKeys } from "../../utils/Constants";
import "./DrawerController.scss";

export interface DrawerParams {
  title?: string | React.ReactNode;
  ignoreEscapeToClose?: boolean;
  content:
    | string
    | React.ReactNode
    | ((closeModal?: () => void) => React.ReactNode);
  size: "xs" | "sm" | "md" | "lg" | "full" | number | string;
  placement: "top" | "right" | "bottom" | "left";
  onHide?: () => void;
  closable?: boolean;
}

const DrawerController = () => {
  const [stack, setStack] = useState<{ modal: DrawerParams; show: boolean }[]>(
    []
  );

  useDatabus(
    DataBusSubKeys.DRAWER_MANAGER_ADD,
    (data: DrawerParams) => {
      setStack((stack) => [...stack, { modal: data, show: true }]);
    },
    [stack, setStack]
  );

  useGlobalKeyEvent(
    ["Escape"],
    (e) => {
      if (e.key === "Escape") {
        const lastModal = stack[stack.length - 1];
        if (lastModal && !lastModal.modal.ignoreEscapeToClose) {
          setStack(
            stack.map((e, i) =>
              i === stack.length - 1 ? { ...e, show: false } : e
            )
          );
        }
      }
    },
    [stack]
  );

  useDatabus(
    DataBusSubKeys.MODAL_MANAGER_CLOSE_ALL,
    () => {
      setStack([]);
    },
    [stack, setStack]
  );

  useEffect(() => {
    if (stack.length === 0) {
      const el = document.querySelector("#root");
      el.removeAttribute("inert");
    }
  }, [stack]);

  if (stack.length === 0) {
    return null;
  } else {
    return (
      <>
        {stack.map(({ modal, show }, index) => {
          const close = () => {
            setStack(
              stack.map((e, i) => {
                return i === index ? { ...e, show: false } : e;
              })
            );
          };
          const content =
            typeof modal.content === "function"
              ? modal.content(close)
              : modal.content;
          return (
            <Drawer
              size={modal.size}
              placement={modal.placement}
              open={show}
              onOpen={() => {
                const el = document.querySelector("#root");
                el.setAttribute("inert", "");
              }}
              onExited={() => {
                setStack(stack.filter((e, i) => i !== index));
                if (modal.onHide) {
                  modal.onHide();
                }
              }}
              onClose={modal.closable ? close : undefined}
            >
              {modal.title && (
                <Drawer.Header closeButton={modal.closable}>
                  <Drawer.Title>{modal.title}</Drawer.Title>
                </Drawer.Header>
              )}
              {modal.title ? <Drawer.Body>{content}</Drawer.Body> : content}
            </Drawer>
          );
        })}
      </>
    );
  }
};

export default DrawerController;
