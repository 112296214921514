import InfiniteTableFilter from "@/configurable/data/FilterComponent/InfiniteTableFilter";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import VirtualizedTable from "@/configurable/data/VirtualizedTable/VirtualizedTable";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import { ExpandKey } from "@/redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "@/redux/hooks";
import { MatchQuery } from "@/services/DataService";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { FilterOptionWithMatchQueryConverter } from "../../data/table-filter/BFTableFilterModel";
import { ColumnConfig } from "../../data/virtualized-table/BFVirtualizedTable";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import "./AssetListAssign.scss";

interface AssetListAssignProps {
  identifier: string;
  filterOptions?: FilterOptionWithMatchQueryConverter[];

  assetType: string;
  matchQuery?: MatchQuery;

  columns: { [columnId: string]: ColumnConfig };
  params?: any;

  infoText?: string;
  selectedTitle?: string;

  value: BaseAsset[];
  onChange: (value: BaseAsset[]) => void;

  expandKeys?: (ExpandKey | string)[];
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
}
const AssetListAssign = (props: AssetListAssignProps) => {
  const tableData = useTypedSelector(
    (state) => state.application.infiniteTables[props.identifier]
  );
  const tableDataSelection = useTypedSelector(
    (state) => state.application.infiniteTables[`${props.identifier}_SELECTION`]
  );

  const firstListSelection = tableData?.selection || [];
  const secondListSelection = tableDataSelection?.selection || [];
  const value = props.value || [];
  return (
    <div className={classNames(`asset-list-assign`)}>
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <PersistentSplitPane
          identifier={`${props.identifier}__splitpane`}
          split="horizontal"
          defaultSize={"50%"}
          maxSize={-200}
          minSize={150}
          primary="first"
          allowResize
        >
          <div className={`list-selection`}>
            {props.infoText && (
              <div className={`info-text`}>{props.infoText}</div>
            )}
            {props.filterOptions && (
              <div className={`filter-row`}>
                <InfiniteTableFilter
                  identifier={props.identifier}
                  filterOptions={props.filterOptions}
                />
              </div>
            )}
            <div className={`table-container`}>
              <VirtualizedTable
                asPost
                expandKeys={props.expandKeys}
                cleanupOnUnmount
                identifier={props.identifier}
                params={props.params}
                assetType={props.assetType}
                selection="multiple-checkbox"
                hover
                additionalMatchQuery={MQ.and(
                  props.matchQuery,
                  value.length > 0
                    ? MQ.nin(
                        "_id",
                        value.map((e) => e._id)
                      )
                    : undefined
                )}
                columns={{
                  ...props.columns,
                  addAction: {
                    label: "",
                    disableCustomize: true,
                    fixed: "right",
                    sortable: false,
                    resizable: false,
                    fixedWidth: 120,
                    render: (node: BaseAsset) => (
                      <div>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            props.onChange([...value, node]);
                          }}
                        >
                          {i18n.t("Global.Buttons.add")}
                        </BFButton>
                      </div>
                    ),
                  },
                }}
              />
            </div>
            <div className={`list-actions`}>
              <BFButton
                appearance="link"
                disabled={firstListSelection.length === 0}
                onClick={() => {
                  props.onChange([
                    ...value,
                    ...firstListSelection.map((id) =>
                      tableData.data?.find((e) => e._id === id)
                    ),
                  ]);
                }}
              >
                {i18n.t(
                  "AssetListAssign.addSelection",
                  "Ausgewählte hinzufügen"
                )}
              </BFButton>
            </div>
          </div>
          <div className={`assigned-selection`}>
            <div className={`selection-title`}>
              {props.selectedTitle ||
                i18n.t("AssetListAssign.selectedTitle", "Ausgewählt")}
            </div>
            <div className={`selection-table`}>
              <VirtualizedTable
                asPost
                expandKeys={props.expandKeys}
                cleanupOnUnmount
                identifier={`${props.identifier}_SELECTION`}
                params={props.params}
                assetType={props.assetType}
                selection="multiple-checkbox"
                hover
                additionalMatchQuery={MQ.and(
                  MQ.in(
                    "_id",
                    value?.map((e) => e._id)
                  )
                )}
                columns={{
                  ...props.columns,
                  removeAction: {
                    label: "",
                    disableCustomize: true,
                    fixed: "right",
                    sortable: false,
                    resizable: false,
                    fixedWidth: 120,
                    render: (node: BaseAsset) => (
                      <div>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            props.onChange(
                              value.filter((e) => e._id !== node._id)
                            );
                          }}
                        >
                          {i18n.t("Global.Buttons.remove")}
                        </BFButton>
                      </div>
                    ),
                  },
                }}
              />
            </div>
            <div className={`selection-actions`}>
              <BFButton
                appearance="link"
                disabled={secondListSelection.length === 0}
                onClick={() => {
                  props.onChange(
                    value.filter((e) => !secondListSelection.includes(e._id))
                  );
                }}
              >
                {i18n.t(
                  "AssetListAssign.removeSelection",
                  "Ausgewählte entfernen"
                )}
              </BFButton>
            </div>
          </div>
        </PersistentSplitPane>
      </ValidationPopover>
    </div>
  );
};

export default AssetListAssign;
