import i18n from "@/i18n";
import DataBusDefaults from "@/services/DataBusDefaults";

// todo @Anton: refactor after "npm translate" done it's job
// using i18n.t everywhere for "npm translate" to recognize where it needs to translate
export const FloorPlanToastService = {
  // create
  createSuccess() {
    DataBusDefaults.toast({
      type: "success",
      text: i18n.t(
        "cb:FloorPlan.messages.success.floorPlanCreated",
        "Grundriss erfolgreich erstellt"
      ),
    });
  },

  createdWithoutImage() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.success.floorPlanCreatedWithoutImage",
        "Grundriss ohne Bild erstellt"
      ),
    });
  },

  createError() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.errors.failedToCreateFloorPlan",
        "Erstellung des Grundrisses fehlgeschlagen"
      ),
    });
  },

  // update
  updateSuccess() {
    DataBusDefaults.toast({
      type: "success",
      text: i18n.t(
        "cb:FloorPlan.messages.success.floorPlanUpdated",
        "Grundriss erfolgreich aktualisiert"
      ),
    });
  },

  updatedWithoutImage() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.success.floorPlanUpdatedWithoutImage",
        "Grundriss ohne Bild aktualisiert"
      ),
    });
  },

  updateError() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.errors.failedToUpdateFloorPlan",
        "Aktualisierung des Grundrisses fehlgeschlagen"
      ),
    });
  },

  // delete
  deleteSuccess() {
    DataBusDefaults.toast({
      type: "success",
      text: i18n.t(
        "cb:FloorPlan.messages.success.floorPlanDeleted",
        "Grundriss erfolgreich gelöscht"
      ),
    });
  },

  deleteError() {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.errors.failedToDeleteFloorPlan",
        "Löschen des Grundrisses fehlgeschlagen"
      ),
    });
  },
};
