import { CSSProperties, useEffect, useState } from "react";
import { DrawIconState } from "../types";
import { IDefaultIcon } from "@/types/common";
import { fetchFeatureSvg, processSvgSerialization } from "../helpers";

export const useDrawIconWithSvg = (
  drawIcon?: IDefaultIcon,
  drawColor?: CSSProperties["color"]
) => {
  // ! state
  const [loading, setLoading] = useState(false);
  const [drawIconWithSvg, setDrawIconWithSvg] = useState<DrawIconState>({
    svg: null,
  });

  // ! effects
  // fetch icon for draw interaction
  useEffect(() => {
    if (!drawIcon) {
      const updatedDrawState: DrawIconState = { svg: null };
      if (drawColor) updatedDrawState.color = drawColor;

      setDrawIconWithSvg(() => updatedDrawState);
      return;
    }

    const fetchIcon = async () => {
      setLoading(true);
      let iconSvg: DrawIconState["svg"] = null;
      const unSerializedSvg = await fetchFeatureSvg(drawIcon);
      if (unSerializedSvg) {
        iconSvg = processSvgSerialization(unSerializedSvg, drawColor);
      }

      const updatedDrawState: DrawIconState = {
        icon: drawIcon,
        svg: iconSvg,
      };

      if (drawColor) updatedDrawState.color = drawColor;

      setDrawIconWithSvg(() => updatedDrawState);
      setLoading(false);
    };

    fetchIcon();
  }, [drawColor, drawIcon]);

  return { drawIconWithSvg, drawIconWithSvgLoading: loading };
};
