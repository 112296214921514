import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBAgreementContractor.scss";

export type ContractorType = {
  entity: string;
  objectId: string;
};
interface CBAgreementContractorProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: ContractorType;
  rentalAgreement?: RentalAgreement;
}
const CBAgreementContractor = (props: CBAgreementContractorProps) => {
  return (
    <BFPDFMarkerSection
      className={`cb-agreement-contractor`}
      marginBottom={20}
      value={props.marks}
      title={i18n.t(
        "cb:RentalAgreement.Form.Sections.contractor.title",
        "Vertragsgesellschaft"
      )}
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.contractor.viewHint",
        "Informationen zur Vertragsgesellschaft"
      )}
      readonly
      identifier="contractor"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <div className={`__flex-wrap`}>
        <BFValueDisplay
          label={i18n.t(
            "cb:RentalAgreement.Form.Fields.entity",
            "Gesellschaft"
          )}
          value={props.data.entity}
          formatter={(value) => OrgaStruct.getEntity(value)?.displayName}
        />
        <BFValueDisplay
          label={i18n.t("cb:RentalAgreement.Form.Fields.objectId", "Objekt")}
          value={props.data.objectId}
          formatter={(value) => OrgaStruct.getObject(value)?.displayName}
        />
      </div>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementContractor;
