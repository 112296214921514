import i18n from "@/i18n";
import { useEffect, useState } from "react";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import LabeledInput from "../LabeledInput";
import BFCheckbox from "../checkbox/BFCheckbox";
import BFInput from "../input/BFInput";
import "./BFCheckboxList.scss";

export const checkboxSearchString = (search: string, node: string) => {
  return node.toLowerCase().includes(search.toLowerCase());
};
interface BFCheckboxListProps {
  value: string[];
  onChange: (string: string[]) => void;
  data: {
    value: string;
    label: string | React.ReactNode;
  }[];
  disabled?: boolean;
  label: string;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  maxHeight?: number;
  minHeight?: number;
  easyActions?: boolean;
  searchFC?: (search: string, node: string | React.ReactNode) => boolean;
}
const BFCheckboxList = (props: BFCheckboxListProps) => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(props.data);

  useEffect(() => {
    if (props.searchFC) {
      setFilteredData(
        props.data.filter((d) => props.searchFC(search, d.label))
      );
    } else {
      setFilteredData(props.data);
    }
  }, [search, props.data]);

  return (
    <LabeledInput
      className={`bf-checkbox-list__labeled-input`}
      label={props.label}
      labelPosition={"top"}
      error={!!props.validation?.message}
      suffix={
        <>
          {props.searchFC && (
            <BFInput
              disabled={props.disabled}
              placeholder={i18n.t("Global.Labels.search", "Suchen")}
              prefix={<BfIcon {...DefaultIcons.SEARCH} size="xxs" />}
              value={search}
              onChange={(value: string) => setSearch(value)}
            />
          )}
          {props.easyActions && (
            <div className={`bf-checkbox-list__additional-actions`}>
              <BFButton
                disabled={props.disabled}
                size="xs"
                appearance="link"
                onClick={() => {
                  props.onChange(filteredData.map((d) => d.value));
                }}
              >
                {i18n.t("Global.Buttons.selectAll", "Alle auswählen")}
              </BFButton>
              <BFButton
                disabled={props.disabled}
                size="xs"
                appearance="link"
                onClick={() => {
                  props.onChange([]);
                }}
              >
                {i18n.t("Global.Buttons.deselectAll", "Alle abwählen")}
              </BFButton>
            </div>
          )}
        </>
      }
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <div
          className={`bf-checkbox-list`}
          style={{ maxHeight: props.maxHeight, minHeight: props.minHeight }}
        >
          {filteredData.length === 0 && (
            <div className={`__empty`}>
              {i18n.t("Global.Labels.noEntries", "Keine Einträge vorhanden")}
            </div>
          )}
          {filteredData.map((entry) => (
            <div className={`entry`}>
              <BFCheckbox
                disabled={props.disabled}
                checked={props.value?.includes(entry.value)}
                onChange={(value, checked) => {
                  if (checked) {
                    props.onChange([...props.value, entry.value]);
                  } else {
                    props.onChange(
                      props.value.filter((v) => v !== entry.value)
                    );
                  }
                }}
              >
                {entry.label}
              </BFCheckbox>
            </div>
          ))}
        </div>
      </ValidationPopover>
    </LabeledInput>
  );
};

export default BFCheckboxList;
