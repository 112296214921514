import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import { AccountingAccount } from "@/apps/tatar/accounting/interfaces/account.interface";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { useHttpCache } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import LanguageService from "@/services/LanguageService";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { Loader } from "rsuite";
import { RentalAgreement } from "../../../TenantsInterfaces";
import "./CBRentalAgreementOpenAmounts.scss";
import CBRentalAgreementSaldoGraph from "./CBRentalAgreementSaldoGraph";

interface CBRentalAgreementOpenAmountsProps {
  accountingData: AccountingData;
  rentalAgreement: RentalAgreement;
  debitAccount: AccountingAccount;
  account: AccountingAccount;
  object: OAObject;
  chartId: string;
}
const CBRentalAgreementOpenAmounts = (
  props: CBRentalAgreementOpenAmountsProps
) => {
  const data = useHttpCache<
    {
      accountId: string;
      value: number;
    }[]
  >(
    `cb-rental-agreement-open-debit-positions-cost-id-${props.account._id}`,
    `/api/accounting/${props.rentalAgreement.data.entity}/${props.account._id}/getOpenDebitPositionsCostId`,
    "get",
    null
  );

  const debitAccount = props.debitAccount;

  if (!debitAccount) {
    return (
      <div
        className={`cb-rental-agreement-accounting __card no-debit-positions`}
      >
        {i18n.t(
          "cb:RentalAgreement.Accounting.NoDebitPositions",
          "Es wurde bisher kein Mieterkonto angelegt."
        )}
      </div>
    );
  }

  const objectKindId = props.object.data.objectKindId;
  const objectKind = ObjectKindStruct.getKind(objectKindId);

  const balance = (debitAccount.data.sumBalance || 0) * -1;

  if (data.state === "loading") {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  if (data.state === "error") {
    return (
      <div>
        <p>error</p>
      </div>
    );
  }

  return (
    <div className={classNames(`cb-rental-agreement-open-accounts`)}>
      <DebugDataComponent
        data={{
          debitAccount,
          accountingData: props.accountingData,
          objectKind,
          object: props.object,
          debitData: data.data,
        }}
      />
      <div className={`head-line`}>
        <div className={`label`}>
          {i18n.t(
            "cb:RentalAgreement.Labels.Accounting.CurrentDebit",
            "Aktueller Kontostand"
          )}
        </div>
        <div
          className={classNames(`value`, {
            negative: balance < 0,
            postive: balance > 0,
          })}
        >
          {StringUtils.formatCurrency(
            balance,
            undefined,
            undefined,
            props.accountingData.accounting.data.currency
          )}
        </div>
      </div>
      <div className={`info-container`}>
        <PersistentSplitPane
          className={`split-pane-open-amounts`}
          identifier="rental-agreement-accounting-split-pane-open-amounts"
          split="vertical"
          defaultSize={"50%"}
          // maxSize={}
          minSize={200}
          maxSize={-200}
          primary="first"
          allowResize
          onSizeChange={() => {
            DataBusDefaults.chartResized(props.chartId);
          }}
        >
          <div className={`graph`}>
            <AssetLoader
              assetType={AssetTypes.Accounting.Account}
              query={MQ.and(
                MQ.eq(
                  "data.entity",
                  props.accountingData.accounting.data.entity
                ),
                MQ.eq("data.linkedAsset.assetId", props.rentalAgreement._id)
              )}
              render={(account: AccountingAccount) => (
                <CBRentalAgreementSaldoGraph
                  chartId={props.chartId}
                  accountingData={props.accountingData}
                  account={account}
                />
              )}
            />
          </div>
          <div className={`sub-positions`}>
            <table>
              {data.data.map((entry) => {
                return (
                  <AssetLoader
                    assetType={AssetTypes.Accounting.Account}
                    id={entry.accountId}
                    renderLoading={() => (
                      <tr>
                        <td colSpan={2}>
                          <Loader />
                        </td>
                      </tr>
                    )}
                    render={(account: AccountingAccount) => {
                      const balance = entry.value;
                      return (
                        <>
                          <tr className={`sub-debit`}>
                            <td className={`label`}>
                              <span>
                                {LanguageService.translateLabel(
                                  account.data.displayName
                                )}
                              </span>
                            </td>
                            <td
                              className={classNames(`value`, {
                                negative: balance < 0,
                                postive: balance > 0,
                              })}
                            >
                              {StringUtils.formatCurrency(
                                balance,
                                true,
                                undefined,
                                props.accountingData.accounting.data.currency
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    }}
                  />
                );
              })}
            </table>
          </div>
        </PersistentSplitPane>
      </div>
    </div>
  );
};

export default CBRentalAgreementOpenAmounts;
