import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { useDatabus, useTypedSelector } from "@/redux/hooks";
import { DataBusSubKeys } from "@/utils/Constants";
import { hasValue } from "@/utils/Helpers";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Collapse, { DIMENSION } from "rsuite/esm/Animation/Collapse";
import "./ApplicationSidebar.scss";

export type ApplicationSidebarParams = {
  title?: string | React.ReactNode;
  closable?: boolean;
  width: number | string;
  render: (close: () => void) => React.ReactNode;
  stayOnAppChange?: boolean;
};
interface ApplicationSidebarProps {}
const ApplicationSidebar = (props: ApplicationSidebarProps) => {
  const application = useTypedSelector(
    (state) => state.uiConfig.activeApplication
  );
  const [current, setCurrent] = useState<ApplicationSidebarParams>(null);
  const [width, setWidth] = useState<number | string>(300);

  useEffect(() => {
    if (current && !current.stayOnAppChange) {
      setCurrent(null);
    }
  }, [application]);
  useDatabus(DataBusSubKeys.SIDEBAR_OPEN, (param: ApplicationSidebarParams) => {
    setWidth(param.width);
    setCurrent(param);
  });
  useDatabus(
    DataBusSubKeys.SIDEBAR_CLOSE,
    () => {
      setCurrent(null);
    },
    [current]
  );
  return (
    <Collapse in={hasValue(current)} dimension={DIMENSION.WIDTH}>
      <div>
        <div
          className={classNames(`application-sidebar`, {
            "with-header": current?.title || current?.closable,
          })}
          style={{ width }}
        >
          {current && (
            <>
              {(current.closable || current.title) && (
                <div className={classNames(`application-sidebar-header`)}>
                  <div className={`application-sidebar-title`}>
                    {current.title || ""}
                  </div>
                  {current.closable && (
                    <BFButton
                      appearance="clear-on-white"
                      size={"xxs"}
                      className={`application-sidebar-close`}
                      onClick={() => setCurrent(null)}
                    >
                      <BfIcon {...DefaultIcons.CLOSE} size="xxs" />
                    </BFButton>
                  )}
                </div>
              )}
              <div className={`application-sidebar-content`}>
                {current.render(() => setCurrent(null))}
              </div>
            </>
          )}
        </div>
      </div>
    </Collapse>
  );
};

export default ApplicationSidebar;
