import { IDefaultIcon } from "@/types/common";

export const DefaultIcons: { [key: string]: IDefaultIcon } = {
  EDIT: {
    type: "light",
    data: "pencil-1",
  },
  SEARCH: {
    type: "light",
    data: "search",
  },
  ADD: {
    type: "light",
    data: "add",
  },
  BACK: {
    type: "light",
    data: "arrow-left",
  },
  FORWARD: {
    type: "light",
    data: "arrow-right",
  },
  CLOSE: {
    type: "light",
    data: "close",
  },
  REMOVE: {
    type: "light",
    data: "close",
  },
  TRASH: {
    type: "light",
    data: "bin-2",
  },
  RETRY: {
    type: "light",
    data: "synchronize-arrow-1",
  },
  REFRESH: {
    type: "light",
    data: "synchronize-arrow-1",
  },
  CHEVRON: {
    type: "light",
    data: "arrow-down-1",
  },
  CHAT: {
    type: "light",
    data: "conversation-chat-2",
  },
  OPEN_LINK: {
    type: "light",
    data: "expand-6",
  },
  MORE: {
    type: "light",
    data: "navigation-menu-horizontal",
  },
  ARROW_LEFT: {
    type: "light",
    data: "arrow-left",
  },
  ARROW_RIGHT: {
    type: "light",
    data: "arrow-right",
  },
};
// https://www.streamlinehq.com/icons/streamline-light/interface-essential/synchronize/synchronize-arrow-1
