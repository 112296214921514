import classNames from "classnames";
import Color from "color";
import { customAlphabet } from "nanoid";
import { useState } from "react";
import BFStatus from "../../../abstract-ui/data/status/BFStatus";
import BFDropdown from "../../../abstract-ui/general/Dropdown/BFDropdown";
import { DropdownItem } from "../../../abstract-ui/general/Dropdown/BFDropdownContent";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import { IDefaultIcon } from "@/types/common";
import "./EZStatusSelect.scss";

interface EZStatusOption {
  label: string;
  value: string;
  color?: string;
  icon?: IDefaultIcon;
  subStatus?: {
    label: string;
    value: string;
  }[];
}
interface EZStatusSelectProps {
  className?: string;
  value: {
    status: string;
    subStatus?: string;
  };
  onChange?: (value: { status: string; subStatus?: string }) => void;
  onBlur?: (ev, value: string) => void;

  size?: "xs" | "sm" | "md" | "lg";
  error?: string;
  disabled?: boolean;
  data: EZStatusOption[];
  //   dataType?: "default" | "tag";
  prefixIcon?: BfIconProps;
  readonly?: boolean;
}
const EZStatusSelect = (props: EZStatusSelectProps) => {
  const [nanoId, setNanoId] = useState(
    customAlphabet("abcdefghijklmnopqrstuvwxyz", 10)
  );

  const selectedStatus = props.data.find(
    (option) => option.value === props.value.status
  );
  const selectedSubStatus = selectedStatus?.subStatus?.find(
    (e) => e.value === props.value.subStatus
  );

  let color;
  if (selectedStatus && selectedStatus.color) {
    color = Color(selectedStatus.color);
  }

  let label = selectedStatus?.label || "";
  if (selectedSubStatus) {
    label = `${selectedStatus.label} - ${selectedSubStatus.label}`;
  }
  const renderReadonly = () => {
    if (selectedStatus) {
      return (
        <BFStatus
          color={selectedStatus.color}
          label={label}
          icon={(selectedStatus as any)?.icon}
          showIcon={!!(selectedStatus as any)?.icon}
        />
      );
    } else {
      return null;
    }
  };

  const renderSelect = () => {
    return (
      <>
        {color && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .ez-status-select#${nanoId} .rs-dropdown-toggle.rs-btn {
              background: ${selectedStatus?.color} !important;
              color: ${
                color.isDark() ? "#fff" : color.darken(0.7).hex()
              } !important;
              
            }
            .ez-status-select#${nanoId} .rs-dropdown-toggle.rs-btn svg.rs-dropdown-toggle-caret {
              color: ${
                color.isDark() ? "#fff" : color.darken(0.7).hex()
              } !important;
            }
          `,
            }}
          />
        )}

        <BFDropdown
          size={props.size}
          onBlur={() => props.onBlur?.(null, null)}
          label={
            <>
              {selectedStatus?.icon && (
                <BfIcon size="xs" {...selectedStatus.icon} />
              )}
              {label}
            </>
          }
          items={props.data.map((status) => {
            if (status.subStatus?.length > 0) {
              return {
                type: "menu",
                active: status.value === props.value.status,
                text: <BFStatus label={status.label} color={status.color} />,
                items: status.subStatus.map((subStatus) => ({
                  type: "button",
                  text: subStatus.label,
                  active:
                    status.value === props.value.status &&
                    subStatus.value === props.value.subStatus,
                  onSelect: () =>
                    props.onChange({
                      status: status.value,
                      subStatus: subStatus.value,
                    }),
                })),
              } as DropdownItem;
            } else {
              return {
                type: "button",
                active: status.value === props.value.status,
                text: <BFStatus label={status.label} color={status.color} />,
                onSelect: () =>
                  props.onChange({ status: status.value, subStatus: null }),
              } as DropdownItem;
            }
          })}
        />
      </>
    );
  };

  return (
    <div
      className={classNames(`ez-status-select`, props.className, {
        error: props.error,
        readonly: props.readonly,
      })}
      id={nanoId}
    >
      {props.readonly && renderReadonly()}
      {!props.readonly && renderSelect()}
    </div>
  );
};

export default EZStatusSelect;
