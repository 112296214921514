import { FC, useEffect, useMemo, useState } from "react";
import i18n from "@/i18n";
import {
  FloorPlanToLocations,
  OpenLayerState,
  ViewPositionModalContentProps,
} from "./types";
import {
  FloorPlanPositionsBase,
  OAFloorPlanBase,
} from "@/apps/tatar/objectsApp/types/object.interface";
import { AssetTypes } from "@/model/AssetTypes";
import { FETCH_FLOOR_PLAN_LIST_IDENTIFIER } from "./config";
import Log from "@/debug/Log";
import MQ from "@/utils/MatchQueryUtils";
import { fetchImageUrl } from "./helpers";
import DataBusDefaults from "@/services/DataBusDefaults";
import FloorPlanPositionsService from "@/services/FloorPlanPositionsService";
import LoadOverlay from "@/components/LoadOverlay";
import {
  OpenLayerLocationItem,
  OpenLayerWrapper,
} from "@/components/OpenLayerWrapper";
import LoadPage from "@/components/LoadPage/LoadPage";
import { ViewPositionFloorPlanList } from "./components";
import { EmptyContentComponent } from "@/components/EmptyContentComponent";
import { ErrorContentComponent } from "src/components/ErrorContentComponent";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import styles from "./ViewPositionModalContent.module.scss";
import axios from "axios";

const ViewPositionModalContent: FC<ViewPositionModalContentProps> = ({
  assetId,
  assetType,
  buildingId,
  drawIcon,
  drawColor,
}) => {
  // ! state
  const [positions, setPositions] = useState<FloorPlanPositionsBase[] | null>(
    null
  );
  const [isError, setIsError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [selectedFloorPlanId, setSelectedFloorPlanId] = useState<string>("");

  const [openLayerState, setOpenLayerState] = useState<OpenLayerState | null>(
    null
  );

  // ! memo
  const floorPlanIdsToFetch = useMemo<string[]>(() => {
    if (!positions?.length) return [];

    return positions.reduce((acc, { data: { floorPlanId } }) => {
      if (floorPlanId) acc.push(floorPlanId);
      return acc;
    }, []);
  }, [positions]);

  const floorPlanIdToLocations = useMemo<FloorPlanToLocations>(() => {
    if (!positions?.length) return {};

    return positions.reduce<FloorPlanToLocations>(
      (acc, { data: { floorPlanId, locations } }) => {
        if (!floorPlanId || !locations) return acc;

        // add icon and color to location item
        acc[floorPlanId] = locations.map<OpenLayerLocationItem>(
          ({ coordinates }) => ({
            coordinates,
            icon: drawIcon,
            color: drawColor,
          })
        );

        return acc;
      },
      {}
    );
  }, [positions, drawColor, drawIcon]);

  // ! helpers
  const resetState = () => {
    setOpenLayerState(null);
    setSelectedFloorPlanId("");
  };

  // ! handlers
  const onFloorPlanSelect = async (floorPlanBase: OAFloorPlanBase) => {
    setLocalLoading(true);
    const imageUrl = await fetchImageUrl(floorPlanBase);
    setLocalLoading(false);

    if (!imageUrl) return resetState();

    const predefinedLocations = floorPlanIdToLocations[floorPlanBase._id];
    if (!predefinedLocations) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "cb:FloorPlanPosition.messages.errors.failedToFetchFloorPlanPosition",
          "Fehler beim Abrufen der Grundrissposition"
        ),
      });
      return resetState();
    }

    setOpenLayerState({ imageUrl, predefinedLocations });
    setSelectedFloorPlanId(floorPlanBase._id);
  };

  // ! effects
  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchPositions = async () => {
      try {
        const positions =
          await FloorPlanPositionsService.fetchMultiplePositions({
            assetId,
            assetType,
            cancelToken: source.token,
          });

        if (positions?.length) return setPositions(positions);
        setIsEmpty(true);
      } catch (error) {
        if (axios.isCancel(error)) return;

        Log.error("Error fetching floor plan positions", error);
        setIsError(true);
      }
    };

    fetchPositions();

    return () => {
      source.cancel();
    };
  }, [assetId, assetType]);

  if (isError) {
    return (
      <ErrorContentComponent
        description={i18n.t(
          "cb:FloorPlanPosition.messages.errors.failedToFetchFloorPlanPositions",
          "Fehler beim Abrufen der Grundrisspositionen"
        )}
      />
    );
  }

  if (isEmpty) {
    return (
      <EmptyContentComponent
        description={i18n.t(
          "cb:FloorPlanPosition.messages.errors.floorPlanPositionsNotFound",
          "Positionen nicht gefunden"
        )}
      />
    );
  }

  if (!positions) {
    return <LoadPage size="md" />;
  }

  // ! render
  return (
    <div className={styles.content_wrapper}>
      <LoadOverlay open={localLoading} />

      <div className={styles.list_component_wrapper}>
        <ListComponent
          hiddenSortFirst
          cleanupOnUnmount
          dataRenderType="render-as-list"
          assetType={AssetTypes.Portfolio.FloorPlan}
          identifier={FETCH_FLOOR_PLAN_LIST_IDENTIFIER}
          additionalMatchQuery={MQ.and(
            MQ.in("_id", floorPlanIdsToFetch),
            MQ.eq("data.objectId", buildingId),
            MQ.ne("data.status", "archived")
          )}
          render={(floorPlanBaseList: OAFloorPlanBase[]) => (
            <ViewPositionFloorPlanList
              selectedFloorPlanId={selectedFloorPlanId}
              floorPlanBaseList={floorPlanBaseList}
              onFloorPlanSelect={onFloorPlanSelect}
            />
          )}
        />
      </div>

      <div className={styles.open_layer_container}>
        {!openLayerState && (
          <span className={styles.pick_floor_plan_text}>
            {i18n.t(
              "cb:FloorPlan.messages.actions.pickTheFloorPlan",
              "Wählen Sie den Grundriss aus der Liste"
            )}
          </span>
        )}

        {openLayerState && (
          <OpenLayerWrapper
            key={openLayerState.imageUrl}
            imageUrl={openLayerState.imageUrl}
            predefinedLocations={openLayerState.predefinedLocations}
          />
        )}
      </div>
    </div>
  );
};

export default ViewPositionModalContent;
