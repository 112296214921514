import i18n from "@/i18n";
import { CBPortfolioTechnicalUnit } from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import { TechnicalUnitCategoryGroup } from "@/apps/tatar/cashBudget/views/config/technical-unit-config/CBTechnicalUnitConfigInterfaces";
import {
  PortfolioSupplyUnit,
  SupplyUnitCategoryGroup,
} from "@/apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import { AssetTypes } from "@/model/AssetTypes";
import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  ViewPositionModalContent,
  ViewPositionModalContentProps,
} from "../ViewPositionModalContent";
import { AddPositionsForm, AddPositionsFormProps } from "../AddPositionsForm";

class FloorPlanPositionsModalManagerClass {
  // ! technical unit
  technicalUnitAddPosition(
    technicalUnit: CBPortfolioTechnicalUnit,
    category?: TechnicalUnitCategoryGroup
  ) {
    this.openAddModal({
      assetId: technicalUnit._id,
      buildingId: technicalUnit.data.objectId,
      assetType: AssetTypes.Portfolio.TechnicalUnit,
      drawColor: category?.data?.color,
      drawIcon: category?.data?.icon,
    });
  }

  technicalUnitViewPosition(
    technicalUnit: CBPortfolioTechnicalUnit,
    category?: TechnicalUnitCategoryGroup
  ) {
    this.openViewModal({
      assetId: technicalUnit._id,
      buildingId: technicalUnit.data.objectId,
      assetType: AssetTypes.Portfolio.TechnicalUnit,
      drawIcon: category?.data?.icon,
      drawColor: category?.data?.color,
    });
  }

  // ! supply unit
  supplyUnitAddPosition(
    supplyUnit: PortfolioSupplyUnit,
    category?: SupplyUnitCategoryGroup
  ) {
    this.openAddModal({
      assetId: supplyUnit._id,
      buildingId: supplyUnit.data.objectId,
      assetType: AssetTypes.Portfolio.SupplyUnit,
      drawColor: category?.data?.color,
      drawIcon: category?.data?.icon,
      maxUnits: 1,
      singleFloorPlan: true,
    });
  }

  supplyUnitViewPosition(
    supplyUnit: PortfolioSupplyUnit,
    category?: SupplyUnitCategoryGroup
  ) {
    this.openViewModal({
      assetId: supplyUnit._id,
      buildingId: supplyUnit.data.objectId,
      assetType: AssetTypes.Portfolio.SupplyUnit,
      drawIcon: category?.data?.icon,
      drawColor: category?.data?.color,
    });
  }

  // ! modal openers
  private openAddModal(formProps: Omit<AddPositionsFormProps, "closeModal">) {
    ModalManager.show({
      size: "md",
      closable: true,
      noPadding: true,
      backdrop: "static",
      content: (_states, _setStates, closeModal) => (
        <AddPositionsForm {...formProps} closeModal={closeModal!} />
      ),
    });
  }

  private openViewModal(contentProps: ViewPositionModalContentProps) {
    ModalManager.show({
      size: "xl",
      closable: true,
      backdrop: "static",
      title: i18n.t(
        "cb:Portfolio.Objekt.technicalUnit.positions.positions",
        "Positionen"
      ),
      buttons: [
        {
          text: i18n.t("Global.Buttons.close"),
          closeOnClick: true,
          appearance: "default",
        },
      ],
      content: <ViewPositionModalContent {...contentProps} />,
    });
  }
}

export const FloorPlanPositionsModalManager =
  new FloorPlanPositionsModalManagerClass();
