import { FC } from "react";
import i18n from "@/i18n";
import { EmptyContentComponentProps } from "./types";
import DocumentScanAnimation from "@/components/LottieAnimations/DocumentScan/DocumentScanAnimation";
import styles from "./EmptyContentComponent.module.scss";

const EmptyContentComponent: FC<EmptyContentComponentProps> = ({
  title,
  description = i18n.t("Global.Error.NoData"),
}) => {
  return (
    <div className={styles.empty_content_wrapper}>
      <div className={styles.empty_content_text}>
        {title && <div className={styles.empty_content_title}>{title}</div>}
        <div className={styles.empty_content_description}>{description}</div>
      </div>

      <div className={styles.empty_animation_wrapper}>
        <DocumentScanAnimation />
      </div>
    </div>
  );
};

export default EmptyContentComponent;
