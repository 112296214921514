import {
  RequestMethodConfig,
  CreateFloorPlanRequestData,
  DeleteFloorPlanRequestData,
  UpdateFloorPlanRequestData,
  UploadFloorPlanImageRequestData,
} from "./types";
import {
  OAFloorPlanBase,
  FloorPlanLocationItemWithIcon,
} from "@/apps/tatar/objectsApp/types/object.interface";
import { DocumentStoreFileMetaData } from "@/modules/document-store/DSInterfaces";
import { AssetTypes } from "@/model/AssetTypes";
import { HTTP, HttpInterface } from "@/utils/Http";
import DataBusDefaults from "@/services/DataBusDefaults";
import SubmitService, { SubmitMessage } from "@/services/SubmitService";
import CDNService, { CDNGenerateDownloadLink } from "@/services/CDNService";

class FloorPlanServiceClass {
  async createFloorPlan({
    requestConfig,
    identifiersToUpdate,
  }: RequestMethodConfig<CreateFloorPlanRequestData>) {
    return await this.makeRequest<OAFloorPlanBase>(
      { data: requestConfig },
      identifiersToUpdate
    );
  }

  async updateFloorPlan({
    requestConfig,
    identifiersToUpdate,
  }: RequestMethodConfig<UpdateFloorPlanRequestData>) {
    const { _id, data } = requestConfig;

    return await this.makeRequest({ _id, data }, identifiersToUpdate);
  }

  async deleteFloorPlan({
    requestConfig,
    identifiersToUpdate,
  }: RequestMethodConfig<DeleteFloorPlanRequestData>) {
    return await this.makeRequest<unknown>(
      { _id: requestConfig._id, data: { status: "archived" } },
      identifiersToUpdate
    );
  }

  async uploadImage({
    requestConfig,
    identifiersToUpdate,
  }: RequestMethodConfig<UploadFloorPlanImageRequestData>) {
    const { image, floorPlanBaseId } = requestConfig;

    const fileMetaData: DocumentStoreFileMetaData = {
      file: image,
      name: image.name,
    };

    await CDNService.uploadAttachment(
      floorPlanBaseId,
      fileMetaData,
      AssetTypes.Portfolio.FloorPlan,
      "data.images"
    );

    if (identifiersToUpdate) this.updateIdentifiers(identifiersToUpdate);
  }

  async fetchImageURL(
    floorPlanBase: OAFloorPlanBase,
    cancelObj?: CDNGenerateDownloadLink["cancelObj"]
  ): Promise<string> {
    // find CDN for image
    const firstImageCDN = floorPlanBase.cdn?.find((cdnItem) => {
      return cdnItem._id === floorPlanBase.data.images[0].linkToCdn;
    });

    if (!firstImageCDN) throw new Error("Can not find image CDN");

    const url = await CDNService.fetchCDNLink({
      assetType: AssetTypes.Portfolio.FloorPlan,
      assetId: floorPlanBase._id,
      fileKey: firstImageCDN.key,
      assetField: "data.images",
      hasFolderReadPermissions: true,
      cancelObj,
    });

    if (!url) throw new Error("Failed to fetch image url for Floor Plan");

    return url;
  }

  async fetchLocationsInfo(
    floorPlanBaseId: string,
    cancelToken?: HttpInterface["cancelToken"]
  ): Promise<FloorPlanLocationItemWithIcon[]> {
    return HTTP.get({
      url: `floor-plan/floor-locations-info/${floorPlanBaseId}`,
      cancelToken,
    });
  }

  private async makeRequest<ReturnType>(
    data: SubmitMessage["data"],
    identifiersToUpdate?: string[]
  ) {
    const result = await SubmitService.submitDataAsync(
      {
        type: "asset",
        assetType: AssetTypes.Portfolio.FloorPlan,
        data,
        ignorePropChecks: true,
        ignoreSubmitValidation: true,
      },
      true
    );

    if (identifiersToUpdate) this.updateIdentifiers(identifiersToUpdate);

    return result as ReturnType;
  }

  private updateIdentifiers(identifiers: string[]) {
    DataBusDefaults.reload({ identifiers });
  }
}

const FloorPlanService = new FloorPlanServiceClass();

export default FloorPlanService;
