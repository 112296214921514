import { FC, memo } from "react";
import { ImageFieldDropzonePreviewProps } from "./types";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import FilePreviewComponent from "@/components/FilePreviewComponent/FilePreviewComponent";
import styles from "./ImageFieldDropzonePreview.module.scss";

const ImageFieldDropzonePreview: FC<ImageFieldDropzonePreviewProps> = ({
  file,
  onRemove,
}) => {
  return (
    <>
      <FilePreviewComponent type="file" file={file} width={250} height={250} />

      <BFButton
        circle
        icon={{
          type: "light",
          data: "delete-2",
          size: "sm",
        }}
        size="sm"
        onClick={onRemove}
        className={styles.delete_button}
      />
    </>
  );
};

export default memo(ImageFieldDropzonePreview);
