import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { TableSort } from "@/model/common/CommonInterfaces";
import BaseAsset from "@/model/general-assets/BaseAsset";
import InfiniteTable from "@/redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "@/redux/hooks";
import { MatchQuery } from "@/services/DataService";
import { hasValue } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Collapse from "rsuite/esm/Animation/Collapse";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import FlexGrowContainer from "../../layout/flex-grow-container/FlexGrowContainer";
import BFInput from "../input/BFInput";
import "./BFAssetChooser.scss";

export type BFAssetChooserOption = {
  label: string;
  group?: string;
  subLabel?: string;
  value: string;
};

interface BFAssetChooserProps {
  // options: BFChooserOption<string>[];
  assetType: string;
  matchQuery?: MatchQuery;
  getOption: (node: BaseAsset) => BFAssetChooserOption;
  groups?: {
    matchQuery: MatchQuery;
    label: string;
    value: string;
  }[];
  sort?: TableSort[];

  value?: string;
  onChange: (value: string, asset: BaseAsset) => void;
  description?: string;
  focusOnMount?: boolean;
  height?: string | number;
  searchPlaceholder?: string;
  showSelected?: boolean;
  allowDeselect?: boolean;
  dense?: boolean;
}

const BFAssetChooser = (props: BFAssetChooserProps) => {
  const dispatch = useDispatch();
  const [activeGroup, setActiveGroup] = useState<string>(null);
  const [identifier] = useState(_.uniqueId("bf-asset-chooser-"));
  // const [search, setSearch] = useState("");

  const search =
    useTypedSelector(
      (state) => state.application.infiniteTables[identifier]?.searchTerm
    ) || "";

  const groups = props.groups || [];
  const group = groups.find((group) => group.value === activeGroup);
  return (
    <div
      className={classNames(`bf-asset-chooser`, {
        dense: props.dense,
      })}
      style={{ height: props.height }}
    >
      {props.description && (
        <div className={`description`}>{props.description}</div>
      )}
      <div className={`search`}>
        <BFInput
          focusOnMount={props.focusOnMount}
          value={search}
          placeholder={props.searchPlaceholder}
          onChange={(value: string) =>
            dispatch(InfiniteTable.setSearch(identifier, value))
          }
          prefix={<BfIcon size="xs" {...DefaultIcons.SEARCH} />}
        />
      </div>
      <div className={`search-content`}>
        {props.showSelected && (
          <Collapse in={hasValue(props.value)}>
            <div>
              <div className={`selection`}>
                <div className={`selection-content`}>
                  <div className={`selection-label`}>
                    {i18n.t("BFAssetChooser.selected", "Ausgewählt")}
                  </div>
                  <div className={`selection-value`}>
                    {props.value && (
                      <AssetLoader
                        inline
                        assetType={props.assetType}
                        id={props.value}
                        render={(asset: BaseAsset) => {
                          const option = props.getOption(asset);

                          return (
                            <div className={`selection-option`}>
                              {option.label}
                            </div>
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
                <BFButton
                  size="xs"
                  appearance="outline"
                  onClick={() => {
                    props.onChange(undefined, undefined);
                  }}
                >
                  <BfIcon size="xxs" {...DefaultIcons.CLOSE} />
                </BFButton>
              </div>
            </div>
          </Collapse>
        )}
        {search === "" && groups.length > 1 && (
          <FlexGrowContainer>
            <div className={`groups-container`}>
              <div
                className={classNames(`group-selection`, {
                  active: activeGroup === null,
                })}
              >
                {groups.map((group) => (
                  <BFButton
                    className={`group-button list-button`}
                    key={group.value}
                    onClick={() => setActiveGroup(group.value)}
                  >
                    <div className={`group-button__label`}>{group.label}</div>
                    <BfIcon size="xxs" type="light" data={"arrow-right-1"} />
                  </BFButton>
                ))}
              </div>
              <div
                className={classNames(`group-list`, {
                  active: activeGroup !== null,
                })}
              >
                <div className={`group-header`}>
                  <BFButton
                    appearance="clear-on-white"
                    onClick={() => setActiveGroup(null)}
                  >
                    <BfIcon size="xs" {...DefaultIcons.BACK} />
                  </BFButton>
                  <div className={`group-title`}>{group?.label}</div>
                </div>
                <FlexGrowContainer noOverflow>
                  <BFAssetChooserList
                    sort={props.sort}
                    search={search}
                    getOption={props.getOption}
                    identifier={identifier}
                    assetType={props.assetType}
                    matchQuery={MQ.and(props.matchQuery, group?.matchQuery)}
                    onChange={props.onChange}
                    value={props.value}
                  />
                </FlexGrowContainer>
              </div>
            </div>
          </FlexGrowContainer>
        )}
        {(search !== "" || groups.length <= 1) && (
          <FlexGrowContainer>
            <BFAssetChooserList
              sort={props.sort}
              search={search}
              getOption={props.getOption}
              identifier={identifier}
              assetType={props.assetType}
              matchQuery={MQ.and(
                props.matchQuery,
                groups.length === 1 ? groups[0]?.matchQuery : undefined
              )}
              onChange={props.onChange}
              value={props.value}
            />
          </FlexGrowContainer>
        )}
      </div>
    </div>
  );
};

const BFAssetChooserList = (props: {
  identifier: string;
  assetType: string;
  matchQuery?: MatchQuery;
  value?: string;
  sort?: TableSort[];
  search: string;
  getOption: (node: BaseAsset) => BFAssetChooserOption;
  onChange: (value: string, asset: BaseAsset) => void;
}) => {
  return (
    <div className={`bf-asset-chooser-list`}>
      <ListComponent
        asPost
        identifier={props.identifier}
        assetType={props.assetType}
        hiddenSort={props.sort}
        additionalMatchQuery={props.matchQuery}
        params={{
          selected: props.value,
        }}
        render={(node: BaseAsset, index, params) => {
          const option = props.getOption(node);
          return (
            <BFChooserEntry
              asset={node}
              entry={option}
              onSelect={props.onChange}
              selected={params.selected}
            />
          );
        }}
      />
    </div>
  );
};

const BFChooserEntry = (props: {
  entry: BFAssetChooserOption;
  asset: BaseAsset;
  selected?: string;
  onSelect: (value: string, asset: BaseAsset) => void;
}) => {
  return (
    <BFButton
      className={classNames(`bf-asset-chooser-entry list-button`, {
        selected: props.selected === props.entry.value,
      })}
      onClick={() => props.onSelect(props.entry.value, props.asset)}
    >
      <div className={`bf-asset-chooser-entry-label`}>{props.entry.label}</div>
      {props.entry.subLabel && (
        <div className={`bf-asset-chooser-entry-sub-label`}>
          {props.entry.subLabel}
        </div>
      )}
    </BFButton>
  );
};

export default BFAssetChooser;
