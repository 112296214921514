import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import AccountSelection from "@/apps/tatar/accounting/components/account-selection/AccountSelection";
import {
  AccountingAccount,
  AccountingBookingFormValue,
  AccountingBookingType,
  AccountType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFQuickSelect from "@/modules/abstract-ui/forms/input/BFQuickSelect";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import { hasValue } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { Loader } from "rsuite";
import AccountingFormAdditionalData from "../AccountingFormAdditionalData";
import AccountingRentalInfo from "../info/AccountingRentalInfo";
import "./AccountingShouldPositionForm.scss";

interface AccountingShouldPositionFormProps {
  onClose: () => void;
  onSuccess?: (asset: BaseAsset) => void;

  forAccount?: AccountingAccount;
  accounting?: AccountingData;
  accountTypes: AccountType[];
  taxable?: boolean;
  transformSubmitValues?: (
    values: AccountingShouldPositionFormValue
  ) => AccountingBookingFormValue;
}

const getInitialValue = (props: AccountingShouldPositionFormProps) => {
  const { forAccount } = props;
  let account: AccountingAccount = props.forAccount;
  return {
    entity: props.accounting.accounting.data.entity,
    objectId: account?.data?.objectId || null,
    account: account?._id || null,
    bookingText: `${i18n.t(
      "acc:AccountingShouldPositionForm.defaultText",
      "Manuelle Sollstellung"
    )} - ${StringUtils.formatDate(new Date())}`,
    date: new Date(),
    bookings: [],
    note: "",
    linkedAsset: [],
    attachments: [],
  } as Partial<AccountingShouldPositionFormValue>;
};

const transformFormdataToSubmitdata = (
  props: AccountingShouldPositionFormProps,
  values: AccountingShouldPositionFormValue
) => {
  return props.transformSubmitValues
    ? props.transformSubmitValues(values)
    : ({
        groupDisplayName: values.bookingText,
        date: values.date,
        entity: values.entity,
        account: props.forAccount._id,
        fullAmount: 0,
        note: values.note,
        linkedAsset: [
          ...values.linkedAsset,
          ...values.attachments.map((e) => ({
            assetType: AssetTypes.CashBudget.Attachment,
            assetId: e,
          })),
        ],

        frames: values.bookings
          .filter(({ value }) => value.amount !== 0)
          .map(({ contraAccount, bookingType, value }) => ({
            objectId: values.objectId,
            contraAccount: contraAccount,
            bookings: [
              {
                bookingType:
                  AccountingService.switchAccountingBookingType(bookingType),
                value: {
                  amount: value.amount,
                  currency: value.currency,
                },
                costId: null,
                usedForId: null,
                referenceField: values.referenceField,
              },
            ],
          })),
      } as AccountingBookingFormValue);
};

export type AccountingShouldPositionFormValue = {
  entity: string;
  objectId: string;
  account: string;
  bookingText: string;
  date: Date;
  referenceField: string;
  bookings: {
    contraAccount: string;
    bookingType: AccountingBookingType;
    value: {
      amount: number;
      currency: string;
    };
  }[];
  note: string;
  linkedAsset: {
    assetType: string;
    assetId: string;
    extra?: any;
  }[];
  attachments: string[];
};

const AccountingShouldPositionForm: React.FC<AccountingShouldPositionFormProps> =
  (props) => {
    const [initialValue] = useState(getInitialValue(props));
    return (
      <FormStruct
        title={i18n.t(
          "acc:AccountingShouldPositionForm.title",
          "Manuelle Sollstellung"
        )}
        className={classNames("accounting-should-position-form")}
        onSubmit={async (values: AccountingShouldPositionFormValue) => {
          const submitData: AccountingBookingFormValue =
            transformFormdataToSubmitdata(props, values);

          const result = await AccountingService.createBooking(submitData);
          props.onSuccess?.(result);
          props.onClose();
        }}
        ignoreSubmitOnEnter
        usePrompt
        //			 description={props.asset ? i18n.t("CBBookingCategoryRuleView.UpdateDescription", "Ändern Sie die Daten des Assets und speichern Sie.") : i18n.t("CBBookingCategoryRuleView.CreateDescription", "Erstellen Sie ein neues Asset und speichern Sie.")}
        submitText={i18n.t(
          "Accounting.AccountingShouldPositionForm.Submit",
          "Speichern"
        )}
        onAbort={props.onClose}
        initialValues={initialValue}
        render={(form) => (
          <>
            <div className={`__flex`}>
              <Field
                name={`objectId`}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <FormFieldValues names={["entity"]}>
                      {([entity]) => (
                        <BFChooserSelect
                          label={i18n.t(
                            "acc:AccountingBookingForm.objectId",
                            "Objekt"
                          )}
                          disabled={!entity || hasValue(props.forAccount)}
                          hideSubLabel
                          {...input}
                          {...FormValidators.getValidation(meta)}
                          // DataBus.emit("WHISPER", {
                          //   identifier: "accounting-entity-select",
                          //   type: "CLOSE",
                          // });
                          onChange={(value) => {
                            if (value !== input.value) {
                              // reset bookings
                              form.form.mutators.setValue(`bookings`, []);
                              form.form.mutators.setValue(`account`, null);
                            }
                            input.onChange(value);
                          }}
                          data={
                            entity
                              ? OrgaStruct.getObjectSelectOptions(entity)
                              : []
                          }
                        />
                      )}
                    </FormFieldValues>
                  </div>
                )}
              </Field>
              <Field
                name={`account`}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <FormFieldValues names={["entity", `objectId`]}>
                      {([entity, objectId]) => (
                        <AccountSelection
                          label={i18n.t(
                            "acc:AccountingBookingForm.account",
                            "Konto"
                          )}
                          accountTypes={props.accountTypes}
                          disabled={!entity || hasValue(props.forAccount)}
                          entity={entity}
                          value={input.value}
                          onChange={(
                            value: string,
                            asset: AccountingAccount
                          ) => {
                            if (value !== input.value) {
                              // reset bookings
                              form.form.mutators.setValue(`bookings`, []);
                            }
                            input.onChange(value);
                          }}
                          {...FormValidators.getValidation(meta)}
                        />
                      )}
                    </FormFieldValues>
                  </div>
                )}
              </Field>
            </div>

            <FormFieldValues names={[`account`]}>
              {([account]) => {
                if (account) {
                  return (
                    <AssetLoader
                      inline
                      assetType={AssetTypes.Accounting.Account}
                      query={MQ.and(
                        MQ.eq("_id", account),
                        MQ.eq(
                          "data.linkedAsset.assetType",
                          AssetTypes.Rental.RentalAgreement
                        )
                      )}
                      render={(account: AccountingAccount) => (
                        <AccountingRentalInfo
                          accounting={props.accounting}
                          rentalAgreementId={account?.data.linkedAsset.assetId}
                        />
                      )}
                      renderError={() => null}
                    />
                  );
                }

                return null;
              }}
            </FormFieldValues>

            <AccountingFormAdditionalData
              withReferenceField
              accounting={props.accounting}
            />

            <FormFieldValues names={[`objectId`, "account"]}>
              {([objectId, account]) => (
                <>
                  {objectId && account && (
                    <AssetLoader
                      assetType={AssetTypes.Portfolio.Object}
                      id={objectId}
                      render={(object: OAObject) => (
                        <Field name={`bookings`}>
                          {({ input, meta }) => (
                            <>
                              <table className={`bookings-table`}>
                                <tr>
                                  <th>
                                    {i18n.t(
                                      "acc:AccountingBookingForm.bookings.CostId",
                                      "Kostenstelle"
                                    )}
                                  </th>
                                  <th>
                                    {i18n.t(
                                      "acc:AccountingBookingForm.bookings.Amount",
                                      "Wert"
                                    )}
                                  </th>
                                </tr>
                                {[
                                  ...object.data.feature.immo.accounting.debitposition
                                    .filter((e) =>
                                      props.taxable
                                        ? e.taxRate !== 0
                                        : e.taxRate === 0
                                    )
                                    .map((debitpos) =>
                                      MQ.and(
                                        MQ.eq(
                                          "data.linkedAsset.assetType",
                                          AssetTypes.Portfolio.Object
                                        ),
                                        MQ.eq(
                                          "data.linkedAsset.assetId",
                                          objectId
                                        ),
                                        MQ.eq(
                                          "data.linkedAsset.extra",
                                          debitpos.id
                                        )
                                      )
                                    ),
                                  MQ.and(
                                    MQ.eq(
                                      "data.accountType",
                                      AccountType.debitor_deposit
                                    ),
                                    MQ.eq(
                                      "data.entity",
                                      props.accounting.accounting.data.entity
                                    ),
                                    MQ.eq("data.taxAccount.taxRate", 0)
                                  ),
                                  MQ.and(
                                    MQ.eq(
                                      "data.accountType",
                                      AccountType.debitor_dunning
                                    ),
                                    MQ.eq(
                                      "data.entity",
                                      props.accounting.accounting.data.entity
                                    ),
                                    MQ.eq("data.taxAccount.taxRate", 0)
                                  ),
                                ].map((mq) => {
                                  return (
                                    <AssetLoader
                                      // TODO add loading tr instead of div
                                      renderLoading={() => (
                                        <tr>
                                          <td colSpan={2}>
                                            <Loader />
                                          </td>
                                        </tr>
                                      )}
                                      assetType={AssetTypes.Accounting.Account}
                                      query={mq}
                                      render={(account: AccountingAccount) => {
                                        const valueWithoutCurrent =
                                          input.value.filter(
                                            (e) =>
                                              e.contraAccount !== account._id
                                          );
                                        const el = input.value.find(
                                          (e) => e.contraAccount === account._id
                                        ) || {
                                          contraAccount: account._id,
                                          bookingType:
                                            AccountingBookingType.SOLL,
                                          value: {
                                            amount: 0,
                                            currency:
                                              props.accounting.accounting.data
                                                .currency,
                                          },
                                        };
                                        return (
                                          <tr>
                                            <td>
                                              {LanguageService.translateLabel(
                                                account.data.displayName
                                              )}
                                            </td>
                                            <td>
                                              <div>
                                                <BFInput
                                                  type="priceInput"
                                                  currencySuffix={StringUtils.getCurrencySymbol(
                                                    props.accounting.accounting
                                                      .data.currency
                                                  )}
                                                  removePrefixPadding
                                                  suffix={
                                                    <BFQuickSelect
                                                      value={
                                                        el.bookingType ||
                                                        AccountingBookingType.SOLL
                                                      }
                                                      // disabled={values.length === 0}
                                                      onChange={(
                                                        newType: AccountingBookingType
                                                      ) => {
                                                        input.onChange([
                                                          ...valueWithoutCurrent,
                                                          {
                                                            ...el,
                                                            bookingType:
                                                              newType,
                                                          },
                                                        ]);
                                                      }}
                                                      options={[
                                                        {
                                                          label: i18n.t(
                                                            "acc:SollShort",
                                                            "S"
                                                          ),
                                                          value: "S",
                                                        },
                                                        {
                                                          label: i18n.t(
                                                            "acc:HabenShort",
                                                            "H"
                                                          ),
                                                          value: "H",
                                                        },
                                                      ]}
                                                    />
                                                  }
                                                  removeSuffixPadding
                                                  textAlign="right"
                                                  value={el?.value?.amount || 0}
                                                  onChange={(value: number) => {
                                                    input.onChange([
                                                      ...valueWithoutCurrent,
                                                      {
                                                        ...el,
                                                        value: {
                                                          amount: value,
                                                          currency:
                                                            props.accounting
                                                              .accounting.data
                                                              .currency,
                                                        },
                                                      },
                                                    ]);
                                                  }}
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }}
                                    />
                                  );
                                })}

                                <tr
                                  style={{
                                    borderTop: "solid 1px #888",
                                  }}
                                >
                                  <th className="padding-top-10">
                                    {i18n.t(
                                      "acc:AccountingBookingForm.bookings.Sum",
                                      "Summe"
                                    )}
                                  </th>
                                  <th
                                    className="padding-top-10"
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    {StringUtils.formatCurrency(
                                      _.sum(
                                        (input.value || []).map((e) => {
                                          if (
                                            e.bookingType ===
                                            AccountingBookingType.HABEN
                                          ) {
                                            return -e.value.amount;
                                          } else {
                                            return e.value.amount;
                                          }
                                        })
                                      ) || 0,
                                      null,
                                      null,
                                      props.accounting.accounting.data.currency
                                    )}
                                  </th>
                                </tr>
                              </table>
                            </>
                          )}
                        </Field>
                      )}
                    />
                  )}
                </>
              )}
            </FormFieldValues>
          </>
        )}
      />
    );
  };

export default AccountingShouldPositionForm;
