import { RAInvoice } from "@/apps/tatar/invoiceApp/RAInterfaces";
import MultipleAssetLoaders from "@/components/AssetLoader/MultipleAssetLoaders";
import UserAvatar from "@/components/AvatarComponent/UserAvatar";
import Userlabel from "@/components/AvatarComponent/Userlabel";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BaseAsset from "@/model/general-assets/BaseAsset";
import { PlacementAll } from "@/modules/abstract-ui/common/Placements";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "@/modules/abstract-ui/general/whisper/BFOverlay";
import PermissionService from "@/services/PermissionService";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import BookingService from "../../../services/BookingService";
import { PortfolioLoan } from "../../portfolio/interfaces/CBPortfolioAsset";
import { RentalAgreement } from "../../tenants/TenantsInterfaces";
import { SuggestionHint } from "../CBIdlyBookingsConst";
import CBIdlyConnectInvoiceSugggestion from "../connections/CBIdlyConnectInvoiceSugggestion";
import CBIdlyConnectLoanSugggestion from "../connections/CBIdlyConnectLoanSugggestion";
import CBIdlyConnectRentalSuggestion from "../connections/CBIdlyConnectRentalSuggestion";
import CBIdlyConnectService, {
  Suggestion,
} from "../connections/CBIdlyConnectService";
import CBidlyConnectionSuggestionHint from "../connections/CBidlyConnectionSuggestionHint";
import "./CBValidationIndicator.scss";

interface CBValidationIndicatorProps {
  booking: AssetCashBudgetEntry;
  placement?: PlacementAll;
}
const CBValidationIndicator = (props: CBValidationIndicatorProps) => {
  return (
    <MultipleAssetLoaders
      assets={props.booking.data.linkedAsset}
      render={(assets: BaseAsset[]) => {
        return (
          <Indicator
            assets={assets}
            booking={props.booking}
            placement={props.placement}
          />
        );
      }}
    />
  );
};

export default CBValidationIndicator;

interface SuggestionResult {
  assetType: string;
  asset: BaseAsset;
  suggestion?: Suggestion<any, any>;
  hints?: SuggestionHint[];
}
const Indicator = (props: {
  booking: AssetCashBudgetEntry;
  assets: BaseAsset[];
  placement?: PlacementAll;
}) => {
  if (
    (props.booking.data.linkedAsset || []).length !== props.assets.length ||
    props.booking.data.linkedAsset.length === 0
  ) {
    return null;
  }
  const hasPermissionToValidate =
    PermissionService.hasSpecificBusinessUnitRoles("cb_validateBookings", [
      props.booking.data.unit,
    ]);

  const isValidatedBy = props.booking.data.validatedBooking?.user;

  const suggestions = props.booking.data.linkedAsset
    .map((linkedAsset, index) => {
      const result: SuggestionResult = {
        assetType: linkedAsset.assetType,
        asset: props.assets[index],
      };
      if (linkedAsset.assetType === AssetTypes.Invoice) {
        result.suggestion = CBIdlyConnectService.mapSuggestionInvoice(
          result.asset as RAInvoice,
          props.booking
        );
        result.hints = CBIdlyConnectService.getSuggestionHintInvoice(
          result.suggestion
        );
      }
      if (linkedAsset.assetType === AssetTypes.Portfolio.Loan) {
        result.suggestion = CBIdlyConnectService.mapSuggestionLoan(
          result.asset as PortfolioLoan,
          props.booking
        );
        result.hints = CBIdlyConnectService.getSuggestionHintLoan(
          result.suggestion
        );
      }
      if (linkedAsset.assetType === AssetTypes.Rental.RentalAgreement) {
        result.suggestion = CBIdlyConnectService.mapSuggestionRental(
          result.asset as RentalAgreement,
          props.booking
        );
        result.hints = CBIdlyConnectService.getSuggestionHintRental(
          result.suggestion
        );
      }

      if (!result.suggestion) {
        return null;
      }

      return result;
    })
    .filter((e) => e);

  let countGreen = 0;
  let countOrange = 0;
  let countRed = 0;

  suggestions.forEach((suggestion) => {
    suggestion.hints.forEach((hint) => {
      if (hint.level === "almostFully" || hint.level === "fully") {
        countGreen++;
      } else if (hint.level === "partially") {
        countOrange++;
      } else {
        countRed++;
      }
    });
  });

  const countAll = countGreen + countOrange + countRed;
  let level = "partially";
  if (countGreen > countAll * 0.9) {
    level = "full";
  } else if (countRed > countAll * 0.2 && countGreen === 0) {
    level = "warn";
  }

  return (
    <BFOverlay
      placement={props.placement}
      enterable
      speaker={
        <div className={`cb-validation-indicator-overlay`}>
          {(isValidatedBy || hasPermissionToValidate) && (
            <div className={`validated-header`}>
              {isValidatedBy && (
                <div className={`validated-by`}>
                  <div className={`description`}>
                    {i18n.t(
                      "cb:CBValidationIndicator.validatedBy.description",
                      "Die Buchung wurde validiert von"
                    )}
                  </div>
                  <div className={`user`}>
                    <Userlabel id={isValidatedBy} />
                  </div>
                  <div className={`date`}>
                    {StringUtils.formatDate(
                      props.booking.data.validatedBooking.date
                    )}
                  </div>
                </div>
              )}
              <div className={`fill`} />
              {hasPermissionToValidate && (
                <div className={`validation-action`}>
                  {isValidatedBy && (
                    <BFButton
                      appearance="link"
                      onClick={async () => {
                        await BookingService.invalidateBooking(
                          props.booking._id
                        );
                      }}
                    >
                      {i18n.t(
                        "cb:CBValidationIndicator.invalidate",
                        "Invalidieren"
                      )}
                    </BFButton>
                  )}
                  {!isValidatedBy && (
                    <BFButton
                      appearance="link"
                      onClick={async () => {
                        await BookingService.validateBooking(props.booking._id);
                      }}
                    >
                      {i18n.t(
                        "cb:CBValidationIndicator.validate",
                        "Validieren"
                      )}
                    </BFButton>
                  )}
                </div>
              )}
            </div>
          )}
          <div className={`suggestions`}>
            {suggestions.map((suggestion, index) => {
              return (
                <div className={`suggestion`} key={index}>
                  <div className={`data`}>
                    {suggestion.assetType === AssetTypes.Invoice && (
                      <CBIdlyConnectInvoiceSugggestion
                        suggestion={suggestion.suggestion}
                      />
                    )}
                    {suggestion.assetType ===
                      AssetTypes.Rental.RentalAgreement && (
                      <CBIdlyConnectRentalSuggestion
                        suggestion={suggestion.suggestion}
                      />
                    )}
                    {suggestion.assetType === AssetTypes.Portfolio.Loan && (
                      <CBIdlyConnectLoanSugggestion
                        booking={props.booking}
                        suggestion={suggestion.suggestion}
                      />
                    )}
                  </div>
                  <div className={`hints`}>
                    <CBidlyConnectionSuggestionHint hints={suggestion.hints} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
    >
      <div>
        {isValidatedBy && (
          <div className={`cb-validation-indicator-validated-by`}>
            <UserAvatar id={isValidatedBy} size={24} />
            <div className={classNames(`cb-validation-indicator`, level)}></div>
          </div>
        )}
        {!isValidatedBy && (
          <div className={classNames("cb-validation-indicator", level)}></div>
        )}
      </div>
    </BFOverlay>
  );
};
