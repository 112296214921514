import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import { AccountingAccount } from "@/apps/tatar/accounting/interfaces/account.interface";
import StructLoader from "@/components/StructLoader/StructLoader";
import ChartComponent from "@/configurable/components/ChartComponent/ChartComponent";
import i18n from "@/i18n";
import { useHttpCache } from "@/redux/hooks";
import ArrayUtils from "@/utils/ArrayUtils";
import { usePrevious } from "@/utils/Hooks";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import { DebitPositionResult } from "./CBRentalAgreementDebitPositions";
import "./CBRentalAgreementSaldoGraph.scss";

interface CBRentalAgreementSaldoGraphProps {
  accountingData: AccountingData;
  account: AccountingAccount;
  chartId: string;
}
type DebitPositionResultWithSaldo = DebitPositionResult & {
  saldo: number;
};
const CBRentalAgreementSaldoGraph = (
  props: CBRentalAgreementSaldoGraphProps
) => {
  const [dataTransformed, setDataTransformed] = useState<
    { name: string; date: Date; saldo: number; bookingValue: number }[]
  >([]);
  const data = useHttpCache<DebitPositionResult[]>(
    `cb-rental-agreement-all-debit-positions-${props.account._id}`,
    `/api/accounting/${props.accountingData.accounting.data.entity}/${props.account._id}/getDebitPositions`,
    "get",
    null
  );
  const prev = usePrevious(data?.data);
  useEffect(() => {
    if (!_.eq(prev, data?.data)) {
      const dataTransformed = ArrayUtils.sortData(data.data, {
        dir: "asc",
        key: "data.date",
      }).reduce((prev, current, index) => {
        // ...pos,
        // saldo:

        return [
          ...prev,
          {
            name: current.data.displayName,
            date: new Date(current.data.date),
            bookingValue: current.data.sum.haben - current.data.sum.soll,
            saldo:
              (prev[index - 1]?.saldo || 0) -
              current.data.sum.soll +
              current.data.sum.haben,
          },
        ];
      }, []);

      setDataTransformed(dataTransformed);
    }
  }, [data]);
  return (
    <StructLoader
      unitType={props.accountingData.accounting.data.type}
      structTypes={["objectKind", "orga"]}
      render={() => {
        return (
          <div className={classNames(`cb-rental-agreement-saldo-graph`)}>
            {data.state === "loading" && (
              <div className={`__center`}>
                <Loader />
              </div>
            )}
            {data.state === "error" && (
              <div className={`__center __error`}>
                {i18n.t(
                  "cb:RentalAgreement.DebitPositions.Error",
                  "Fehler beim Laden der Positionen"
                )}
              </div>
            )}
            {data.state === "success" && (
              <>
                {/* <DebugDataComponent data={data.data} /> */}
                {data.data.length !== 0 && (
                  <>
                    <ChartComponent
                      identifier={props.chartId}
                      options={{
                        tooltip: {
                          trigger: "axis",
                          formatter: function (params) {
                            const saldo = params[0].data.value[1];

                            const htmlValue = params
                              .map((param) => {
                                const valueOfBooking = param.data.bookingValue;
                                const name = param.data.name;
                                const date = param.data.value[0];

                                return `<div class="entry">
                                            <div class="date">
                                                ${StringUtils.formatDate(date)}
                                            </div>
                                            <div class="name">${name}</div>
                                            
                                            <div class="value ${
                                              valueOfBooking < 0
                                                ? "negative"
                                                : "positive"
                                            }">
                                            ${StringUtils.formatCurrency(
                                              valueOfBooking,
                                              true,
                                              undefined,
                                              props.accountingData.accounting
                                                .data.currency
                                            )}
                                            </div>

                                    </div>`;
                              })
                              .join("<br/>");
                            return `<div class="cb-rental-agreement-saldo-graph-tooltip">
                              <div class="saldo-line">
                                <div class="label">
                                ${i18n.t("cb:RentalAgreement.Saldo", "Saldo")}
                                </div>
                                <div class="value ${
                                  saldo < 0 ? "negative" : "positive"
                                }">
                                ${StringUtils.formatCurrency(
                                  saldo,
                                  true,
                                  undefined,
                                  props.accountingData.accounting.data.currency
                                )}
                                </div>
                              </div>
                            ${htmlValue}</div>`;
                          },
                          axisPointer: {
                            animation: false,
                          },
                        },
                        xAxis: {
                          type: "time",
                        },
                        yAxis: {
                          type: "value",
                        },
                        series: [
                          {
                            data: dataTransformed.map((e) => ({
                              name: e.name,
                              bookingValue: e.bookingValue,
                              value: [e.date?.toISOString(), e.saldo],
                            })),
                            type: "line",
                            smooth: true,
                          },
                        ],
                      }}
                    />
                  </>
                )}
              </>
            )}
          </div>
        );
      }}
    />
  );
};

export default CBRentalAgreementSaldoGraph;
