import { FC, useCallback } from "react";
import { Field } from "react-final-form";
import { FloorPlanFormImageFieldProps } from "./types";
import {
  ImageFieldNoEditView,
  ImageFieldDropzoneUpload,
  ImageFieldDropzonePreview,
} from "./components";
import BFDropzone from "@/modules/abstract-ui/dropzone/BFDropzone";
import FormValidators from "@/components/Form/Validation/FormValidators";
import ValidationPopover from "@/modules/abstract-ui/general/ValidationPopover/ValidationPopover";
import styles from "./FloorPlanFormImageField.module.scss";

const FloorPlanFormImageField: FC<FloorPlanFormImageFieldProps> = ({
  formProps,
  floorPlanBase,
}) => {
  // ! memos
  const onRemoveImageFromForm = useCallback(() => {
    formProps.form.mutators.setValue("image", "");

    // disable deps to prevent memoized ImageFieldDropzonePreview rerender
  }, []); // eslint-disable-line

  // ! render field with preview
  if (floorPlanBase?.data.images?.length) {
    return <ImageFieldNoEditView floorPlanBase={floorPlanBase} />;
  }

  // ! render field with dropzone
  return (
    <Field
      name="image"
      validate={FormValidators.compose(FormValidators.required())}
    >
      {({ input, meta }) => {
        const errorMsg = meta.error && meta.touched ? meta.error : "";

        return (
          <ValidationPopover level="error" message={errorMsg}>
            <BFDropzone
              accept={{ "image/*": [] }}
              maxFiles={1}
              onDrop={(acceptedFiles: File[]) => {
                formProps.form.mutators.setValue("image", acceptedFiles[0]);
              }}
              className={styles.dropzone}
              render={(openUploadDialog: () => void) => {
                let DropzoneContent: JSX.Element | null = null;
                let wrapperClassName = styles.upload_container;
                const containerClickHandler = input.value
                  ? undefined
                  : openUploadDialog;

                if (input.value) {
                  wrapperClassName = styles.preview_container;
                  DropzoneContent = (
                    <ImageFieldDropzonePreview
                      file={input.value}
                      onRemove={onRemoveImageFromForm}
                    />
                  );
                }

                if (!input.value) {
                  DropzoneContent = <ImageFieldDropzoneUpload />;
                }

                return (
                  <div
                    className={wrapperClassName}
                    onClick={containerClickHandler}
                  >
                    {DropzoneContent}
                  </div>
                );
              }}
            />
          </ValidationPopover>
        );
      }}
    </Field>
  );
};

export default FloorPlanFormImageField;
