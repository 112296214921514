import { AssetTypes } from "@/model/AssetTypes";
import BFDetailsButton from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "rsuite";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import TableComponent from "../../../../../../../../configurable/components/TableComponent/TableComponent";
import DebugDataComponent from "../../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../../i18n";
import BFCheckbox from "../../../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import BfIcon from "../../../../../../../../modules/abstract-ui/icon/BfIcon";
import { setTableSort } from "../../../../../../../../redux/actions/application/application-actions";
import { useTypedSelector } from "../../../../../../../../redux/hooks";
import { AppState } from "../../../../../../../../redux/store";
import DataBus from "../../../../../../../../services/DataBus";
import {
  MatchQuery,
  MatchQueryAndOr,
} from "../../../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { PortfolioLoan } from "../../../interfaces/CBPortfolioAsset";
import CBLoanChart from "./CBLoanChart";
import "./CBPortfolioLoanCard.scss";

const TABLE_IDENT = `cb-portfolio-loan-table`;

const DropdownPossibilities = () =>
  [
    {
      label: i18n.t("cb:Loan.Sort.dateDesc", "Datum absteigend"),
      name: "data.dateOfRecording",
      sortType: "desc",
    },
    {
      label: i18n.t("cb:Loan.Sort.dateAsc", "Datum aufsteigend"),
      name: "data.dateOfRecording",
      sortType: "asc",
    },
  ] as {
    label: string;
    name: string;
    sortType: "desc" | "asc";
  }[];

const RuntimeFilters = () => [
  {
    text: i18n.t("Global.Labels.total", "Gesamt"),
    value: null,
  },
  {
    text: i18n.t("cb:Loan.current", "Laufende"),
    value: "current",
  },
  {
    text: i18n.t("cb:Loan.future", "Zukünftige"),
    value: "future",
  },
  {
    text: i18n.t("cb:Loan.finished", "Abgeschlossen"),
    value: "finished",
  },
];

const generateMatchQuery = (
  // filterMonths: number,
  // typeFilter: DetailFilter,
  // config: CBPortfolioImmoConfig,
  processingStatusFilter: null | "current" | "future" | "finished",
  objectIds?: string[],
  ignoreLoanIds?: string[]
) => {
  const queryObj = {
    type: "and",
    query: [],
  } as MatchQueryAndOr;

  switch (processingStatusFilter) {
    case "current":
      queryObj.query.push({
        type: "op",
        name: "data.loanData.loanPayoutDate",
        op: "lt",
        value: moment().startOf("day").toISOString(),
      } as MatchQuery);
      queryObj.query.push({
        type: "op",
        name: "data.calculated.loanEndDate",
        op: "gt",
        value: moment().startOf("day").toISOString(),
      } as MatchQuery);
      break;
    case "finished":
      queryObj.query.push({
        type: "op",
        name: "data.calculated.loanEndDate",
        op: "lt",
        value: moment().startOf("day").toISOString(),
      } as MatchQuery);
      break;
    case "future":
      queryObj.query.push({
        type: "op",
        name: "data.loanData.loanPayoutDate",
        op: "gt",
        value: moment().startOf("day").toISOString(),
      } as MatchQuery);
      break;
  }

  if (objectIds) {
    // todo remove false check
    queryObj.query.push({
      type: "op",
      name: "data.objects.objectId",
      op: "in",
      value: objectIds,
    } as MatchQuery);
  }
  if ((ignoreLoanIds || []).length > 0) {
    queryObj.query.push({
      type: "op",
      name: "_id",
      op: "nin",
      value: ignoreLoanIds,
    } as MatchQuery);
  }

  if (queryObj.query.length === 0) {
    return undefined;
  } else {
    return queryObj;
  }
};
interface CBPortfolioLoanCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBPortfolioLoanCard = (props: CBPortfolioLoanCardProps) => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState<{
    label: string;
    name: string;
    sortType: "desc" | "asc";
  }>(DropdownPossibilities()[0]);

  const [ignoreLoanIds, setIgnoreLoanIds] = useState<string[]>([]);

  const [runtimeFilter, setRuntimeFilter] = useState<
    null | "current" | "future" | "finished"
  >(RuntimeFilters()[1].value as any);

  const assets = useTypedSelector(
    (state: AppState) =>
      state.application.tables[TABLE_IDENT]?.data as PortfolioLoan[]
  );
  // const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
  //   type: "op",
  //   name: "data.type",
  //   op: "eq",
  //   value: "immo",
  // });
  // const [filterMonths, setFilterMonths] = useState<number>(12);
  // const [detailFilter, setDetailFilter] = useState<DetailFilter>(null);
  const loading = false; //configCache.state === "loading";

  useEffect(() => {
    DataBus.emit(DataBusSubKeys.RELOAD, {
      identifiers: [TABLE_IDENT],
    });
  }, [runtimeFilter, sort]);
  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-loan-card"
      title={i18n.t("cb:Loan.loan", "Darlehen")}
      data={{
        ignoreLoanIds: ignoreLoanIds,
        query: generateMatchQuery(runtimeFilter, props.objectIds),
      }}
      loading={loading}
      // headRight={

      // }
      kpis={
        [
          // {
          //   name: "Anzahl",
          //   value: "10",
          //   width: 50,
          // },
          // {
          //   name: "Tatsächliche Kosten",
          //   value: "212,20 EUR",
          //   width: 100,
          // },
          // {
          //   name: "Geschätzte Kosten",
          //   value: "5.012,20 EUR",
          //   width: 100,
          // },
          // {
          //   name: "Diff. Schätzung",
          //   value: "89,58 %",
          //   width: 100,
          // },
        ]
      }
      subHead={
        <div>
          <div className="chart">
            <CBLoanChart
              assets={assets}
              objectIds={props.objectIds}
              chartIdentifier="cb-portfolio-loan-card"
              matchQuery={generateMatchQuery(
                runtimeFilter,
                props.objectIds,
                ignoreLoanIds
              )}
            />
          </div>
          <div className="filters">
            <div className="groups">
              <BFButtonToggle
                buttons={RuntimeFilters()}
                value={runtimeFilter}
                onChange={(val: null | "current" | "future" | "finished") => {
                  setRuntimeFilter(val);
                  setIgnoreLoanIds([]);
                }}
              />
              {/* <BFButtonToggle
              buttons={[
                {
                  text: "6 Monate",
                  value: 6,
                },
                {
                  text: "12 Monate",
                  value: 12,
                },
                {
                  text: "24 Monate",
                  value: 24,
                },
              ]}
              value={filterMonths}
              onChange={(val: number) => setFilterMonths(val)}
            /> */}
            </div>
            <div className="actions">
              <Dropdown
                placement={"bottomEnd"}
                title={
                  <div className={`order-label`}>
                    <BfIcon type="bf" data="data-transfer-vertical" />
                    {i18n.t("cb:Loan.sort", "Sortierung")} : {sort.label}
                  </div>
                }
                activeKey={`${sort.name}#${sort.sortType}`}
              >
                {DropdownPossibilities().map((entry, index) => (
                  <Dropdown.Item
                    key={index}
                    onSelect={() => {
                      setSort(entry);
                      dispatch(
                        setTableSort(TABLE_IDENT, [
                          {
                            dataKey: entry.name,
                            sortType: entry.sortType,
                          },
                        ])
                      );
                    }}
                    eventKey={`${entry.name}#${entry.sortType}`}
                  >
                    {entry.label}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
      }
      childOptions={{
        height: 400,
        render: (data) => (
          <div className="loan-table">
            <div className="table-wrapper">
              <TableComponent
                params={{
                  ignoreLoanIds: data.ignoreLoanIds,
                }}
                noDataText={i18n.t(
                  "cb:Loan.emptyText",
                  "Keine Darlehen vorhanden"
                )}
                ignoreMatchQueryExpressions={true}
                initialReload={false}
                appearance={"clear"}
                ignoreInteractionHighlights={true}
                ignoreTableSelection={true}
                useEndlessScrolling={true}
                post
                dataUrl={"/api/asset/list/portfolio-loan"}
                hideColumnHeaders={true}
                hideConfigMenu={true}
                pageSize={30}
                identifier={TABLE_IDENT}
                // onDataUpdate={(data: TableCache) => {
                //   this.setState({
                //     countAttachments: data.total ? data.total : null,
                //   });
                // }}
                hideSelectionControls={true}
                striped={false}
                matchQuery={data?.query}
                // initialSort={this.props.filterBy}
                // matchQuery={{
                //   type: "and",
                //   query: [
                //     {
                //       name: "_id",
                //       op: "in",
                //       type: "op",
                //       value: entry.data.receipts,
                //     },
                //   ],
                // }}
                columns={{
                  file: {
                    columnHeaderTextKey: "",
                    cellRenderer: (row, key, columnConf, params) => {
                      return (
                        <CBPortfolioLoanEntry
                          onChange={(checked) => {
                            setIgnoreLoanIds(
                              checked
                                ? params.ignoreLoanIds.filter(
                                    (e) => e !== row.original._id
                                  )
                                : [...params.ignoreLoanIds, row.original._id]
                            );
                          }}
                          selected={
                            params.ignoreLoanIds.indexOf(row.original._id) ===
                            -1
                          }
                          loan={row.original as PortfolioLoan}
                          key={row.original._id}
                        />
                      );
                    },
                  },
                }}
              />
            </div>
          </div>
        ),
      }}
    />
  );
};
export default CBPortfolioLoanCard;

const CBPortfolioLoanEntry = (props: {
  loan: PortfolioLoan;
  selected: boolean;
  onChange: (val: boolean) => void;
}) => {
  // const objectCache = useAssetCache<CBPortfolioObject>(
  //   "portfolio-object",
  //   props.damageClaim.data.objectId
  // );
  const {
    selected,
    onChange,
    loan: { data },
    // showObject,
    // damageClaim: { data },
  } = props;

  return (
    <div className="cb-loan-entry">
      <div className="selection">
        <BFCheckbox
          onChange={(value, checked) => {
            onChange(checked);
          }}
          checked={selected}
        />
      </div>
      <DebugDataComponent data={props.loan} />
      <div className="left">
        <div className="id text-overflow">
          {data.loanID}{" "}
          <BFDetailsButton
            size="xs"
            appearance="link"
            data={{
              assetId: props.loan._id,
              assetType: AssetTypes.Portfolio.Loan,
              type: props.loan.data.type,
            }}
          >
            {i18n.t("Global.Buttons.details")}
          </BFDetailsButton>
        </div>
        <div className="lender text-overflow">{data.lender}</div>
        <div className="text-overflow">{data.text}</div>
        {/* <div className="status">
          <div className="processing-status status-entry">
            <div className="label">Fallstatus</div>
            <div
              className="value"
              style={{ color: getColorOfProcessStatus(data.processingStatus) }}
            >
              {getNameOfProcessStatus(data.processingStatus)}
            </div>
          </div>
          <div className="approval-status status-entry">
            <div className="label">Genehmigung</div>
            <div
              className="value"
              style={{ color: getColorOfApprovalStatus(data.approvalStatus) }}
            >
              {getNameOfApprovalStatus(data.approvalStatus)}
            </div>
          </div>
          {/* {data.processingStatus === ProcessingStatus.WAITING_FOR_APPROVAL && (
            <div className="approval-status">
              {getNameOfApprovalStatus(data.approvalStatus)}
            </div>
          )} */}
        {/* </div> */}{" "}
      </div>
      <div className="right">
        {[
          [
            i18n.t("cb:Loan.loanAmount", "Darlehenssumme"),
            StringUtils.formatCurrency(data.loanData.loanAmount, true),
            120,
          ],
          [
            i18n.t("cb:Loan.annualInterest", "Zinssatz"),
            `${StringUtils.formatNumber(data.loanData.annualInterest, true)}%`,
            50,
          ],
          [
            i18n.t("cb:Loan.refinancingSum", "Offener Endbetrag"),
            StringUtils.formatCurrency(data.calculated.refinancingSum, true),
            120,
          ],
          [
            i18n.t("cb:Loan.loanPayoutDate", "Start"),
            moment(data.loanData.loanPayoutDate).format("DD.MM.YYYY"),
            90,
          ],
          [
            i18n.t("cb:Loan.loanEndDate", "Ende"),
            moment(data.calculated.loanEndDate).format("DD.MM.YYYY"),
            90,
          ],
        ].map(([label, value, minWidth]) => (
          <div className="data-entry" style={{ minWidth }}>
            <div className="label">{label}</div>
            <div className="value">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
