import React, { FC } from "react";
import i18n from "@/i18n";
import { OpenLayerControlsPanelProps } from "./types";
import styles from "./OpenLayerControlsPanel.module.scss";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ModalManager from "@/components/ModalComponent/ModalManager";

const OpenLayerControlsPanel: FC<OpenLayerControlsPanelProps> = ({
  isEditable,
  onEditClick,
  onSaveClick,
  onCancelClick,
}) => {
  // ! variables
  const editableButtonText = isEditable ? "cancel" : "edit";

  const handleCancelClick = () => {
    ModalManager.confirm({
      message: i18n.t(
        "OpenLayer.messages.confirm.cancelEditMessage",
        "Wenn Sie abbrechen, gehen alle Änderungen verloren. Sind Sie sicher?"
      ),
      confirmButtonText: i18n.t("Global.Labels.yes"),
      cancelButtonText: i18n.t("Global.Labels.no"),
      onConfirm: onCancelClick,
    });
  };

  // ! render
  return (
    <div className={styles.control_panel}>
      <BFButton
        appearance="outline"
        type="button"
        size="sm"
        onClick={isEditable ? handleCancelClick : onEditClick}
      >
        {i18n.t(`Global.Buttons.${editableButtonText}`)}
      </BFButton>

      {isEditable && (
        <BFButton
          type="submit"
          appearance="primary"
          size="sm"
          onClick={onSaveClick}
        >
          {i18n.t(`Global.Buttons.save`)}
        </BFButton>
      )}
    </div>
  );
};

export default OpenLayerControlsPanel;
