import GlobalActions from "@/services/GlobalActions";
import classNames from "classnames";
import Userlabel from "../../../../../../../../components/AvatarComponent/Userlabel";
import AssetImages from "../../../../../../../../components/CDNImageGallery/AssetImages";
import ModalManager from "../../../../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../../../../components/StructLoader/StructLoader";
import ListComponent from "../../../../../../../../configurable/components/ListComponent/ListComponent";
import DebugDataComponent from "../../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../../modules/abstract-ui/data/status/BFStatus";
import { LinkCell } from "../../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import ContactLabel from "../../../../../../../../modules/contacts-module/ContactLabel";
import AssetDocumentsButton from "../../../../../../../../modules/document-store/impl/AssetDocumentsButton";
import MaintenanceStruct from "../../../../../../../../redux/actions/struct/implemented/MaintenanceStruct";
import TechnicalUnitStruct from "../../../../../../../../redux/actions/struct/implemented/TechnicalUnitStruct";
import DataBusDefaults from "../../../../../../../../services/DataBusDefaults";
import LanguageService from "../../../../../../../../services/LanguageService";
import ArrayUtils from "../../../../../../../../utils/ArrayUtils";
import { hasValue } from "../../../../../../../../utils/Helpers";
import MQ from "../../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../../utils/StringUtils";
import {
  AP_TECHNICAL_UNITS_TABLE,
  CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER,
} from "../../../../../../activityApp/specific/maintenance/technical-units/APTechnicalUnitsConst";
import { APContractActivity } from "../../../../../../activityApp/views/details/maintenance/APActivityMainteanceInterfaces";
import CBTechnicalUnitIcon from "../../../../config/technical-unit-config/components/CBTechnicalUnitIcon";
import { RentalUnit } from "../../../../tenants/TenantsInterfaces";
import { CBPortfolioTechnicalUnit } from "../../../interfaces/CBPortfolioAsset";
import "./CBTechnicalUnitCard.scss";
import CBTechnicalUnitForm from "./CBTechnicalUnitForm";
import { Divider } from "rsuite";
import {
  ManagePositionsCardDropdown,
  FloorPlanPositionsModalManager,
} from "@/components/ManageFloorPlanPositions";
import { TechnicalUnitCategoryGroup } from "@/apps/tatar/cashBudget/views/config/technical-unit-config/CBTechnicalUnitConfigInterfaces";

interface CBTechnicalUnitCardProps {
  asset: CBPortfolioTechnicalUnit;
}
const CBTechnicalUnitCard = (props: CBTechnicalUnitCardProps) => {
  // ! handlers
  const onManagePositionsClick = (category?: TechnicalUnitCategoryGroup) => {
    FloorPlanPositionsModalManager.technicalUnitAddPosition(
      props.asset,
      category
    );
  };

  const onViewPositionsClick = (category?: TechnicalUnitCategoryGroup) => {
    FloorPlanPositionsModalManager.technicalUnitViewPosition(
      props.asset,
      category
    );
  };

  // ! render
  return (
    <StructLoader
      structType="technicalUnit"
      unitType={props.asset.data.type}
      render={() => {
        const category = TechnicalUnitStruct.getCategory(
          props.asset.data.technicalUnitCategory
        );
        const subCategory = category?.data.categories?.find(
          (e) => e.id === props.asset.data.technicalUnitCategorySub
        );

        const fields = [
          ...(category?.data.fields || []),
          ...(subCategory?.fields || []),
        ];

        return (
          <div className={classNames(`__card cb-technical-unit-card`)}>
            <div className={`gallery`}>
              <AssetImages
                assetId={props.asset._id}
                assetType={AssetTypes.Portfolio.TechnicalUnit}
                fieldPath="data.images"
              />
            </div>
            <div className={`card-content`}>
              <DebugDataComponent data={props.asset} />
              <div className={`header`}>
                <div className={`id`}>{props.asset.data.id}</div>
                <div className={`fill`} />
                {category && (
                  <div className={`category`}>
                    <CBTechnicalUnitIcon
                      value={{
                        color: category.data.color,
                        data: category.data.icon.data,
                        type: category.data.icon.type,
                      }}
                    />
                    <div className={`category-label`}>
                      <span>
                        {LanguageService.translateLabel(
                          category.data.displayName
                        )}
                      </span>
                      {subCategory && (
                        <span>
                          {" "}
                          -
                          {LanguageService.translateLabel(
                            subCategory.displayName
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={`title`}>{props.asset.data.displayName}</div>
              {props.asset.data.note && (
                <div className={`note`}>
                  <div className={`note-label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.technicalUnit.create.note",
                      "Notiz"
                    )}
                  </div>
                  <div className={`note-content`}>{props.asset.data.note}</div>
                </div>
              )}
              {fields.length > 0 && (
                <table className={`custom-fields`}>
                  {fields.map((fieldCfg) => {
                    const value = props.asset.data.customFields.find(
                      (e) => e.fieldId === fieldCfg.id
                    )?.value;
                    let formattedValue = hasValue(value) ? value : "-";
                    if (fieldCfg.fieldType === "date" && hasValue(value)) {
                      formattedValue = StringUtils.formatDate(value);
                    }

                    return (
                      <tr key={fieldCfg.id}>
                        <td className={`label`}>
                          {LanguageService.translateLabel(fieldCfg.displayName)}
                        </td>
                        <td className={`value`}>{formattedValue}</td>
                      </tr>
                    );
                  })}
                </table>
              )}

              {props.asset.data.rentalUnits &&
                (props.asset.data.rentalUnits?.length || 0) > 0 && (
                  <>
                    <hr />
                    <div>
                      <div className={`category-label`}>
                        <span>
                          {i18n.t(
                            "cb:Portfolio.Objekt.technicalUnit.create.rentalUnits",
                            "Mieteinheiten"
                          )}
                        </span>
                      </div>
                      <ListComponent
                        identifier={`supply-unit-rental-units-${props.asset._id}`}
                        assetType={AssetTypes.Rental.RentalUnit}
                        additionalMatchQuery={MQ.in(
                          "_id",
                          props.asset.data.rentalUnits
                        )}
                        emptyText={i18n.t(
                          "cb:Portfolio.Objekt.supplyUnit.rentalUnits.empty",
                          "Keine Mietobjekte vorhanden"
                        )}
                        render={(rentalUnit: RentalUnit) => {
                          return (
                            <BFButton
                              noPadding
                              appearance="link"
                              onClick={() => {
                                GlobalActions.openDetails(
                                  AssetTypes.Rental.RentalUnit,
                                  rentalUnit._id,
                                  rentalUnit.data.type
                                );
                              }}
                            >
                              {`${rentalUnit.data.id} - ${rentalUnit.data.displayName}`}
                            </BFButton>
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              <hr />
              <StructLoader
                unitType={props.asset.data.type}
                structTypes={["maintenance", "unit", "orga"]}
                render={() => <TechniclUnitContracts asset={props.asset} />}
              />

              <hr />

              <div className={`actions`}>
                <AssetDocumentsButton
                  assetId={props.asset._id}
                  assetType={AssetTypes.Portfolio.TechnicalUnit}
                  documentsFieldPath="data.attachments"
                />
                <div className={`fill`} />

                <div className="right-side-actions-wrapper">
                  <BFButton
                    appearance="link"
                    noPadding
                    onClick={() => {
                      ModalManager.show({
                        noPadding: true,
                        content: (state, setState, close) => (
                          <CBTechnicalUnitForm
                            asset={props.asset}
                            onClose={close}
                            onArchived={() => {
                              close();
                              DataBusDefaults.reload({
                                identifiers: [
                                  AP_TECHNICAL_UNITS_TABLE,
                                  CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER,
                                ],
                              });
                              DataBusDefaults.closeModal("__technical-unit");
                            }}
                            onSuccess={() => {
                              //   DataBusDefaults.reload({
                              //     identifiers: [TABLE_IDENT],
                              //   });
                              close();
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    {i18n.t("Global.Buttons.edit")}
                  </BFButton>

                  <Divider vertical />

                  <ManagePositionsCardDropdown
                    onManagePositionsClick={() =>
                      onManagePositionsClick(category)
                    }
                    onViewPositionsClick={() => onViewPositionsClick(category)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default CBTechnicalUnitCard;

const TechniclUnitContracts = (props: { asset: CBPortfolioTechnicalUnit }) => {
  const activeMaintenanceStatus =
    MaintenanceStruct.getStatusByActivityStatusAndType(
      props.asset.data.type,
      "active",
      "waiting"
    );

  return (
    <div className={`contracts`}>
      <ListComponent
        identifier={`technical-unit-contracts-${props.asset._id}`}
        assetType={AssetTypes.Activity.Maintenance}
        additionalMatchQuery={MQ.and(
          MQ.eq("data.relations.assetId", props.asset._id),
          MQ.in(
            "data.status",
            activeMaintenanceStatus.map((e) => e.id)
          )
        )}
        emptyText={i18n.t(
          "cb:Portfolio.Objekt.technicalUnit.contracts.empty",
          "Keine Wartungsverträge vorhanden"
        )}
        render={(activity: APContractActivity) => {
          const status = MaintenanceStruct.getStatusOf(activity);
          let statusLabel = null;
          if (status) {
            statusLabel = LanguageService.translateLabel(status.displayName);
            let subStatues = null;
            if (status.subStatus) {
              subStatues = status.subStatus.find(
                (e) => e.id === activity.data.subStatus
              );
              if (subStatues) {
                statusLabel = `${statusLabel} - ${LanguageService.translateLabel(
                  subStatues.displayName
                )}`;
              }
            }
          }

          const nextDueAppointment = ArrayUtils.sortData(
            activity.data.appointments
              .filter((e) => e.status === "open")
              .filter((e) =>
                e.affectedTechnicalUnits.includes(props.asset._id)
              ),
            {
              key: "dueDate",
              dir: "asc",
            }
          )?.[0];
          return (
            <div className={`activity-entry`}>
              <div className={`title-row`}>
                <div className={`activity-id`}>
                  <LinkCell
                    assetType={AssetTypes.Activity.Maintenance}
                    id={activity._id}
                    text={activity.data.activityId}
                  />
                </div>
                <div className={`activity-name`}>
                  {activity.data.displayName}
                </div>
                {statusLabel && (
                  <div>
                    <BFStatus
                      size="xxs"
                      color={status.color}
                      label={statusLabel}
                      icon={status.icon}
                    />
                  </div>
                )}
              </div>
              <table className={`contract-data`}>
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.technicalUnit.contracts.contact",
                      "Vertragspartner"
                    )}
                  </td>
                  <td>
                    {activity.data.contact ? (
                      <ContactLabel contactId={activity.data.contact} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.technicalUnit.contracts.nextDue",
                      "Nächste Fälligkeit"
                    )}
                  </td>
                  <td>
                    {nextDueAppointment
                      ? `${StringUtils.formatDate(
                          nextDueAppointment.dueDate
                        )} / ${nextDueAppointment.note || "-"}`
                      : "-"}
                  </td>
                </tr>
                {activity.data.noticePeriod.type === "limited" ? (
                  <>
                    <tr>
                      <td className={`label`}>
                        {i18n.t(
                          "cb:Portfolio.Objekt.technicalUnit.contracts.contractDuration",
                          "Vertragsdauer"
                        )}
                      </td>
                      <td>
                        {activity.data.contractStartDate
                          ? StringUtils.formatDate(
                              activity.data.contractStartDate
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={`label`}>
                        {i18n.t(
                          "cb:Portfolio.Objekt.technicalUnit.contracts.noticeUntil",
                          "Kündigung bis"
                        )}
                      </td>
                      <td>
                        {activity.data.noticePeriod.typeData
                          .earliestCancelationDate
                          ? StringUtils.formatDate(
                              activity.data.noticePeriod.typeData
                                .earliestCancelationDate
                            )
                          : "-"}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td className={`label`}>
                      {i18n.t(
                        "cb:Portfolio.Objekt.technicalUnit.contracts.contractDuration",
                        "Vertragsdauert"
                      )}
                    </td>
                    <td>
                      {activity.data.contractStartDate
                        ? StringUtils.formatDate(
                            activity.data.contractStartDate
                          )
                        : i18n.t(
                            "cb:Portfolio.Objekt.technicalUnit.contracts.notSetYet",
                            "nicht gesetzt"
                          )}{" "}
                      -{" "}
                      {i18n.t(
                        "cb:Portfolio.Objekt.technicalUnit.contracts.unlimited",
                        "unbefristet"
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.technicalUnit.contracts.costOfContract",
                      "Vertragskosten"
                    )}
                  </td>
                  <td>
                    {activity.calculated.budgetSumShould
                      ? StringUtils.formatCurrency(
                          activity.calculated.budgetSumShould
                        )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.technicalUnit.contracts.assignee",
                      "Zugewiesen"
                    )}
                  </td>
                  <td>
                    {activity.data.assignee ? (
                      <Userlabel id={activity.data.assignee} avatarSize={18} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </table>
            </div>
          );
        }}
      />
      {/* 
     prio, vertragsparrtner, nächste Fälligkeit (zu der technischen einheit),
    Vertrags bis, Kündigung bis, Vertragskosten,
    Verantwortlich
  */}
    </div>
  );
};
