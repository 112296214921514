import PublicFetchService from "@/services/PublicFetchService";
import sanitizeHtml from "sanitize-html";

const IBERIO_PUBLIC_CDN = "https://d4sxwz0gz1e3r.cloudfront.net";

export const sanitzeS3Icon = (svgData: string): string | null => {
  if (svgData) {
    let attributeString = "";
    if (svgData.indexOf("<style>") !== -1) {
      const regex = /<style>.*{(.*)}<\/style>/gm;
      const match = regex.exec(svgData);
      if (match) {
        const styles = match[1];
        const allStyles = styles.split(";");

        allStyles.forEach((style) => {
          const styleSplit = style.split(":");
          if (styleSplit.length === 2) {
            const styleName = styleSplit[0].trim();
            const styleValue = styleSplit[1].trim();
            attributeString += `${styleName}="${styleValue}" `;
          }
        });
      }
    }

    const useSVGData = svgData
      .replace(/(<[a-zA-Z]* )/gm, `$1 ${attributeString}`)
      .replace("<svg ", `<svg stroke="currentColor" `);
    // .replace(`viewBox="0 0 24 24"`, `viewBox="0 0 26 26"`);

    return sanitizeHtml(useSVGData, {
      allowedTags: [
        "svg",
        "path",
        "defs",
        "linearGradient",
        "rect",
        "polygon",
        "circle",
        "g",
        "title",
      ],
      allowedAttributes: false,
    });
  } else {
    return null;
  }
};

export const fetchS3Icon = async (
  iconType: string,
  iconName: string
): Promise<string | null> => {
  const svgData = await PublicFetchService.fetch(
    `${IBERIO_PUBLIC_CDN}/icons/${iconType}/${iconName}.svg`,
    "text"
  );

  //sanitize
  return sanitzeS3Icon(svgData);
};

export const getDirectS3Icon = (
  iconType: string,
  iconName: string
): string | null => {
  const url = `${IBERIO_PUBLIC_CDN}/icons/${iconType}/${iconName}.svg`;
  const svgData = PublicFetchService.getDirectResponseFromCache(url);
  return sanitzeS3Icon(svgData);
};
