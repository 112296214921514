import CustomFieldsService from "@/modules/customfields/CustomFieldsService";
import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import ObjectIdService from "@/utils/ObjectIdUtils";
import moment from "moment";
import { nanoid } from "nanoid";
import { AssetTypes } from "../../../model/AssetTypes";
import CDNService from "../../../services/CDNService";
import CacheService from "../../../services/CacheService";
import DataBusDefaults from "../../../services/DataBusDefaults";
import { MatchQuery } from "../../../services/DataService";
import PermissionService from "../../../services/PermissionService";
import ServiceUtils from "../../../services/ServiceUtils";
import { hasValue, isDefined } from "../../../utils/Helpers";
import { HTTP } from "../../../utils/Http";
import MQ from "../../../utils/MatchQueryUtils";
import { ActivityApplicationConstants } from "./ActivityHooks";
import { APActivity } from "./ActivityInterfaces";
import { ActivityAdvertisement } from "./views/details/advertisement-feature/APAdvertisement.interface";
import { APAdvertisementActivity } from "./views/details/maintenance/APActivityMainteanceInterfaces";
import {
  APOffer,
  APOfferFormValue,
} from "./views/details/offer-feature/APOffer.Interface";
import {
  APInvoiceProjectLinkSplitValue,
  ProjectBudgetGroup,
  ProjectBudgetPlanEntry,
  ProjectScheduleGroup,
} from "./views/details/project-budget/APProjectBudgetInterfaces";

export interface APAttachmentFileData {
  file: File;
  name: string;
  note?: string;
  documentType?: string;
}
class ActivityServiceClass {
  async updateSupervisors(
    baseUrl: string,
    activityId: string,
    preState: string[],
    postState: string[]
  ) {
    const idsToDelete = preState.filter((e) => !postState.includes(e));
    const idsToAdd = postState.filter((e) => !preState.includes(e));

    let result = null;
    for (const id of idsToDelete) {
      result = await HTTP.post({
        url: `/${baseUrl}/${activityId}/unsetSupervisor`,
        target: "EMPTY",
        bodyParams: {
          value: id,
        },
      });
    }
    for (const id of idsToAdd) {
      result = await HTTP.post({
        url: `/${baseUrl}/${activityId}/setSupervisor`,
        target: "EMPTY",
        bodyParams: {
          value: id,
        },
      });
    }
    CacheService.updateDataInCaches(result._id, result);

    return result;
  }

  async createActivity(
    baseUrl: string,
    data: any,
    linkCommentId?: string,
    customs = {}
  ) {
    const result = await HTTP.post({
      url: `/${baseUrl}/createNewActivity`,
      target: "EMPTY",
      bodyParams: {
        type: data.type,
        name: data.name,
        shortDescription: data.shortDescription || "",
        objectId: data.objectId || undefined,
        entity: data.entity,
        relations: data.relations,
        commentId: linkCommentId,
        linkedAsset: data.linkedAsset || undefined,
        ...customs,
      },
    });

    return result as APActivity;
  }

  async startApprovalDeprecated(
    baseUrl: string,
    damageId: string,
    displayName: string,
    offerIds: string[],
    urgent: boolean,
    urgentComment: string,
    assetType: string
  ) {
    let output = await HTTP.post({
      url: `/${baseUrl}/${damageId}/startOfferApproval`,
      target: "EMPTY",
      bodyParams: {
        offerEntries: offerIds,
        displayName,
        urgent: hasValue(urgent) ? urgent : false,
        urgentComment: hasValue(urgent) ? urgentComment : undefined,
      },
    });
    CacheService.updateDataInCaches(output._id, output);
    return output;
  }

  async approveOffer(
    baseUrl: string,
    assetType: string,
    damageId: string,
    type: "accept" | "decline",
    offerApprovalId: string,
    selectedId?: string
  ) {
    let activity = await HTTP.post({
      url: `/${baseUrl}/${damageId}/acceptApproval`,
      target: "EMPTY",
      bodyParams: {
        type,
        offerApprovalId,
        selectedId,
      },
    });
    CacheService.updateDataInCaches(activity._id, activity);
    const approval = activity.data.offerApproval.find(
      (e) => e.id === offerApprovalId
    );
    if (approval.status === "ongoing") {
      const task = await CacheService.getData({
        oType: "asset",
        id: approval.taskId,
        assetType: AssetTypes.Task,
        forceReload: true,
        silentReload: true,
      });
    }

    // CacheService.updateDataInCaches(activity._id, activity);

    return activity;
  }
  async declineOffer(
    baseUrl: string,
    assetType: string,
    damageId: string,
    type: "accept" | "decline",
    offerApprovalId: string,
    reason?: string
  ) {
    let activity = await HTTP.post({
      url: `/${baseUrl}/${damageId}/acceptApproval`,
      target: "EMPTY",
      bodyParams: {
        type,
        offerApprovalId,
        declineReason: reason,
      },
    });
    CacheService.updateDataInCaches(activity._id, activity);
    const approval = activity.data.offerApproval.find(
      (e) => e.id === offerApprovalId
    );
    if (approval.status === "ongoing") {
      const task = await CacheService.getData({
        oType: "asset",
        id: approval.taskId,
        assetType: AssetTypes.Task,
        forceReload: true,
        silentReload: true,
      });
    }
    // CacheService.updateDataInCaches(activity._id, activity);

    return activity;
  }

  async declineActivity(baseUrl: string, damageClaim: APActivity) {
    const output = await HTTP.post({
      url: `/${baseUrl}/${damageClaim._id}/abort`,
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(output._id, output);
    return output;
  }

  async uploadOffer(
    assetType: string,
    assetId: string,
    data: {
      file: File;
      vendor: string;
      value: number;
      executionDate?: Date;
      mail?: string;
      phone?: string;
      note?: string;
      didComparison?: boolean;
      comparisons?: any[];
    }
  ) {
    const tempId = nanoid();
    const cdnData = (await CDNService.uploadFile({
      file: data.file,
      filename: data.file.name,
      assetType: assetType,
      assetField: "data.offers",
      assetId: assetId,
      tempID: tempId,
    })) as { cdnID: string };

    const output = (await CDNService.linkFile(
      assetType,
      assetId,
      cdnData.cdnID,
      {
        path: "data.offers",
        type: "array",
        value: {
          status: "new",
          value: data.value,
          vendor: data.vendor,
          executionDate: data.executionDate,
          mail: data.mail,
          phone: data.phone,
          note: data.note,
          didComparison: data.didComparison,
          comparisons: data.didComparison ? data.comparisons : undefined,
        },
      }
    )) as APActivity;
    CacheService.updateDataInCaches(output._id, output);
    return output;
  }

  routeToFilteredList(
    constants: ActivityApplicationConstants,
    propertyPath: string,
    ids: string[],
    activityStruct: ActivityAbstractStructClass<any>,
    span?: { from: moment.Moment; to: moment.Moment },
    showActive?: boolean
  ) {
    const statusFilter = [];

    if (showActive) {
      if (propertyPath !== "data.status") {
        const allActiveStatus = activityStruct
          .getAllStatus()
          .filter((status) => {
            return status.activity_status === "active";
          })
          .map((status) => status.id);
        statusFilter.push({
          filterKey: "data.status",
          value: allActiveStatus,
        });
      }
    }

    if (
      PermissionService.hasBusinessUnitRole(
        constants?.permissionPrefix + "listActivities"
      )
    ) {
      const queryParams = {
        filter: [
          {
            filterKey: propertyPath,
            value: ids,
          },
          ...(span
            ? [
                {
                  filterKey: "data.creationDate",
                  value: {
                    type: "range",
                    from: moment(span.from).utc(true).toISOString(),
                    to: moment(span.to).utc(true).toISOString(),
                  },
                },
              ]
            : []),
          ...statusFilter,
        ],
      };

      DataBusDefaults.route({
        route: "/list-activities",
        stayInApp: true,
        queryParams,
      });
    }
  }
  async saveBudgetDraftTimespan(
    baseUrl: string,
    activityId: string,
    entries: ProjectBudgetPlanEntry[],
    fromDate: Date,
    toDate: Date
  ) {
    return ServiceUtils.toastError(async () => {
      const budget = await HTTP.post({
        url: `/${baseUrl}/${activityId}/saveBudgetDraft`,
        target: "EMPTY",
        bodyParams: {
          entries,
          fromDate,
          toDate,
        },
      });
      CacheService.updateDataInCaches(budget._id, budget);
      return budget;
    });
  }

  async saveProjectBudgetDraft(
    baseUrl: string,
    activityId: string,
    entries: ProjectBudgetPlanEntry[],
    groups: ProjectBudgetGroup[],
    fromDate: Date,
    toDate: Date,
    scheduleGroups: ProjectScheduleGroup[]
  ) {
    return ServiceUtils.toastError(async () => {
      const budget = await HTTP.post({
        url: `/${baseUrl}/${activityId}/saveDraft`,
        target: "EMPTY",
        bodyParams: {
          entries,
          groups,
          fromDate,
          toDate,
          scheduleGroups,
        },
      });
      CacheService.updateDataInCaches(budget._id, budget);
      return budget;
    });
  }

  async publishProjectBudgetDraft(
    baseUrl: string,
    activityId: string,
    budgetId: string
  ) {
    return ServiceUtils.toastError(async () => {
      const activty = await HTTP.post({
        url: `/${baseUrl}/${activityId}/${budgetId}/publishDraft`,
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(activty._id, activty);
      return activty;
    });
  }

  async setProjectBudgetApproval(
    baseUrl: string,
    taskId: string,
    status: "accept" | "decline",
    reason?: string
  ) {
    return ServiceUtils.toastError(async () => {
      const task = await HTTP.post({
        url: `/${baseUrl}/${taskId}/acceptProjectApproval`,
        target: "EMPTY",
        bodyParams: {
          type: status,
          declineReason: reason || undefined,
        },
      });
      return task;
    });
  }

  async updateBudgetInvoicePositions(
    baseUrl: string,
    budgetInvoiceLinkId: string,
    values: APInvoiceProjectLinkSplitValue[]
  ) {
    return ServiceUtils.toastError(async () => {
      const budgetInvoiceLink = await HTTP.post({
        url: `/${baseUrl}/${budgetInvoiceLinkId}/splitPlan`,
        target: "EMPTY",
        bodyParams: {
          data: values,
        },
      });
      CacheService.updateDataInCaches(budgetInvoiceLink._id, budgetInvoiceLink);
      return budgetInvoiceLink;
    });
  }

  async setSchedule(
    baseUrl: string,
    activityId: string,
    scheduleGroupid: string,
    daysNeeded: number,
    comment?: string
  ) {
    return ServiceUtils.toastError(async () => {
      const budgetInvoiceLink = await HTTP.post({
        url: `/${baseUrl}/${activityId}/${scheduleGroupid}/setSchedule`,
        target: "EMPTY",
        bodyParams: {
          daysNeeded,
          comment,
        },
      });
      CacheService.updateDataInCaches(budgetInvoiceLink._id, budgetInvoiceLink);
      return budgetInvoiceLink;
    });
  }
  async unsetSchedule(
    baseUrl: string,
    activityId: string,
    scheduleGroupId: string
  ) {
    return ServiceUtils.toastError(async () => {
      const budgetInvoiceLink = await HTTP.post({
        url: `/${baseUrl}/${activityId}/${scheduleGroupId}/unsetSchedule`,
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(budgetInvoiceLink._id, budgetInvoiceLink);
      return budgetInvoiceLink;
    });
  }

  async saveBudgetDraft(
    baseUrl: string,
    activityId: string,
    entries: ProjectBudgetPlanEntry[],
    startYear: number,
    turnusInYears: number
  ) {
    return ServiceUtils.toastError(async () => {
      const budget = await HTTP.post({
        url: `/${baseUrl}/${activityId}/saveBudgetDraft`,
        target: "EMPTY",
        bodyParams: {
          entries,
          startYear,
          turnusInYears,
        },
      });
      CacheService.updateDataInCaches(budget._id, budget);
      return budget;
    });
  }

  async publishBudgetDraft(
    baseUrl: string,
    activityId: string,
    budgetId: string
  ) {
    return ServiceUtils.toastError(async () => {
      const activty = await HTTP.post({
        url: `/${baseUrl}/${activityId}/${budgetId}/publishDraft`,
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(activty._id, activty);
      return activty;
    });
  }

  async setBudgetApproval(
    baseUrl: string,
    taskId: string,
    status: "accept" | "decline",
    reason?: string
  ) {
    return ServiceUtils.toastError(async () => {
      const task = await HTTP.post({
        url: `/${baseUrl}/${taskId}/acceptBudgetApproval`,
        target: "EMPTY",
        bodyParams: {
          type: status,
          declineReason: reason || undefined,
        },
      });
      return task;
    });
  }

  async markAsCancelContract(baseUrl: string, activityId: string) {
    return ServiceUtils.toastError(async () => {
      const activty = await HTTP.post({
        url: `/${baseUrl}/${activityId}/markAsCancelContract`,
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(activty._id, activty);
      return activty;
    });
  }
  async extendContracts(
    baseUrl: string,
    query: {
      matchQuery?: MatchQuery;
      textQuery?: string;
    }
  ) {
    return ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/${baseUrl}/extendContracts`,
        target: "EMPTY",
        bodyParams: {
          query,
        },
      });
      return result;
    });
  }
  async extendContract(baseUrl: string, activityId: string) {
    return ServiceUtils.toastError(async () => {
      await this.extendContracts(baseUrl, {
        matchQuery: MQ.eq("_id", activityId),
      });
      const result = await CacheService.getData({
        id: activityId,
        oType: "asset",
        assetType: AssetTypes.Activity.Maintenance,
        forceReload: true,
      });
      CacheService.update(result);
      return result;
    });
  }

  async setStatus(
    baseUrl: string,
    activityId: string,
    newStatus: string,
    newSubStatus?: string
  ) {
    return ServiceUtils.toastError(async () => {
      const activty = await HTTP.post({
        url: `/${baseUrl}/${activityId}/setStatus`,
        target: "EMPTY",
        bodyParams: {
          newStatus,
          newSubStatus: newSubStatus || null,
        },
      });
      CacheService.updateDataInCaches(activty._id, activty);
      return activty;
    });
  }

  async submitOffer(
    activityId: string,
    value: APOfferFormValue,
    constants: ActivityApplicationConstants,
    asset?: APOffer
  ) {
    return ServiceUtils.toastError(async () => {
      const result = await CustomFieldsService.handleCustomFieldAttachments({
        assetType: AssetTypes.Activity.Offer,
        customFields: constants.fields?.assetApproval?.fields || [],
        submitFc: async (value) => {
          return (await HTTP.post({
            url: isDefined(asset)
              ? `/${constants.serviceUrl}/${asset._id}/updateOffer`
              : `/${constants.serviceUrl}/${activityId}/createNewOffer`,
            target: "EMPTY",
            bodyParams: {
              ...value,
            },
          })) as APOffer;
        },
        values: value,
        prefix: "meta",
        asset: asset,
      });
      if (isDefined(asset)) {
        CacheService.update(result);
      }
      return result;
    });
  }

  async startOfferApproval(
    baseUrl: string,
    activityId: string,
    displayName: string,
    offerIds: string[],
    urgent: boolean,
    urgentComment: string
  ) {
    let output = await HTTP.post({
      url: `/${baseUrl}/${activityId}/startNewOfferApproval`,
      target: "EMPTY",
      bodyParams: {
        offerEntries: offerIds,
        displayName,
        urgent: hasValue(urgent) ? urgent : false,
        urgentComment: hasValue(urgent) ? urgentComment : undefined,
      },
    });
    CacheService.updateDataInCaches(output._id, output);
    return output;
  }

  async updateAdvertisement(
    serviceUrl: string,
    activity: APAdvertisementActivity,
    id: string,
    data: Partial<ActivityAdvertisement>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `${serviceUrl}/${activity._id}/updateAdvertisements`,
        target: "EMPTY",
        bodyParams: {
          advertisements: activity.data.advertisements.map((advertismement) => {
            if (advertismement.id === id) {
              return {
                ...advertismement,
                ...data,
              };
            }
            return advertismement;
          }),
        },
      });
      CacheService.update(result);
    });
  }
  async addAdvertisement(
    serviceUrl: string,
    rentalactivityacancy: APAdvertisementActivity,
    data: Partial<ActivityAdvertisement>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `${serviceUrl}/${rentalactivityacancy._id}/updateAdvertisements`,
        target: "EMPTY",
        bodyParams: {
          advertisements: [
            ...(rentalactivityacancy.data.advertisements || []),
            { ...data, id: ObjectIdService.new(), status: "active" },
          ],
        },
      });
      CacheService.update(result);
    });
  }
  async removeAdvertisement(
    serviceUrl: string,
    activity: APAdvertisementActivity,
    id: string
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `${serviceUrl}/${activity._id}/updateAdvertisements`,
        target: "EMPTY",
        bodyParams: {
          advertisements: activity.data.advertisements.filter(
            (advertismement) => {
              if (advertismement.id === id) {
                return false;
              }
              return true;
            }
          ),
        },
      });
      CacheService.update(result);
    });
  }
}
const ActivityService = new ActivityServiceClass();
export default ActivityService;
