import moment from "moment";
import { useEffect } from "react";
import ChartComponent from "../../../../../../../../configurable/components/ChartComponent/ChartComponent";
import DebugDataComponent from "../../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../../i18n";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { MatchQuery } from "../../../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import MQ from "../../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { PortfolioLoan } from "../../../interfaces/CBPortfolioAsset";
import { CBStatisticLoanEntry } from "../../../interfaces/CBStatisticQueries";
import KPISingleValueCard from "../SingleKPIValue";
import "./CBLoanChart.scss";

export const cbLoanChartOption = (
  data: CBStatisticLoanEntry[],
  forPdf?: boolean
) => ({
  animation: forPdf ? false : true,
  title: {
    show: false,
    // text: "Rainfall vs Evaporation",
    // left: "center",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      animation: false,
    },
    valueFormatter: (val) => StringUtils.formatCurrency(val),

    // position: ["90%", 0],
    formatter: (entries, b, c, d) => {
      return `<div class="cb-loan-chart-tooltip">
        <h5 class="head">${moment(entries[0].axisValueLabel, "MMM YYYY").format(
          "MMMM YYYY"
        )}</h5>
        ${entries
          .sort((a, b) => a.seriesIndex - b.seriesIndex)
          .map(
            (entry, index) => `
        <div class="entry">
        <div class="label ${index === 4 ? "bold" : ""}">
        ${entry.marker} ${entry.seriesName}
        </div>
        <div class="value">
          ${StringUtils.formatCurrency(entry.value)}
        </div>
      </div>
        ${index === 1 ? `<div class="divider"></div>` : ""} 
        `
          )
          .join("")}
        
      </div>`;
    },
  },
  legend: {
    show: forPdf ? true : false,
    // data: ["Evaporation", "Rainfall"],
    // left: 10,
  },
  toolbox: {
    feature: {
      //   dataZoom: {
      //     yAxisIndex: "none",
      //   },
      //   restore: {},
      //   saveAsImage: {},
    },
  },
  axisPointer: {
    link: [
      {
        xAxisIndex: [0, 1],
      },
      {
        xAxisIndex: [1, 0],
      },
    ],
  },
  dataZoom: forPdf
    ? undefined
    : [
        {
          brushSelect: false,
          type: "slider",
          show: true,
          realtime: true,
          // start: 0,
          startValue: moment()
            .startOf("month")
            .subtract(12, "month")
            .format("MMM YYYY"),
          // end: 100,
          endValue: moment()
            .startOf("month")
            .add(72, "month")
            .format("MMM YYYY"),
          xAxisIndex: [0, 1],
          zoomOnMouseWheel: false,
          moveOnMouseWheel: false,
        },
        {
          brushSelect: false,
          type: "inside",
          realtime: true,
          // start: 0,
          startValue: moment()
            .startOf("month")
            .subtract(12, "month")
            .format("MMM YYYY"),
          // end: 100,
          endValue: moment()
            .startOf("month")
            .add(72, "month")
            .format("MMM YYYY"),
          xAxisIndex: [0, 1],
          zoomOnMouseWheel: false,
          moveOnMouseWheel: false,
        },
      ],
  grid: [
    {
      top: forPdf ? 50 : 30,
      left: 125,
      right: 50,
      height: "40%",
    },
    {
      left: 125,
      right: 50,
      top: "55%",
      height: "35%",
    },
  ],
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      axisLine: { onZero: true },
      data: data.map((e) =>
        moment()
          .year(e._id.year)
          .month(e._id.month - 1)
          .format("MMM YYYY")
      ),
      offset: 15,
    },
    {
      show: false,
      gridIndex: 1,
      type: "category",
      boundaryGap: false,
      axisLine: { onZero: true },
      data: data.map((e) =>
        moment()
          .year(e._id.year)
          .month(e._id.month - 1)
          .format("MMM YYYY")
      ),
      position: "top",
    },
  ],
  yAxis: [
    {
      name: i18n.t("cb:Loan.Chart.restvaluta", "Restvaluta"),
      type: "value",
      nameLocation: "middle",
      nameGap: 110,
      nameTextStyle: {
        fontSize: 15,
      },
      axisLabel: {
        formatter: (val) => StringUtils.formatCurrency(val),
      },
    },
    {
      gridIndex: 1,
      name: i18n.t(
        "cb:Loan.Chart.InterestAndRepaymentHistory",
        "Zins- und Tilgungsverlauf"
      ),
      type: "value",
      nameLocation: "middle",
      nameGap: 110,
      nameTextStyle: {
        fontSize: 15,
      },
      axisLabel: {
        formatter: (val) => StringUtils.formatCurrency(val),
      },
      //   inverse: true,
    },
  ],
  series: [
    {
      type: "line",
      markLine: {
        silent: true,
        symbol: ["none", "none"],
        label: { formatter: () => "Heute", color: "#000" },
        data: [{ xAxis: moment().format("MMM YYYY") }],
      },
    },
    {
      name: i18n.t("cb:Loan.Chart.restvaluta", "Restvaluta"),
      type: "line",
      symbolSize: 3,
      // prettier-ignore
      data: data.map(e => e.endBalance),
    },
    {
      name: i18n.t("cb:Loan.Chart.payedInterest", "Bezahlte Zinsen"),
      type: "line",
      symbolSize: 3,
      // prettier-ignore
      data: data.map(e => e.accumulatedInterest),
    },
    {
      name: i18n.t("cb:Loan.Chart.interest", "Zinsen"),
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 1,
      symbolSize: 3,
      stack: "zins/tilgung",
      areaStyle: {
        // opacity: 0.1,
      },
      emphasis: {
        focus: "series",
      },
      // prettier-ignore
      data: data.map(e => e.interestAmount),
    },
    {
      name: i18n.t("cb:Loan.Chart.repayment", "Tilgung"),
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 1,
      symbolSize: 3,
      stack: "zins/tilgung",
      areaStyle: {
        // opacity: 0.1,
      },
      emphasis: {
        focus: "series",
      },
      // prettier-ignore
      data: data.map(e => e.repaymentAmount),
      //   label: {
      //     emphasis: {
      //       show: true,
      //       position: "top",
      //       formatter: (params) => {
      //         return data.map((e) => e.repaymentAmount + e.interestAmount)[
      //           params.index
      //         ];
      //       },
      //     },
      //   },
    },
    {
      itemStyle: {
        color: "#a3a3a300",
        opacity: 0,
      },
      name: i18n.t("cb:Loan.Chart.anuity", "Annuität"),
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 1,
      symbolSize: 0,
      stack: "tilgungsrate",
      areaStyle: {},
      emphasis: {
        disabled: true,
      },
      select: {
        disabled: true,
      },
      // prettier-ignore
      data: data.map(e => e.repaymentAmount + e.interestAmount),
    },
  ],
});

export const cbLoanChartFillEmptyData = (data: CBStatisticLoanEntry[]) => {
  if (!data || data.length === 0) {
    return [];
  }
  const firstDate = data[0]._id;
  const lastDate = data[data.length - 1]._id;

  const output: CBStatisticLoanEntry[] = [];

  let run = moment()
    .year(firstDate.year)
    .month(firstDate.month - 1)
    .startOf("month");

  const last = moment()
    .year(lastDate.year)
    .month(lastDate.month - 1)
    .endOf("month");

  let index = 0;
  while (run.clone().add(1, "month").isBefore(last)) {
    if (
      run.year() === data[index]._id.year &&
      run.month() === data[index]._id.month - 1
    ) {
      output.push({
        ...data[index],
        _id: {
          month: data[index]._id.month,
          year: data[index]._id.year,
        },
      });

      index++;
    } else {
      output.push({
        _id: {
          month: run.month() + 1,
          year: run.year(),
        },
        accumulatedInterest: data[index - 1]?.accumulatedInterest,
        comments: [],
        endBalance: data[index - 1]?.endBalance || 0,
        interestAmount: 0,
        loadIds: [],
        payout: 0,
        repaymentAmount: 0,
        startBalance: data[index - 1]?.endBalance || 0,
      });
    }
    run = run.add(1, "month");
  }

  return output;
};
interface Props {
  matchQuery: MatchQuery;
  chartIdentifier: string;
  assets: PortfolioLoan[];
  objectIds: string[];
  referenceId?: string;
}
const CBLoanChart = (props: Props) => {
  const queryData = useStatisticQuery<CBStatisticLoanEntry[]>(
    "LOAN_DETAIL",
    1,
    {
      query: { matchQuery: props.matchQuery },
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  return (
    <div
      className={`cb-loan-chart ${
        (props.assets || []).length === 0 ? "empty" : ""
      }`}
    >
      <DebugDataComponent data={queryData.data} />
      {(props.assets || []).length > 0 && (
        <ChartComponent
          renderer="canvas"
          options={cbLoanChartOption(cbLoanChartFillEmptyData(queryData.data))}
          identifier={props.chartIdentifier}
        />
      )}
      {(props.assets || []).length === 0 && (
        <div className="empty-text">
          {i18n.t("cb:Loan.Chart.noLoansThere", "Keine Darlehen vorhanden")}
        </div>
      )}
    </div>
  );
};
export default CBLoanChart;

const mockData = (assets: PortfolioLoan[]) => {
  const entriesUnGrouped: CBStatisticLoanEntry[] = assets?.reduce(
    (prev, current) => [
      ...prev,
      ...current.data.values.map((entry) => ({
        _id: {
          month: moment(entry.date).month() - 1,
          year: moment(entry.date).year(),
        },
        startBalance: entry.startBalance,
        endBalance: entry.endBalance,
        payout: entry.payout,
        repaymentAmount: entry.repaymentAmount,
        interestAmount: entry.interestAmount,
        accumulatedInterest: entry.accumulatedInterest,
        comments: entry.comment
          ? [{ text: entry.comment, id: current._id, date: entry.date }]
          : [],
        loadIds: [current._id],
      })),
    ],
    []
  );
  return entriesUnGrouped
    ?.reduce(
      (prev, current) =>
        prev.find(
          (e) =>
            e._id.month === current._id.month && e._id.year === current._id.year
        )
          ? prev.map((p) =>
              p._id.month === current._id.month &&
              p._id.year === current._id.year
                ? {
                    _id: p._id,
                    accumulatedInterest:
                      p.accumulatedInterest + current.accumulatedInterest,
                    comments: [...p.comments, ...current.comments],
                    endBalance: p.endBalance + current.endBalance,
                    interestAmount: p.interestAmount + current.interestAmount,
                    loadIds: [...p.loadIds, ...current.loadIds],
                    payout: p.payout + current.payout,
                    repaymentAmount:
                      p.repaymentAmount + current.repaymentAmount,
                    startBalance: p.startBalance + current.startBalance,
                  }
                : p
            )
          : [...prev, current],
      [] as CBStatisticLoanEntry[]
    )
    .sort((a, b) => {
      if (a._id.year === b._id.year) {
        return a._id.month - b._id.month;
      } else {
        return a._id.year - b._id.year;
      }
    });
};

interface LoanKPIProps {
  objectIds: string[];
  referenceId?: string;
  selector:
    | "startBalance"
    | "endBalance"
    | "payout"
    | "repaymentAmount"
    | "interestAmount"
    | "accumulatedInterest"
    | "endBalance"
    | "annuity";
}

export const CBLoanSingleData = (props: LoanKPIProps) => {
  const queryData = useStatisticQuery<CBStatisticLoanEntry[]>(
    "LOAN_DETAIL",
    1,
    {
      query: {
        matchQuery: MQ.and(MQ.in("data.objects.objectId", props.objectIds)),
      },
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  // i18n.t("cb:Loan.KPI.startBalance", "Anfangsvaluta");
  // i18n.t("cb:Loan.KPI.payout", "Auszahlung");
  // i18n.t("cb:Loan.KPI.annuity", "Annuität");
  // i18n.t("cb:Loan.KPI.repaymentAmount", "Aktuelle Tilgung");
  // i18n.t("cb:Loan.KPI.interestAmount", "Aktuelle Zinsen");
  // i18n.t("cb:Loan.KPI.accumulatedInterest", "Bezahlte Zinsen");
  // i18n.t("cb:Loan.KPI.endBalance", "Restvaluta");

  const filledData = queryData.data
    ? cbLoanChartFillEmptyData(queryData.data).map((e) => ({
        ...e,
        annuity: e.repaymentAmount + e.interestAmount,
      }))
    : [];
  const currentFrame = filledData?.find(
    (e) =>
      e._id.month === moment().utc(true).month() + 1 &&
      e._id.year === moment().utc(true).year()
  );
  return (
    <KPISingleValueCard
      name={i18n.t(`cb:Loan.KPI.${props.selector}`)}
      value={
        currentFrame?.[props.selector]
          ? StringUtils.formatCurrency(currentFrame?.[props.selector])
          : "-"
      }
      loading={queryData.loading}
      error={queryData.error}
      onReload={queryData.reload}
    />
  );
};
