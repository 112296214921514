import i18n from "@/i18n";
import { Tooltip } from "rsuite";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import BFWhisper from "@/modules/abstract-ui/general/whisper/BFWhisper";
import styles from "./FloorPlanNoImageIcon.module.scss";

const FloorPlanNoImageIcon = () => {
  return (
    <div className={styles.no_image_icon_wrapper}>
      <BFWhisper
        trigger="hover"
        placement="top"
        speaker={
          <Tooltip>
            {i18n.t(
              "cb:FloorPlan.tooltips.FloorPlanHasNoImage",
              "Grundriss ist kein Bild verfügbar"
            )}
          </Tooltip>
        }
      >
        {/* div here to enable hover, BfIcon not reacting on hover */}
        <div>
          <BfIcon
            size={"xs"}
            type="light"
            data="information-circle"
            className={styles.no_image_icon}
          />
        </div>
      </BFWhisper>
    </div>
  );
};

export default FloorPlanNoImageIcon;
