import { css } from "emotion";
import { Component, CSSProperties } from "react";
// import { Icon } from "rsuite";
import classNames from "classnames";
import "./font.scss";
import S3Icon, { S3_ICON_TYPES } from "./S3Icon";

export type BfIconProps = {
  type?: "bf" | string;
  data: any;
  size?: "xxs" | "xs" | "sm" | "lg" | "xl" | "2x" | "3x" | "4x" | "5x";
  color?: string;
  className?: string;
  style?: CSSProperties;
  width?: number | string;
  height?: number | string;
  rotate?: number;
  animateTransform?: boolean;
};

type States = {};

class BfIcon extends Component<BfIconProps, States> {
  render() {
    const { type, data, size, color, style, className } = this.props;

    if (S3_ICON_TYPES.indexOf(type) !== -1) {
      return <S3Icon {...this.props} />;
    } else if (type === "bf") {
      return (
        <i
          style={{
            transform: this.props.rotate
              ? `rotate(${this.props.rotate}deg)`
              : undefined,
          }}
          className={classNames(
            "bf-icon",
            "rs-icon",
            "iber-icon",
            `icon-${data}`,
            className,
            color && `color-${color}`,
            `bf-icon size-${size || "norm"}`,
            style && css(style as any),
            {
              "animate-transform": this.props.animateTransform,
            }
          )}
        />
      );
    } else if (type === "img") {
      return (
        <img
          className={classNames({
            "bf-icon": true,
            img: true,
            "icon-img": true,
            "animate-transform": this.props.animateTransform,
          })}
          src={data}
          style={{
            width: this.props.width,
            height: this.props.height,
            transform: this.props.rotate
              ? `rotate(${this.props.rotate}deg)`
              : undefined,
          }}
          width={this.props.width}
          height={this.props.height}
        />
      );
    } else {
      return <div>icon 404</div>;
      // return (
      //   <Icon
      //     className={`bf-icon ${color ? "color-" + color : ""} ${
      //       className ? className : ""
      //     } ${style ? css(style as any) : ""}`}
      //     icon={data}
      //     size={size !== "xl" && size !== "xs" && size !== "sm" ? size : "lg"}
      //   />
      // );
    }
  }
}

export default BfIcon;
