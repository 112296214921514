import { FC, useRef, useState } from "react";
import i18n from "@/i18n";
import { FormRenderProps } from "react-final-form";
import { AddPositionsFormProps, AddPositionsFormValues } from "./types";
import { OpenLayerLocationItem } from "@/components/OpenLayerWrapper";
import {
  OAFloorPlanBase,
  FloorPlanLocationItem,
  FloorPlanPositionsBase,
  FloorPlanPositionsData,
} from "@/apps/tatar/objectsApp/types/object.interface";
import Log from "@/debug/Log";
import {
  processExistingPositions,
  AddPositionsFormToastService,
  handleSelectRequestRejection,
} from "./helpers";
import FloorPlanService from "@/services/FloorPlanService";
import FloorPlanPositionsService from "@/services/FloorPlanPositionsService";
import LoadOverlay from "@/components/LoadOverlay";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FloorPlanSelectField, OpenLayerField } from "./components";
import styles from "./AddPositionsForm.module.scss";

const AddPositionsForm: FC<AddPositionsFormProps> = ({
  assetId,
  drawIcon,
  drawColor,
  assetType,
  buildingId,
  maxUnits,
  singleFloorPlan,
  closeModal,
}) => {
  // ! state
  const [localLoading, setLocalLoading] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);

  // ! refs
  const floorPlanPositionsBaseRef = useRef<FloorPlanPositionsBase | null>(null);
  const formPropsRef = useRef<FormRenderProps<AddPositionsFormValues> | null>(
    null
  );

  // ! handlers
  const onFloorPlanSelect = async (
    floorPlanId: string,
    selected: OAFloorPlanBase
  ) => {
    // reset prev selected state
    formPropsRef.current?.form.mutators.setValue("locations", null);
    floorPlanPositionsBaseRef.current = null;

    // fetch image url and floorPlanPositionsBase
    setLocalLoading(true);
    const [imageResult, positionResult] = await Promise.allSettled([
      FloorPlanService.fetchImageURL(selected),
      FloorPlanPositionsService.fetchPosition({ floorPlanId, assetId }),
    ]);
    setLocalLoading(false);

    const allFulfilled =
      imageResult.status === "fulfilled" &&
      positionResult.status === "fulfilled";

    if (!allFulfilled) {
      handleSelectRequestRejection(imageResult, positionResult);

      formPropsRef.current?.form.mutators.setValue("floorPlanId", undefined);
      setImageURL(null);
      return;
    }

    // select new Floor Plan
    formPropsRef.current?.form.mutators.setValue("floorPlanId", selected._id);

    // set new image
    const url = imageResult.value;
    setImageURL(url);

    // process floorPlanPositionBase
    const floorPlanPositionBase = positionResult.value;
    if (!floorPlanPositionBase) return;
    floorPlanPositionsBaseRef.current = floorPlanPositionBase;

    if (!floorPlanPositionBase.data.locations.length) return;
    // add icon and color to location item
    const locations =
      floorPlanPositionBase.data.locations.map<OpenLayerLocationItem>(
        ({ coordinates }) => ({ coordinates, icon: drawIcon, color: drawColor })
      );

    formPropsRef.current?.form.mutators.setValue("locations", locations);
  };

  const onCreatePosition = async (position: FloorPlanPositionsData) => {
    try {
      // check and remove existing positions if only one allowed
      if (singleFloorPlan) {
        const canProceed = await processExistingPositions(assetId);
        if (!canProceed) return;
      }

      await FloorPlanPositionsService.createPosition(position);
      closeModal();
    } catch (error) {
      Log.error("Error creating floor plan positions", error);
      AddPositionsFormToastService.failedToSaveFloorPlanPosition();
    }
  };

  const onUpdatePosition = async (
    position: FloorPlanPositionsData,
    positionId: FloorPlanPositionsBase["_id"]
  ) => {
    try {
      await FloorPlanPositionsService.updatePosition(position, positionId);
      closeModal();
    } catch (error) {
      Log.error("Error updating floor plan positions", error);
      AddPositionsFormToastService.failedToUpdateFloorPlanPosition();
    }
  };

  const onFormSubmit = async (formValues: AddPositionsFormValues) => {
    const locations = formValues.locations.map<FloorPlanLocationItem>(
      ({ coordinates }) => ({ coordinates })
    );

    const positionData: FloorPlanPositionsData = {
      locations,
      assetLink: {
        assetId,
        assetType,
      },
      floorPlanId: formValues.floorPlanId,
      status: "active",
    };

    if (!floorPlanPositionsBaseRef.current) {
      return onCreatePosition(positionData);
    }

    return onUpdatePosition(
      positionData,
      floorPlanPositionsBaseRef.current._id
    );
  };

  const onOpenLayerSubmit = (locations: OpenLayerLocationItem[]) => {
    formPropsRef.current?.form.mutators.setValue("locations", locations);
  };

  // ! render
  return (
    <>
      <FormStruct
        title={i18n.t(
          "cb:FloorPlanPosition.form.titles.addTechnicalUnitPositions",
          "Technische Einheitpositionen hinzufügen"
        )}
        className={styles.form}
        submitText={i18n.t("Global.Buttons.save")}
        onSubmit={onFormSubmit}
        onAbort={closeModal}
        render={(formProps: FormRenderProps<AddPositionsFormValues>) => {
          // set ref to use it in the component
          formPropsRef.current = formProps;

          return (
            <div className={styles.form_fields_wrapper}>
              {/* LOADING */}
              <LoadOverlay open={localLoading} />

              {/* FLOOR PLAN SELECT */}
              <FloorPlanSelectField
                buildingId={buildingId}
                onFloorPlanSelect={onFloorPlanSelect}
              />

              {/* OPEN LAYER LOCATIONS */}
              <OpenLayerField
                imageURL={imageURL}
                maxUnits={maxUnits}
                drawIcon={drawIcon}
                drawColor={drawColor}
                onOpenLayerSubmit={onOpenLayerSubmit}
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default AddPositionsForm;
