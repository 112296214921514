import { ApplicationSidebarParams } from "@/components/ApplicationBody/ApplicationSidebar";
import { FilterValue } from "../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { DataBusSubKeys } from "../utils/Constants";
import DataBus from "./DataBus";

export interface ScrollGroupOptions {
  groupId: string;
  senderId: string;
  top?: number;
  left?: number;
}
class DataBusDefaultsClass {
  filterChanged(options: { identifier: string; filter: FilterValue[] }) {
    DataBus.emit(DataBusSubKeys.FILTER_CHANGED, options);
  }

  focus(identifier: string) {
    DataBus.emit(DataBusSubKeys.FOCUS, {
      id: identifier,
    });
  }

  scrollGroup(options: ScrollGroupOptions) {
    DataBus.emit(DataBusSubKeys.SCROLL_GROUP, options);
  }
  route(options: {
    back?: boolean;
    route: string;
    append?: boolean;
    stayInApp?: boolean;
    clearQueryParams?: boolean;
    queryParams?: any;
    replace?: boolean;
  }) {
    DataBus.emit(DataBusSubKeys.ROUTE, options);
  }

  closeModal(identifier: string) {
    DataBus.emit(DataBusSubKeys.CLOSE_MODAL, {
      identifier,
    });
  }

  toast(options: {
    type: "info" | "success" | "warning" | "error";
    textKey?: string;
    text?: string | React.ReactNode;
    duration?: number;
  }) {
    DataBus.emit(DataBusSubKeys.TOAST, options);
  }

  reload(options: { identifiers: string[] }) {
    DataBus.emit(DataBusSubKeys.RELOAD, options);
  }
  closeAllModals() {
    DataBus.emit(DataBusSubKeys.MODAL_MANAGER_CLOSE_ALL, {});
  }
  chartResized(identifier: string) {
    DataBus.emit(DataBusSubKeys.CHART_RESIZE, {
      identifier,
    });
  }
  chartGeoSelect(chartId: string, geoIds: string[]) {
    DataBus.emit(DataBusSubKeys.CHART_GEO_SELECT, {
      chartId,
      geoIds,
    });
  }

  openSidebar(options: ApplicationSidebarParams) {
    DataBus.emit(DataBusSubKeys.SIDEBAR_OPEN, options);
  }
  closeSidebar() {
    DataBus.emit(DataBusSubKeys.SIDEBAR_CLOSE, {});
  }
}
const DataBusDefaults = new DataBusDefaultsClass();
(window as any).DataBusDefaults = DataBusDefaults;
export default DataBusDefaults;
