// AC Prefix = AppConfig

import BaseAsset from "../model/general-assets/BaseAsset";
import { DocumentStoreDirectory } from "../modules/document-store/DSInterfaces";
import { MCFolder } from "../modules/mail-client/MCInterfaces";
import { MatchQuery, MatchQueryOP } from "../services/DataService";
import { TranslatedLabel } from "../services/LanguageService";
import { CBRentalTargetPosition } from "./tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import { InvoiceDirection } from "./tatar/invoiceApp/RAInterfaces";
import { IDefaultIcon } from "@/types/common";

export type PortfolioStructure = ACPortfolioEntity[];
export type EntityStructure = ACEntity[];
export type InvoiceStructure = ACInvoiceStruct;
//FIXME -> should use strcut request for this
export type UnitStructure = ACUnit[];
export type OposStructure = ACOposRentConfig;
export type ProjectStructure = ACProject[];
export type CategoryStructure = ACCategory[];
export type APStructure = APConfigBase<any>[];

// for old config structures
export type APConfig = APConfigBase<"damageClaim">;

export interface APConfigBase<T extends string> {
  _id: string;
  mandator: string;
  data: {
    type: string;
    activity_type: T; //"damageClaim";
    status: APConfigStatus[];
    offersNeededToStartFlow: number;
    approvalChains: any;
    tags: APConfigTag[];
    planlqBookingCategory?: string[];
  };
}
export interface APConfigApprovalChain {
  users: string[];
  teams: string[];
  currentRule: MatchQueryOP;
}
export interface APConfigTag {
  id: string;
  status: "active" | "archived";
  displayName: TranslatedLabel;
}
export interface APConfigDocumentType {
  id: number;
  status: "active" | "archived";
  displayName: TranslatedLabel;
}

export interface APConfigStatus {
  id: string;
  status: "active" | "archived";
  activity_status: "active" | "inactive" | "waiting";
  tags: APStatusTags[];
  displayName: TranslatedLabel;
  color: string;
  icon?: IDefaultIcon;
  possibleNextStatuses: string[];
  subStatus?: {
    id: string;
    status: "active" | "archived";
    displayName: TranslatedLabel;
  }[];
}

export enum APStatusTags {
  ARCHIVED = "ARCHIVED",
  START_STATUS = "START_STATUS",
  OFFER_STARTABLE = "OFFER_STARTABLE",
  OFFER_APPROVED = "OFFER_APPROVED",
  OFFER_ONGOING = "OFFER_ONGOING",
  OFFER_DECLINED = "OFFER_DECLINED",
  DECLINED_STATUS = "DECLINED_STATUS",
  FINISHED_STATUS = "FINISHED_STATUS",
  PROJECT_BUDGET_IN_APPROVAL = "PROJECT_BUDGET_IN_APPROVAL",
  PROJECT_BUDGET_APPROVED = "PROJECT_BUDGET_APPROVED",
  PROJECT_BUDGET_DECLINED = "PROJECT_BUDGET_DECLINED",
  BUDGET_IN_APPROVAL = "BUDGET_IN_APPROVAL",
  BUDGET_APPROVED = "BUDGET_APPROVED",
  BUDGET_DECLINED = "BUDGET_DECLINED",
  CONTRACT_WANT_TO_CANCEL = "CONTRACT_WANT_TO_CANCEL",
  CONTRACT_TERMINATED = "CONTRACT_TERMINATED",
}

export type ACCategory = {
  _id: string;
  data: {
    displayName: TranslatedLabel;
    displayName2?: TranslatedLabel;
    icon: string | IDefaultIcon;
    kind: "income" | "expense";
    sortKey: number;
    tags: string[];
    type: string;
    group: string;
  };
};

export type ACCostCenter = {
  costCenterId: string;
  displayName: TranslatedLabel;
  description?: TranslatedLabel;
};

export interface ACInvoiceStructConfig extends BaseAsset {
  _id: string;
  data: {
    type: string;
    customFields: {
      id: string;
      displayName: TranslatedLabel;
    }[];
    checkList: InvoiceCheckChainRules[];
    paymentTypes: InvoicePaymentType[];
  };
}
export interface InvoicePaymentType {
  id: string;
  status: "active" | string;
  displayName: string;
  tags: InvoicePaymentTypeTags[];
}
export interface InvoiceCheckChainRules {
  valueBoundary: number;
  switchFilter: {
    matchQuery: MatchQuery;
    value: {
      user: string;
      team: string;
    };
  }[];
  default: {
    user: string;
    team: string;
  };
}

export enum InvoicePaymentTypeTags {
  "SHOULD_BE_BOOKED" = "SHOULD_BE_BOOKED",
  "SHOULD_BE_PAYED" = "SHOULD_BE_PAYED",
  "SHOULD_BE_COMPENSATED" = "SHOULD_BE_COMPENSATED",
  "SHOULD_BE_CHECKED" = "SHOULD_BE_CHECKED",

  "IS_STORNO" = "IS_STORNO",

  "PAYMENT_IBAN" = "PAYMENT_IBAN",
  "PAYMENT_US_NATIONAL" = "PAYMENT_US_NATIONAL",
  "PAYMENT_US_INTERNATIONAL" = "PAYMENT_US_INTERNATIONAL",
  // "PAYMENT_CASH" = "PAYMENT_CASH",
}

export interface ACInvoiceStruct {
  invoiceConfig: ACInvoiceStructConfig[];
  invoiceTypes: ACInvoiceType[];
}
export interface ACInvoiceStructByUnit {
  invoiceConfig: ACInvoiceStructConfig;
  invoiceTypes: ACInvoiceType[];
}

export interface ACInvoiceType {
  _id: string;
  data: {
    type: string;
    status: "active";
    direction: InvoiceDirection;
    displayName: TranslatedLabel;
    chain: ACInvoiceChain;
    relevantActivity?: string[];
    costCenters: ACCostCenter[];
    sortIndex: number;
    description?: TranslatedLabel;
  };
}
export interface ACInvoiceTypePrefilter {
  filter: MatchQuery;
  chainElements: ACInvoiceChain;
}

export interface ACInvoiceChain {
  _id: string;
  displayName: string;
  prefilter?: ACInvoiceTypePrefilter[];
}
export interface ACProject {
  _id: string;
  data: {
    type: string;
    status: "active" | "inactive";
    displayName: string;
    entity: string;
  };
}
export interface ACEntity {
  _id: string;
  id: string;
  entityName: string;
  displayName: string;
  type: string;
  accounts: ACAccount[];
  objects: ACObject[];
}
export interface ACAccount {
  _id: string;
  account: string;
  blz: string;
  displayName: string;
  iban: string;
  bankName: string;
}
export interface ACObject {
  _id: string;
  id: string;
  bankAccount: string;
  displayName: string;
  startOfAdministration: Date;
  endOfAdministration: Date;
}

export type TagConfig = {
  id: string;
  displayName: TranslatedLabel;
  color?: string;
  status?: "active" | "archived";
  group?: string;
};
export type TagConfigWithUnit = TagConfig & {
  unit: string;
};
export type ACUnitTemplate = any;

export interface ACUnit {
  _id: string;
  mandator: string;
  id: number;
  data: {
    identifier: string;
    startDate: Date;
    unitColor: string;
    label: TranslatedLabel;
    lastImport: Date;
    lastStarmoneyImport: Date;
    countModifications: number;
    templates: {
      [key: string]: ACUnitTemplate;
    };
    objectConfig: {
      [assetType: string]: {
        directories: {
          [path: string]: DocumentStoreDirectory;
        };
        mailFolders: MCFolder[];
        tags?: TagConfig[];
      };
    };
  };
}

export interface ACOposRentConfigAsset {
  _id: string;
  mandator: string;
  data: ACOposRentConfig;
}
export interface ACOposRentConfig {
  typesOfDemand: {
    label: string;
    value: string;
  }[];
}

export interface ACPortfolioEntity {
  _id: string;
  id: string;
  entityName: string;
  displayName: string;
  type: string;
  objects: ACPortfolioObject[];
}
export interface ACPortfolioObject {
  _id: string;
  id: string;
  bankAccount: string;
  displayName: string;
  startOfAdministration: Date;
  endOfAdministration: Date;
  lqObject?: string;
  value?: { value: number; date: Date; changedBy: string }[];
  objectConfig?: ACPortfolioObjectConfig;
}

export type DepositPositionConfig = {
  identifier: string;
  automaticDebitPosition: boolean;
  label: TranslatedLabel;
};
export interface ACPortfolioObjectConfig {
  objectId: string;
  rentalTargetPositions: CBRentalTargetPosition[];
  depositPositions: DepositPositionConfig[];
}
