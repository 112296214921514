import { FC } from "react";
import i18n from "@/i18n";
import { ErrorContentComponentProps } from "./types";
import ErrorAnimation from "@/components/LottieAnimations/ErrorAnimation/ErrorAnimation";
import styles from "./ErrorContentComponent.module.scss";

const ErrorContentComponent: FC<ErrorContentComponentProps> = ({
  title,
  description = i18n.t("Global.Error.general"),
}) => {
  return (
    <div className={styles.error_content_wrapper}>
      <div className={styles.error_content_text}>
        {title && <div className={styles.error_content_title}>{title}</div>}
        <div className={styles.error_content_description}>{description}</div>
      </div>

      <div className={styles.error_animation_wrapper}>
        <ErrorAnimation />
      </div>
    </div>
  );
};

export default ErrorContentComponent;
