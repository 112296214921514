import { FC, useEffect } from "react";
import { ViewPositionFloorPlanListProps } from "./types";
import ListEntryComponent from "@/configurable/components/ListComponent/ListEntryComponent";
import styles from "./ViewPositionFloorPlanList.module.scss";

const ViewPositionFloorPlanList: FC<ViewPositionFloorPlanListProps> = ({
  floorPlanBaseList,
  selectedFloorPlanId,
  onFloorPlanSelect,
}) => {
  // ! effects
  useEffect(() => {
    void onFloorPlanSelect(floorPlanBaseList[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ! render
  return (
    <>
      {floorPlanBaseList.map((floorPlanBaseItem) => (
        <ListEntryComponent
          key={floorPlanBaseItem._id}
          entryData={floorPlanBaseItem}
          isSelected={floorPlanBaseItem._id === selectedFloorPlanId}
          onClickEntry={() => onFloorPlanSelect(floorPlanBaseItem)}
          renderEntry={() => (
            <span className={styles.list_item_text}>
              {floorPlanBaseItem.data.name}
            </span>
          )}
        />
      ))}
    </>
  );
};

export default ViewPositionFloorPlanList;
