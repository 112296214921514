import { FC } from "react";
import i18n from "@/i18n";
import { FloorPlanFormProps, FloorPlanFormValues } from "./types";
import FormValidators from "@/components/Form/Validation/FormValidators";
import { FloorPlanFormImageField } from "./components";
import { Field, FormRenderProps } from "react-final-form";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import styles from "./FloorPlanForm.module.scss";

/**
 * TODO:
 *
 * - [X] create a new floor plan form
 * - [X] use <FormStruct/> component
 * - [X] take values for objectId, entityId, and type from the object property
 * - [X] status should set be to active
 * - [X] use a list component to fetch and display floor plans
 * - [-] use the component <AssetImages /> to upload the floor plan image
 * - [X] only one image should be uploaded!
 * - [X] translations!
 */
const FloorPlanForm: FC<FloorPlanFormProps> = ({
  onAbort,
  onFormSubmit,
  floorPlanBase,
}) => {
  // ! variables
  const formTitle = floorPlanBase
    ? i18n.t("cb:FloorPlan.form.titles.update", "Grundriss aktualisieren")
    : i18n.t("cb:FloorPlan.form.titles.create", "Grundriss erstellen");

  // ! render
  return (
    <FormStruct
      title={formTitle}
      initialValues={floorPlanBase && { ...floorPlanBase.data }}
      onAbort={onAbort}
      submitText={i18n.t("Global.Buttons.save")}
      onSubmit={(formValues) => onFormSubmit(formValues, floorPlanBase?._id)}
      render={(formProps: FormRenderProps<FloorPlanFormValues>) => {
        return (
          <div className={styles.form_fields_wrapper}>
            {/* NAME */}
            <Field name="name" validate={FormValidators.required()}>
              {({ input, meta }) => {
                return (
                  <BFInput
                    focusOnMount
                    label={i18n.t("cb:FloorPlan.form.labels.name", "Name")}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                );
              }}
            </Field>

            {/* FLOOR */}
            <Field name="floor" validate={FormValidators.required()}>
              {({ input, meta }) => (
                <BFInput
                  type="number"
                  label={i18n.t("cb:FloorPlan.form.labels.floor", "Etage")}
                  {...input}
                  {...FormValidators.getValidation(meta)}
                />
              )}
            </Field>

            {/* DESCRIPTION */}
            <Field name="description" validate={FormValidators.max(255)}>
              {({ input, meta }) => (
                <BFInput
                  autosize
                  type="textarea"
                  label={i18n.t(
                    "cb:FloorPlan.form.labels.description",
                    "Beschreibung"
                  )}
                  {...input}
                  {...FormValidators.getValidation(meta)}
                />
              )}
            </Field>

            {/* IMAGE */}
            <FloorPlanFormImageField
              formProps={formProps}
              floorPlanBase={floorPlanBase}
            />
          </div>
        );
      }}
    />
  );
};

export default FloorPlanForm;
