import { CSSProperties } from "react";
import i18n from "@/i18n";
import { IDefaultIcon } from "@/types/common";
import { DEFAULT_ICON_COLOR } from "../config";
import {
  fetchS3Icon,
  getDirectS3Icon,
} from "@/modules/abstract-ui/icon/helpers";
import DataBusDefaults from "@/services/DataBusDefaults";
import Color from "color";

// ! SVG SERIALIZATION
const wrapSvgInCircle = (
  color: CSSProperties["color"],
  size: number,
  svgHtml: string
) => {
  const padding = 4;
  const innerSize = size - 2 * padding;
  const halfSize = size / 2;

  // create one more svg to wrap our svg
  return `
    <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
      <circle cx="${halfSize}" cy="${halfSize}" r="${halfSize}" fill="${color}" />
      <g transform="translate(${padding}, ${padding})">
        <svg width="${innerSize}" height="${innerSize}" viewBox="0 0 ${innerSize} ${innerSize}">
          ${svgHtml} 
        </svg>
      </g>
    </svg>
  `;
};

// change SVG styles and attributes
const setSvgProperties = (
  svg: string,
  color: CSSProperties["color"],
  size: number = 32
): string | null => {
  const svgWrapperDiv = document.createElement("div");

  svgWrapperDiv.innerHTML = svg;

  const svgIcon = svgWrapperDiv.querySelector("svg");
  if (!svgIcon) return null;

  // define appropriate svg color and set id
  const colorInstance = Color(color);

  svgIcon.style.color = colorInstance?.isDark()
    ? "#fff"
    : colorInstance.darken(1).hex();

  return wrapSvgInCircle(color, size, svgWrapperDiv.innerHTML);
};

// convert SVG to Base64 to use it as an image source
const svgToBase64 = (svg: string): string | null => {
  try {
    // Step 1: Encode the SVG string into a byte array (Uint8Array)
    const textEncoder = new TextEncoder();
    const encodedSvgBytes = textEncoder.encode(svg);

    // Step 2: Convert the byte array back to a string
    const stringFromBytes = Array.from(encodedSvgBytes)
      .map((byte) => String.fromCharCode(byte))
      .join("");

    // Step 3: Base64 encode the string
    const base64EncodedString = window.btoa(stringFromBytes);

    return `data:image/svg+xml;base64,${base64EncodedString}`;
  } catch (error) {
    console.error("Error encoding SVG to Base64:", error);
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "OpenLayer.messages.errors.failedToLoadFeatureIcon",
        "Das Laden der Icons für ein Objekt ist fehlgeschlagen."
      ),
    });

    return null;
  }
};

// main function for processing SVG serialization
export const processSvgSerialization = (
  svg: string,
  color = DEFAULT_ICON_COLOR
): string | null => {
  const svgWithProperties = setSvgProperties(svg, color);
  if (!svgWithProperties) return null;

  return svgToBase64(svgWithProperties);
};

// ! SVG FETCHING
export const fetchFeatureSvg = async (
  icon: IDefaultIcon
): Promise<string | null> => {
  // get SVG from the cache
  let cachedSvg = getDirectS3Icon(icon.type, icon.data);

  // return SVG if it is in cache
  if (cachedSvg) return cachedSvg;

  // if there is no SVG in the cache, fetch it
  try {
    return await fetchS3Icon(icon.type, icon.data);
  } catch (error) {
    console.error("Failed to fetch SVG for icon:", icon);

    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "OpenLayer.messages.errors.failedToLoadFeatureIcon",
        "Das Laden der Icons für ein Objekt ist fehlgeschlagen."
      ),
    });

    return null;
  }
};
